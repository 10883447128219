import React from "react";
import carlogo from "../../assets/carlogo.svg";
import "./DetailScreen.css";
import gradient from "../../assets/gradient2.png";

function DetailScreen() {
  return (
    <>
      <div className="detail-container">
        <div className="detailpagemain-wrapper">
          <div className="detail-svgcontainer">
            <img
              style={{ width: "100%", height: "100%" }}
              src={gradient}
              alt=""
            />
          </div>
          <div className="detailpage-wrapper">
            <div className="detailpage-image">
              <img
                style={{ width: "100%", height: "auto" }}
                src={carlogo}
                alt=""
              />
            </div>
            <div className="detailpage-content">
              <div className="detailpage-heading">
                <span className="span-heading">WHAT IS STEAM CAR WASH ? </span>
              </div>
              <div className="detailpage-para">
                <div className="para-content">
                  <ul>
                    <li>
                      The Steam Car wash uses steam vapor to clean a car’s
                      exterior and interior. It uses a reasonable amount of
                      pressurized steam jet continuously to clean the car.
                    </li>
                    <li>
                      The heat and pressure of the steam breaks up grease and
                      dirt, leaving your car extremely clean. A steam car wash
                      deeply sanitizes and gets into hard to clean places, it
                      also prevents scratches and never leaves watermarks.
                    </li>
                  </ul>
                </div>
              </div>
              <button className="detailpage-button">READ MORE</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailScreen;
