import { useEffect } from "react";
const useSetweek = () => {
  useEffect(() => {
    setDays();
  }, []);
  const setDays = () => {
    const nodeElements = document.getElementsByClassName(
      "sdp--square-btn sdp--date-btn sdp--text"
    );
    Array.from(nodeElements).map((e) => {
      if (Array.from(e.getElementsByTagName("div"))[0]) {
        e.removeChild(Array.from(e.getElementsByTagName("div"))[0]);
      }
      let str = e.ariaLabel.split(" ")[1];
      str = str.substring(0, 3).toUpperCase();
      let div = document.createElement("div");
      div.innerHTML = str;
      div.className = "weekday-label";
      e.append(div);
      return str;
    });
  };
  return setDays;
};

export default useSetweek;
