import gradient1 from "../../assets/background-gradient2.svg";
import React from "react";
import style from "./GradientBase2.module.css";

const GradientBase2 = (props) => {
  // const { settings } = useSetting();
  // const { screen = "max", minHeight = 0, maxHeight = 0 } = settings;

  return (
    <div className={style["gradientBase-container"]}>
      <div className={style["gradientBase-gradient-base"]}>
        <div
          className={`${style["gradientBase-gradient"]} ${style.firstGradient}`}
        >
          <img
            className={style["gradientBase-background"]}
            style={{ width: "100%", transform: "rotate(180deg)" }}
            src={gradient1}
            alt=""
          />
        </div>
        <div className={style.baseCard}>{props.children}</div>

        <div
          className={`${style["gradientBase-gradient"]} ${style.secondGradient} `}
        >
          <img
            className={style["gradientBase-background"]}
            style={{ width: "100%" }}
            src={gradient1}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default GradientBase2;
