import React from "react";
import "./CompanyPage.css";
import kiviklogo from "../../assets/logoblack.svg";
import appstore from "../../assets/appstore.svg";
import googleplay from "../../assets/googleplay.svg";
import facebook from "../../assets/facebook.svg";
import insta from "../../assets/insta.svg";
import twitter from "../../assets/twitter.svg";
import youtube from "../../assets/youtube.svg";

function CompanyPage() {
  return (
    <div className="companypage-maincontainer">
      <div className="companypage-container">
        <div className="companypage-main-section">
          <div className="companypage-header">
            <div className="companypage-logo">
              <img src={kiviklogo} alt=""></img>
            </div>
          </div>
          <div className="companypage-section">
            KIVLIK is a mobile steam car wash company, which offers premium car
            wash and detailing services at your doorstep. Conveniently book a
            car wash with a few taps on your phone. KIVLIK has several packages
            to meet your budget and requirements.
          </div>
        </div>
        <div className="companypage-main-section">
          <div className="companypage-header company-subheader">COMPANY</div>
          <div className="companydetail-sub">
            <div className="companydetail-subsection">
              <a className="a-class" href="/about">
                Who We Are{" "}
              </a>
            </div>
            <div className="companydetail-subsection">
              <a className="a-class" href="/gallery">
                Our Story
              </a>
            </div>
            <div className="companydetail-subsection">
              <a className="a-class" href="/blog">
                Blog
              </a>
            </div>
            <div className="companydetail-subsection">
              <a className="a-class" href="/careers">
                Careers
              </a>
            </div>
            {/* <div className="companydetail-subsection">
              <a className="a-class" href="/">
                Report Fraud
              </a>
            </div> */}
            <div className="companydetail-subsection">
              <a className="a-class" href="/contact">
                Contact
              </a>
            </div>
            {/* <div className="companydetail-subsection">
              <a className="a-class" href="/">
                Investor Relations
              </a>
            </div> */}
          </div>
        </div>
        <div className="companypage-main-section">
          <div className="companypage-header company-subheader">
            Quick Links
          </div>
          <div className="companydetail-sub">
            <div className="companydetail-subsection">
              <a className="a-class" href="/car-wash-and-detailing">
                Car wash and Detailing
              </a>
            </div>
            <div className="companydetail-subsection">
              {" "}
              <a className="a-class" href="/pricing">
                Pricing
              </a>
            </div>
            <div className="companydetail-subsection">
              <a className="a-class" href="/">
                Gallery
              </a>
            </div>
            <div className="companydetail-subsection">
              <a className="a-class" href="/">
                Franchisee
              </a>
            </div>
          </div>
        </div>
        <div className="companypage-main-section">
          <div className="companypage-header company-subheader">
            <a className="a-class" href="/about">
              Get Connected!
            </a>
          </div>
          <div className="companydetail-sub">
            <div className="companydetail-subsection">
              <div className="companydetail-helpcenter">
                <a className="a-class" href="/contact">
                  Help Desk
                </a>
              </div>
            </div>
            <div className="companydetail-subsection">
              <div className="companydetail-follow">FOLLOW US</div>
            </div>
            <div className="companydetail-social">
              <div className="companydetail-social-wrap">
                <a className="a-class" href="https://www.facebook.com/Kivlik/">
                  <img src={facebook} alt="" />
                </a>
              </div>
              <div className="companydetail-social-wrap">
                <a className="a-class" href="/">
                  {" "}
                  <img src={insta} alt="" />
                </a>
              </div>
              <div className="companydetail-social-wrap">
                <a className="a-class" href="/">
                  <img src={twitter} alt="" />
                </a>
              </div>
              <div className="companydetail-social-wrap">
                <a
                  className="a-class"
                  href="https://www.youtube.com/channel/UCTVcHK56Ph8fmWmhUg0j9tg"
                >
                  <img src={youtube} alt="" />
                </a>
              </div>
            </div>
            <div className="companydetail-app">
              <div className="companydetail-play">
                <img
                  className="companydetail-app-wrapper"
                  src={googleplay}
                  alt=""
                />
              </div>
              <div className="companydetail-play">
                <img
                  className="companydetail-app-wrapper"
                  src={appstore}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyPage;
