import React from "react";
import Button from "../../Container/Button/Button";
import "./AddPaymentProfile.css";
function AddPaymentProfile() {
  return (
    <div className="addPaymentProfile-container">
      <div className="addPaymentProfile-placer">
        <div className="addPaymentProfile-header">
          <h2>Add Payment Profile</h2>
        </div>
        <div className="addPaymentProfile-card">
          <form className="addPaymentProfile-form">
            <div className="addPaymentProfile-divider">
              <input
                className="input-payment"
                type="text"
                name="ptype"
                placeholder="Profile Type"
                required=""
              />

              <input
                className="input-payment"
                type="text"
                name="fname"
                placeholder="First Name"
                required=""
              />
              <input
                className="input-payment"
                type="text"
                name="fname"
                placeholder="Last Name"
                required=""
              />
              <input
                className="input-payment"
                type="text"
                name="email"
                placeholder="Email"
                required=""
              />
              <input
                className="input-payment"
                type="number"
                name="phone"
                placeholder="Phone"
                required=""
              />
            </div>

            <label className="addPaymentProfile-label">
              Profile Type : Credit Card
            </label>
            <div className="addPaymentProfile-divider">
              <input
                className="input-payment"
                type="text"
                name="ctype"
                placeholder="Card Type"
                required=""
              />

              <input
                className="input-payment"
                type="text"
                name="cnumber"
                placeholder="Card Number"
                required=""
              />
              <input
                className="input-payment"
                type="text"
                name="edate"
                placeholder="Expiration Date"
                required=""
              />
              <input
                className="input-payment"
                type="password"
                name="cvv"
                placeholder="CVV"
                required=""
              />
            </div>
          </form>
        </div>
        <div className="addPaymentProfile-submit">
          <Button style={{ width: "12em" }}>SAVE</Button>
        </div>
      </div>
    </div>
  );
}

export default AddPaymentProfile;
