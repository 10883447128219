import React from 'react'

function DoubleContent({ data }) {
    return (
        <div style={{ backgroundColor: data?.left ? "#ffff" : "#f1f1f13d" }} className='gallery-content-padding'>
            <div className={data?.left ? "content-warper row-reverse" : "content-warper"}>
                <div className="content-text-side">
                    <span>
                        <h1 className='gallery-content-header'>{data?.heading1}</h1>
                        <p className='gallery-content-para'> {data?.content1?.split('\n').map(str => <p>{str}</p>)} </p>
                    </span>
                    <span>
                        <h1 className='gallery-content-header'>{data?.heading2}</h1>
                        <p className='gallery-content-para'> {data?.content2?.split('\n').map(str => <p>{str}</p>)} </p>
                    </span>
                </div>
                <div className={data?.left ? "content-image-side justify-start" : "content-image-side justify-end"}>
                    {data?.singleImage && <img src={data?.singleImage} style={{ width: "80", borderRadius: "20px", zIndex: 2 }} alt="car related images" />}
                </div>
            </div>
        </div>
    )
}

export default DoubleContent