import React, { useEffect } from "react";
import MonthPicker from "./month-picker";
import DateButton from "./date-button";
import { getDatesOfMonth } from "./util";
import "./styles.css";
import moment from "moment-timezone";
moment.tz.setDefault("Canada/Eastern");
const DatePicker = React.forwardRef(
  (
    {
      onChange,
      selected = new Date(),
      minDate = new Date(1900, 0, 1),
      maxDate,
      className,
      inActiveDays = [],
      currentMonth,
      ...props
    },
    ref
  ) => {
    const minDateVal = minDate.getTime();
    const maxDateVal =
      typeof maxDate === "undefined"
        ? Number.POSITIVE_INFINITY
        : maxDate.getTime();
    const [monthDate, setMonthDate] = React.useState(selected);
    const [selectedDate, setSelectedDate] = React.useState(selected);
    const nextMonth = React.useCallback(
      () =>
        setMonthDate((d) => {
          const m = d.getMonth();
          const y = d.getFullYear();
          if (m === 11) {
            currentMonth(0, y + 1);
            return new Date(y + 1, 0);
          } else {
            currentMonth(m + 1, y);
            return new Date(y, m + 1);
          }
        }),
      []
    );
    const prevMonth = React.useCallback(
      () =>
        setMonthDate((d) => {
          const m = d.getMonth();
          const y = d.getFullYear();
          if (m === 0) {
            currentMonth(11, y - 1);
            return new Date(y - 1, 11);
          } else {
            currentMonth(m - 1, y);
            return new Date(y, m - 1);
          }
        }),
      []
    );
    const setNewSelectedDate = React.useCallback(
      (date) => {
        setSelectedDate(date);
        onChange?.(date);
        console.log("dddd", inActiveDays);
      },
      [onChange, setSelectedDate]
    );
    // TODO: arrow-keys navigation
    return React.createElement(
      "div",
      {
        className: `sdp ${className ?? ""}`,
        "aria-label": "Date Picker",
        tabIndex: 0,
        ref: ref,
        ...props,
      },
      React.createElement(MonthPicker, {
        month: monthDate.getMonth(),
        year: monthDate.getFullYear(),
        nextMonth: nextMonth,
        prevMonth: prevMonth,
      }),
      React.createElement(
        "div",
        { className: "sdp--dates-grid" },
        React.createElement(
          "p",
          { className: "sdp--text sdp--text__inactive" },
          "Su"
        ),
        React.createElement(
          "p",
          { className: "sdp--text sdp--text__inactive" },
          "Mo"
        ),
        React.createElement(
          "p",
          { className: "sdp--text sdp--text__inactive" },
          "Tu"
        ),
        React.createElement(
          "p",
          { className: "sdp--text sdp--text__inactive" },
          "We"
        ),
        React.createElement(
          "p",
          { className: "sdp--text sdp--text__inactive" },
          "Th"
        ),
        React.createElement(
          "p",
          { className: "sdp--text sdp--text__inactive" },
          "Fr"
        ),
        React.createElement(
          "p",
          { className: "sdp--text sdp--text__inactive" },
          "Sa"
        ),

        getDatesOfMonth(monthDate).map(({ d, active }) => {
          const dVal = d.getTime();
          const dDay = d.getDate();
          return React.createElement(DateButton, {
            key: dVal,
            date: d,
            active: dVal >= minDateVal && dVal <= maxDateVal && active,
            disabledDay: inActiveDays.includes(dDay),
            selected: selectedDate.toDateString() === d.toDateString(),
            onClick: setNewSelectedDate,
          });
        })
      )
    );
  }
);
DatePicker.displayName = "DatePicker";
export default DatePicker;
