import React, { useEffect, useState } from "react";
import gradient from "../../assets/gradient.png";
import mailicon from "../../assets/mailicon.svg";
import phoneicon from "../../assets/phoneicon.svg";
import ConfirmationSuccess from "../../assets/ConfirmationSuccess.svg";
// import failureBooking from "../../assets/failureBooking.svg";
import "./Confirmation.css";
import Location from "./Location";

import { singleBooking } from "../../utils/api";
import { Link, useParams } from "react-router-dom";

function Confirmation() {
  const params = useParams();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [startdateTimeString, setstartDateTimeString] = useState("");
  const [startdate, setstartdate] = useState();
  const [starttime, setstarttime] = useState("");
  const [enddateTimeString, setendDateTimeString] = useState("");
  const [endtime, setendTime] = useState("");
  const [location, setLocation] = useState({});
  const [address, setAddress] = useState("");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    singleBooking(params.id)
      .then((response) => {
        const apiData = response.data.data;
        const lat = apiData && apiData.address && apiData.address.cordinates[0];
        const lng = apiData && apiData.address && apiData.address.cordinates[1];
        const address = `${
          apiData && apiData.address && apiData.address.streetAddress
        }, ${apiData && apiData.address && apiData.address.nickname},  ${
          apiData && apiData.address && apiData.address.province
        }, ${apiData && apiData.address && apiData.address.postalcode} `;
        setstartDateTimeString(
          apiData && apiData.timeslot && apiData.timeslot.startTime
        );
        setendDateTimeString(
          apiData && apiData.timeslot && apiData.timeslot.endTime
        );
        setBookingDetails(apiData);
        setLocation({ lat, lng });
        setAddress(address);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (startdateTimeString !== "" && enddateTimeString !== "") {
      const startdateTime = new Date(startdateTimeString);
      const starttime = startdateTime.toLocaleTimeString();
      const enddateTime = new Date(enddateTimeString);
      const endtime = enddateTime.toLocaleTimeString();
      setstartdate(startdateTime);
      setstarttime(starttime);
      setendTime(endtime);
    }
  }, [startdateTimeString, enddateTimeString]);

  return (
    <div className="confirmation-main-content">
      <div className="confirmation-main-wrapper">
        <div className="bg-confirmation">
          <img style={{ width: "100%", height: "72%" }} src={gradient} alt="" />
        </div>
        <div className="confirmation-wrapper">
          <div className="confirmation-content">
            <div className="confirmation-heading">
              <strong>
                <div className="span-heading">Thank You !</div>
                {/* <div className="span-heading">oops!</div> */}
              </strong>
            </div>
            <div className="confirmation-para">Your booking is confirmed</div>
            {/* <div className="confirmation-para">Your booking is failed</div> */}
            <div className="confirmation-para">
              Booking ID : {bookingDetails && bookingDetails.bookingId}
            </div>
          </div>
          <div className="confirmation-image">
            <img style={{ width: "77%" }} src={ConfirmationSuccess} alt="" />
          </div>
        </div>
      </div>
      <div className="confirmation-detail-wrapper">
        <div className="confirmation-detail-content">
          <div className="confirmation-details">
            <div className="confirmation-detail-heading">Details</div>
          </div>
          <div className="confirmation-details">
            <div style={{ flex: "1" }}>Package</div>
            <div style={{ flex: "1", fontWeight: "normal" }}>
              {bookingDetails &&
                bookingDetails.packageDetails &&
                bookingDetails.packageDetails.packageName}
            </div>
          </div>
          <div className="confirmation-details">
            <div style={{ flex: "1" }}>Vehicle</div>
            <div style={{ flex: "1", fontWeight: "normal" }}>
              {bookingDetails &&
                bookingDetails.packageDetails &&
                bookingDetails.vehicle.nickname}
            </div>
          </div>
          <div className="confirmation-details">
            <div style={{ flex: "1" }}>Location</div>
            <div style={{ flex: "1", fontWeight: "normal" }}>
              {" "}
              {bookingDetails &&
                bookingDetails.packageDetails &&
                bookingDetails.address.streetAddress}
            </div>
          </div>
          <div className="confirmation-details">
            <div style={{ flex: "1" }}>Date</div>
            <div style={{ flex: "1", fontWeight: "normal" }}>
              {" "}
              {`${
                monthNames[startdate?.getMonth()]
              } ${startdate?.getDate()},  ${startdate?.getFullYear()} `}
            </div>
          </div>
          <div className="confirmation-details">
            <div style={{ flex: "1" }}>Time</div>
            <div style={{ flex: "1", fontWeight: "normal" }}>
              {`${starttime} - ${endtime}`}
            </div>
          </div>
          <div className="confirmation-details">
            <Link
              style={{
                flex: "1",
                fontWeight: "normal",
                color: "black",
                textDecoration: "underline",
              }}
              to="/bookingHistory"
            >
              Review Booking
            </Link>
          </div>
          <div className="confirmation-contact">
            <div className="confirmation-contact-details">
              <div style={{ paddingRight: "1em" }}>
                <img src={phoneicon} alt="" />
              </div>
              <div style={{ flex: "1" }}>(877) 754-8545</div>
            </div>
            <div className="confirmation-contact-details">
              <div style={{ paddingRight: "1em" }}>
                <img src={mailicon} alt="" />
              </div>
              <div style={{ flex: "1" }}>helpdesk@kivlik.ca</div>
            </div>
          </div>
        </div>
        <div className="location-container">
          <Location lat={location.lat} lng={location.lng} address={address} />
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
