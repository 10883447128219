import React from "react";
import "./SteamBenefit.css";
import eco from "../../../assets/eco-friendly.svg";
import economic from "../../../assets/economic.svg";
import hypohygenic from "../../../assets/hypohygenic.svg";
import flexibilityclean from "../../../assets/flexibilityclean.svg";
import deepcleaning from "../../../assets/deepcleaning.svg";
function SteamBenefit() {
  return (
    <div className="steam-benefits-container">
      <div className="steam-benefits-heading">BENEFITS OF STEAM</div>
      <div className="steam-benefits-content">
        The Steam Car wash uses steam vapor to clean a car’s exterior and
        interior. It uses a reasonable amount of pressurized steam jet
        continuously to clean the car.
      </div>
      <div className="steam-benefits-images">
        <div className="steam-benefits-image-wrapper">
          <div className="steam-benefits-image">
            <img src={eco} alt="" />
          </div>
          <div className="steam-benefits-image-content"> ECO FRIENDLY</div>
        </div>
        <div className="steam-benefits-image-wrapper">
          <div className="steam-benefits-image">
            <img src={deepcleaning} alt="" />
          </div>
          <div className="steam-benefits-image-content">DEEP CLEANING</div>
        </div>
        <div className="steam-benefits-image-wrapper">
          <div className="steam-benefits-image">
            <img src={flexibilityclean} alt="" />
          </div>
          <div className="steam-benefits-image-content">FLEXIBLE</div>
        </div>
        <div className="steam-benefits-image-wrapper">
          <div className="steam-benefits-image">
            <img src={hypohygenic} alt="" />
          </div>
          <div className="steam-benefits-image-content">HYPOALLERGENIC</div>
        </div>
        <div className="steam-benefits-image-wrapper">
          <div className="steam-benefits-image">
            <img src={economic} alt="" />
          </div>
          <div className="steam-benefits-image-content">ECONOMICAL</div>
        </div>
      </div>
    </div>
  );
}

export default SteamBenefit;
