import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { userAuthContext } from "../context/UserAuthContext";
import { authLogin } from "../Store/userAuth/userAuthSlice";

function ProtectedRoute(props) {
  const dispatch = useDispatch();
  const { state } = useContext(userAuthContext);
  if (state.userDataPresent) {
    if (state.user == null) {
      return <Redirect to="/login"></Redirect>;
    } else {
      dispatch(authLogin());
      return (
        <Route exact path={props.path}>
          {props.children}
        </Route>
      );
    }
  } else {
    return null;
  }
}

export default ProtectedRoute;
