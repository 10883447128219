import React from 'react'

function TermsComponent({ data }) {
    return (
        <>
            <h2> {data?.heading} </h2>
            <p className='termsPara' >
                {data?.para?.split('\n').map(str => <p>{str}</p>)}
            </p>
        </>
    )
}

export default TermsComponent

