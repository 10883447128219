import carWaxImage3 from "../../../assets/carWaxImg3.jpeg";
import carWaxImage4 from "../../../assets/carWaxImg4.jpeg";
import carWaxImage5 from "../../../assets/carWaxImg5.jpeg";
import carWaxImage6 from "../../../assets/carWaxImg6.jpeg";
import carWaxImage7 from "../../../assets/carWaxImg7.jpeg";
import carWaxImage8 from "../../../assets/carWaxImg8.jpeg";
import carWaxImage9 from "../../../assets/carWaxImg9.jpeg";
import carWaxImage10 from "../../../assets/carWaxImg10.jpeg";
import carWaxImage11 from "../../../assets/carWaxImg11.jpeg";

export const GALLERY_CONTENT_DATA = [
  {
    heading: "Steam Car wash and Wax",
    content:
      "The Steam Car wash uses steam vapour to clean a car’s exterior and interior. It uses a reasonable amount of pressurised steam jet continuously to clean the car. \n The heat and pressure of the steam breaks up grease and dirt, leaving your car extremely clean. A steam car wash deeply sanitises and gets into hard to clean places, it also prevents scratches and never leaves watermarks.",
    img1: carWaxImage3,
    img2: carWaxImage4,
    img3: carWaxImage5,
    left: false,
    backGroundPosition: "10% 5%",
  },
  {
    heading: "Don’t skip the wash day",
    content:
      "The longer you let the remains of salt sit on your car, the more damage it causes. To avoid this, wash your car regularly with a high-pressure hose to clean hard-to-reach areas, and make sure to put that lawn sprinkler to good use to cover the undercarriage. \n If you’re opting for paid car wash, check whether they’re using repurposed water as it might contain traces of salt. \n It’s advised to avoid driving before and after a snowstorm since you’ll be unlucky enough to run into fresh road salt at those times. But if you must, make sure you wash your car later.",
    img1: carWaxImage4,
    img2: carWaxImage5,
    img3: carWaxImage6,
    left: false,
    backGroundPosition: "80% 5%",
  },
  {
    heading: "Steam Clean Interior",
    content:
      "The Steam Car wash uses steam vapour to clean a car’s exterior and interior. It uses a reasonable amount of pressurised steam jet continuously to clean the car. ",
    left: false,
    singleImage: carWaxImage7,
    backGroundPosition: "90% 20%",
  },
  {
    singleImageHeading: (
      <h3 className="single-image-heading font-extralight">
        {" "}
        KIVLIK’s products and services are designed to provide a reliable
        solution to the{" "}
        <b className="text-indigo-800 font-light">
          {" "}
          world’s car wash and detailing requirements,
        </b>{" "}
        doing so in an innovative, convenient and sustainable manner.
      </h3>
    ),
    content: "",
    singleImage: carWaxImage11,
    left: true,
    backGroundPosition: "80% 80%",
  },
  {
    heading1: "Steam Clean Interior",
    content1:
      "Steam cleaning can penetrate deep into the fabric or carpet fibers and remove dirt, grime, and bacteria that a traditional cleaning method may not be able to reach. Steam cleaning uses only water and heat, making it an eco-friendly cleaning method that doesn't require any harsh chemicals. The high temperature of the steam can kill germs and bacteria, making it an effective way to sanitize surfaces.",
    heading2: "Wheels and Rims",
    content2:
      "Cleaning the wheels and rims of your car is an important part of regular maintenance, as they can accumulate brake dust, dirt, and grime that can cause damage over time. Steam detailing can keep your wheels and rims looking their best while preventing damage caused by brake dust and other debris.",
    left: false,
    singleImage: carWaxImage10,
    backGroundPosition: "90% 20%",
  },
  {
    heading1: "Carpets and mats",
    content1:
      "Steam cleaning carpets and mats is an effective way to deep clean and sanitize them. By steam cleaning your carpets and mats, you can effectively remove dirt, grime, and bacteria, leaving them clean and fresh. It's recommended to steam clean carpets and mats every 3-6 months to maintain their appearance and prolong their lifespan.",
    heading2: "Salt Removal",
    content2:
      "Steam cleaning is the best method to remove salt stains from carpets, mats, and other surfaces. By using steam to remove salt stains, you can effectively remove the salt residue and prevent further damage to the surface. It's important to clean salt stains as soon as possible to prevent them from causing permanent damage.",
    left: true,
    singleImage: carWaxImage9,
    backGroundPosition: "90% 20%",
  },
  {
    heading1: "Pet Hair Removal",
    content1:
      "Removing pet hair from your car can be a challenging task. KIVLIK has several methods to effectively remove pet hair from your car and keep it clean and free of allergens. Regularly cleaning your car's interior can help prevent pet hair buildup and make future cleaning tasks easier.",
    heading2: "Engine Bay",
    content2:
      "Engine bay steam cleaning is a process of using hot pressurized water vapor to clean the engine compartment of a vehicle. It is a safe and effective way to remove dirt, grime, and other contaminants from the engine bay, including hard-to-reach areas.",
    left: false,
    singleImage: carWaxImage8,
    backGroundPosition: "90% 20%",
  },
  {
    heading1: "Wax Protection for Exterior Paint",
    content1:
      "Wax protection for exterior paint is a process of applying a layer of wax to the paint surface of a vehicle to protect it from various environmental elements and to enhance its appearance. The wax creates a barrier between the paint and external elements, such as UV rays, dirt, and water, preventing damage and fading of the paint.",
    heading2: "Polishing",
    content2:
      "Car paint polishing is the process of removing imperfections and restoring the shine of the paint surface of a vehicle. It involves using a polishing compound and a polishing tool, such as an orbital or rotary buffer, to remove a very thin layer of clear coat from the paint surface, smoothing out any scratches or swirls in the paint.",
    left: true,
    singleImage: carWaxImage5,
    backGroundPosition: "90% 20%",
  },
  // {
  //   heading1: "Steam Clean Interior",
  //   content1:
  //     "The Steam Car wash uses steam vapour to clean a car’s exterior and interior. It uses a reasonable amount of pressurised steam jet continuously to clean the car.",
  //   heading2: "Wheels and Rims",
  //   content2:
  //     "The Steam Car wash uses steam vapour to clean a car’s exterior and interior. It uses a reasonable amount of pressurised steam jet continuously to clean the car.",
  //   left: true,
  //   singleImage: carWaxImage5,
  //   backGroundPosition: "90% 20%",
  // },
];
