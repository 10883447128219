import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authStatus: false,
  userName: "",
};

export const userAuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authLogin: (state) => {
      state.authStatus = true;
    },
    authLogout: (state) => {
      state.authStatus = false;
    },
    setMenuName: (state, payload) => {
      state.userName = payload.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { authLogin, authLogout, setMenuName } = userAuthSlice.actions;

export default userAuthSlice.reducer;
