export const svgIcons = [
  {
    id: "62c44ab0128c5c651f8a8003",
    src: require("../../../assets/sedan-blue-icon.svg").default,
    selected: require("../../../assets/sedan-blue-bg.svg").default,
  },
  {
    id: "62c44add128c5c651f8a801e",
    src: require("../../../assets/compactsuv-blue-icon.svg").default,
    selected: require("../../../assets/compactsuv-blue-bg.svg").default,
  },
  {
    id: "62c44b0b128c5c651f8a803f",
    src: require("../../../assets/midsizesuv-blue-icon.svg").default,
    selected: require("../../../assets/midsizesuv-blue-bg.svg").default,
  },
  {
    id: "62c44b3c128c5c651f8a8061",
    src: require("../../../assets/fullsizesuv-blue-icon.svg").default,
    selected: require("../../../assets/fullsizesuv-blue-bg.svg").default,
  },
  {
    id: "62c44bc2128c5c651f8a807f",
    src: require("../../../assets/minivan-blue-icon.svg").default,
    selected: require("../../../assets/minivan-blue-bg.svg").default,
  },
  {
    id: "62c44bda128c5c651f8a80a1",
    src: require("../../../assets/pickuptruck-blue-icon.svg").default,
    selected: require("../../../assets/pickuptruck-blue-bg.svg").default,
  },
  {
    id: "6428cd1a85cf356f0c6525c3",
    src: require("../../../assets/pickuptruckXL-blue-icon.svg").default,
    selected: require("../../../assets/pickupXL-blue-bg.svg").default,
  },
  {
    id: "64298e9185cf356f0c65283c",
    src: require("../../../assets/minitruck-blue-icon.svg").default,
    selected: require("../../../assets/minitruck-blue-bg.svg").default,
  },

  {
    id: "64298e9c85cf356f0c65284b",
    src: require("../../../assets/truck-blue-icon.svg").default,
    selected: require("../../../assets/truck-blue-bg.svg").default,
  },
  {
    id: "6429908485cf356f0c65285d",
    src: require("../../../assets/campervan-blue-icon.svg").default,
    selected: require("../../../assets/campervan-blue-bg.svg").default,
  },
];

export const packageName = {
  "6377a74d48add1c3dc48d18a": "gold",
  "637ba3167f0458b56e2bfc0a": "platinum",
  "637ba3797f0458b56e2bfe80": "diamond",
  "64219b4e85cf356f0c632b98": "emrald",
};
