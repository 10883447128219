import { axiosInstance, testAxiosInstance } from "../axios/axios";

//Register the user

//Getting All account details
export const getAccountDetails = async () => {
  const instance = await axiosInstance();
  const response = await instance.get(
    "/profile?firstName=true&lastName=true&address=true&phoneNumber=true&roles=true&createdAt=true&updatedAt=true&email=true"
  );
  return response;
};

//Adding Address
export const addAddress = async (body) => {
  const instance = await axiosInstance();
  const response = await instance.post("/profile/address/add", { ...body });
  return response;
};

//reguster user
export const registerUser = async (data) => {
  const instance = await axiosInstance();
  const response = await instance.post("/profile/register", { ...data });
  return response;
};

//editing user-profile
export const editUserProfile = async (data) => {
  const instance = await axiosInstance();
  const response = await instance.post("/profile/edit", { ...data });
  return response;
};

//editting Address details
export const editAddressDetails = async (body) => {
  const instance = await axiosInstance();
  const response = await instance.post("/profile/address/edit", {
    ...body,
  });
  return response;
};

//Deleting Address
export const deleteAddressDetails = async (ID) => {
  const addressId = { id: ID };
  const instance = await axiosInstance();
  const response = await instance.post("/profile/address/delete", {
    ...addressId,
  });
  return response;
};

//Getting All Vehicle details
export const getAllVehicle = async () => {
  const instance = await axiosInstance();
  const response = await instance.get("/vehicle/all");
  return response;
};

//Getting vehicle data with id

export const getVehicle = async (id) => {
  const instance = await axiosInstance();
  const response = await instance.get(`/vehicle/${id}`);
  return response;
};

//Adding vehicle
export const addVehicle = async (body) => {
  const instance = await axiosInstance();
  const response = await instance.post("vehicle/add", { ...body });
  return response;
};

//Deleting vehicle with id

export const deleteVehicle = async (id) => {
  const vehicleId = { vehicleId: id };
  const instance = await axiosInstance();
  const response = await instance.post("/vehicle/delete", {
    ...vehicleId,
  });
  return response;
};

//Editing vehicle with id

export const updateVehicle = async (body) => {
  const instance = await axiosInstance();
  const response = await instance.put("/vehicle/edit", { ...body });
  return response;
};

//Adding bodytype add
export const addBodyType = async (body) => {
  const instance = await axiosInstance();
  const response = await instance.post("/bodytype/add", {
    body: JSON.stringify(body),
  });
  return response;
};

//Getting all bodytype
export const getAllBodyType = async (id) => {
  const instance = await axiosInstance();
  const response = await instance.get("/bodytype/all");
  return response;
};

//Adding bodytype add
export const editBodyType = async (body) => {
  const instance = await axiosInstance();
  const response = await instance.post("/bodytype/edit", {
    body: JSON.stringify(body),
  });
  return response;
};

//getting single bodytype
export const getBodyType = async (id) => {
  const instance = await axiosInstance();
  const response = await instance.get(`/bodytype/?bodytypeId=${id}`);
  return response;
};

//getting all packages
export const getAllPackages = async (id) => {
  const instance = await axiosInstance();
  const response = await instance.get("/package");
  return response;
};

export const getAllSlot = async (id) => {
  const instance = await axiosInstance();
  const response = await instance.get("/timeslot");
  return response;
};

//booking
export const finalBooking = async (body) => {
  const instance = await axiosInstance();
  const response = await instance.post("/booking", { ...body });
  return response;
};

export const singleBooking = async (id) => {
  const instance = await axiosInstance();
  const response = await instance.get(`/booking?bookingId=${id}`);
  return response;
};

//calcute booking tax
export const bookingCost = async (body) => {
  const instance = await axiosInstance();
  const response = await instance.post("booking/calculate-amount", { ...body });
  return response;
};

export const getAllBooking = async () => {
  const instance = await axiosInstance();
  const response = await instance.get("booking/all");
  return response;
};

export const getAllService = async () => {
  const instance = await axiosInstance();
  const response = await instance.get("/package/service/all");
  return response;
};

export const cancelBooking = async (id) => {
  const instance = await axiosInstance();
  const response = await instance.post("/booking/cancel", { ...id });
  return response;
};

export const getSingleAddress = async (id) => {
  const instance = await axiosInstance();
  const response = await instance.get(`/profile/address/${id}`);
  return response;
};

export const postContactUsForm = async (form) => {
  const { name, email, phone, message } = form;
  const instance = await axiosInstance();
  const formData = new FormData();
  formData.append("name", name);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("message", message);
  const response = await instance.post("/contact", formData);
  return response;
};

export const postCareers = async (
  firstName,
  lastName,
  email,
  telephone,
  cv
) => {
  const instance = await axiosInstance();
  // instance.interceptors.request.use((config) => {
  //   config.headers["Content-Type"] = "multipart/form-data";
  // });
  const formData = new FormData();
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("email", email);
  formData.append("telephone", telephone);
  formData.append("cv", cv);
  const response = await instance.post("/career/apply", formData);
  return response;
};

export const postShareAppLink = async (email) => {
  const instance = await axiosInstance();
  const formData = new FormData();
  formData.append("email", email);
  const response = await instance.post("/contact", formData);
  return response;
};
export const postPlanSubscription = async (planId) => {
  const instance = await axiosInstance();
  const formData = new FormData();
  formData.append("planId", planId);
  const response = await instance.post("/create-subscription", formData);
  return response;
};

export const postSubscriptionSuccess = async (body) => {
  const instance = await testAxiosInstance();
  const response = await instance.post("/payment/postSubscriptionSuccess", {
    ...body,
  });
  return response;
};
