import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  calenderData: {},
};

export const userCalenderSlice = createSlice({
  name: "calender",
  initialState,
  reducers: {
    setCalenderData: (state, payload) => {
      state.calenderData = payload.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCalenderData } = userCalenderSlice.actions;

export default userCalenderSlice.reducer;
