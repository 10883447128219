import React, { useEffect, useState } from "react";
import carlogo from "../../assets/carlogo3.svg";
import locationcar from "../../assets/loactioncar.svg";
import "./MyAccount.css";
import { useHistory } from "react-router-dom";
import {
  deleteAddressDetails,
  deleteVehicle,
  getAccountDetails,
  getAllVehicle,
} from "../../utils/api";
import { useDispatch } from "react-redux";
import { getAddressdata } from "../../Store/userLocation/userLocationSlice";
import { setMenuName } from "../../Store/userAuth/userAuthSlice";
import Loading from "../../Container/Loading/Loading";
import { BiDotsVerticalRounded, BiUser } from "react-icons/bi";
import AddContainer from "../../Container/AddContainer/AddContainer";
import ModalAction from "../../Container/Modal/ModalAction/ModalAction";
import Modal from "../../Container/Modal/Modal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import helper from "../../utils/helper";
import { IoClose } from "react-icons/io5";
import ProfileEdit from "./ProfileEdit";
import { Link } from "react-router-dom";

function MyAccountDesktop() {
  const [accountDetail, setAccountDetail] = useState({});
  const [loading, setloading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const allSections = [
    "My Profile",
    "My Vehicles",
    "Location",
    "Payment Profile",
  ];
  const [currentSection, setCurrentSection] = useState("My Profile");
  useEffect(() => {
    getAccountDetails()
      .then((response) => {
        const routingText = location && location.state && location.state.text;
        const route = routingText
          ? helper.getDisplayValue("section", routingText)
          : "My Profile";
        setAccountDetail(response.data.data);
        dispatch(setMenuName(response.data.data.firstName));
        dispatch(getAddressdata(response.data.data.address));
        setCurrentSection(route);
        setloading(true);
      })
      .catch((err) => {
        if (err.response.data.errCode === "USER_NOT_REGISTERED") {
          history.push("/adduser");
        } else {
          console.log(err);
          setError(err);
        }
      });
    getAllVehicle()
      .then((response) => {
        setVehicle(response.data.data.length !== 0 ? response.data.data : []);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }, [dispatch]);

  return (
    <>
      {!error && (
        <>
          {!loading ? (
            <Loading />
          ) : (
            <div>
              <h1 className="uppercase text-white text-center font-semibold">
                My Account
              </h1>
              <div className="max-w-5xl w-full  bg-white rounded-lg max-h-[20rem] flex flex-wrap sm:flex-nowrap  p-10 gap-3 items-stretch mx-auto relative">
                <div className="max-w-[10rem] w-full bg-slate-100 flex flex-col gap-3 justify-start items-center p-4 px-8 rounded-xl">
                  {allSections.map((sect, i) => (
                    <div
                      key={i}
                      className={`p-4  py-4 rounded-xl drop-shadow-sm ${
                        currentSection === sect ? "bg-white" : "bg-transparent"
                      } w-full relative cursor-pointer `}
                      onClick={() => setCurrentSection(sect)}
                    >
                      <div
                        className={`absolute -left-2  rounded-full w-5 h-5 bg-[#17b3f0]/60 ${
                          currentSection === sect ? "flex" : "hidden"
                        }`}
                      ></div>
                      {sect}
                    </div>
                  ))}
                </div>
                <div className="w-full bg-slate-50 p-5 rounded-xl  overflow-auto">
                  {currentSection === "My Profile" && (
                    <MyProfile accountDetail={accountDetail} />
                  )}
                  {currentSection === "My Vehicles" && (
                    <MyVehicles vehicle={vehicle} />
                  )}
                  {currentSection === "Location" && (
                    <MyLocations locations={accountDetail?.address} />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

const MyProfile = ({ accountDetail }) => {
  const history = useHistory();
  return (
    <>
      <div className="flex flex-col gap-5">
        <div className="flex gap-3 items-center">
          <BiUser
            color="white"
            size={"4rem"}
            className="bg-[#17b3f0]/70 rounded-full p-5"
          />
          <div className="flex gap-2 flex-col">
            <p className="text-lg m-0">
              {`${
                accountDetail &&
                Object.keys(accountDetail).length === 0 &&
                Object.getPrototypeOf(accountDetail) === Object.prototype
                  ? ""
                  : accountDetail.firstName
              }  ${
                accountDetail &&
                Object.keys(accountDetail).length === 0 &&
                Object.getPrototypeOf(accountDetail) === Object.prototype
                  ? ""
                  : accountDetail.lastName
              }`}
            </p>
            <p className="textmd m-0">
              {accountDetail &&
              Object.keys(accountDetail).length === 0 &&
              Object.getPrototypeOf(accountDetail) === Object.prototype
                ? ""
                : accountDetail.email}
            </p>
            <p className="textmd m-0">
              {accountDetail &&
              Object.keys(accountDetail).length === 0 &&
              Object.getPrototypeOf(accountDetail) === Object.prototype
                ? ""
                : accountDetail?.phoneNumber}
            </p>
          </div>
        </div>
        {accountDetail && (
          <button
            className="self-center p-2 px-4 rounded-md bg-[#17b3f0]/70 text-white border-none text-center cursor-pointer"
            onClick={() => {
              history.push("/edit-profile", {
                firstName: accountDetail?.firstName,
                lastName: accountDetail?.lastName,
                email: accountDetail?.email,
              });
            }}
          >
            Edit
          </button>
        )}
      </div>
    </>
  );
};

const ProfileEditPopup = ({ setShow }) => {
  return (
    <div className="fixed top-0 left-0 z-50 min-h-screen w-full flex justify-center items-center bg-black/60 ">
      <div className="w-full max-w-sm rounded-md p-4">
        <ProfileEdit />
        {/* <div className="flex justify-between items-center">
          <h3 className="m-2">Edit Profile</h3>
          <IoClose onClick={() => setShow(false)} />
        </div>
        <div className="w-full flex flex-col items-center gap-4 mt-3">
          <input
            type="text"
            placeholder="Enter First Name"
            className="h-4 max-w-xs w-full rounded-md"
          />
          <input
            type="text"
            placeholder="Enter Last Name"
            className="h-4 max-w-xs w-full rounded-md"
          />
          <input
            type="text"
            placeholder="Enter Email"
            className="h-4 max-w-xs w-full rounded-md"
          />
          <button className="max-w-xs w-full bg-[#04a8e7] rounded-md p-2 border-none text-base text-white hover:bg-[#0490e7]">
            Submit
          </button>
        </div> */}
      </div>
    </div>
  );
};

const MyVehicles = ({ vehicle = [] }) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const history = useHistory();
  const addVehicleHandler = () => {
    history.push({
      pathname: "/addvehicle",
      state: { data: 2, text: "vehicle" },
    });
  };
  const deleteHandler = (id) => {
    setDeleteId(id);
    setModalStatus(true);
  };
  const actionModalHandler = (e) => {
    deleteVehicle(deleteId)
      .then((response) => {
        if (response.data.status === true) {
          setDeleteStatus(true);
          setModalStatus(false);
          e.preventDefault();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const closeModalHandler = () => {
    setModalStatus(false);
  };
  const deleteSuccessHandler = (e) => {
    setDeleteStatus(false);
    window.location.reload(false);
    e.preventDefault();
    history.push({
      pathname: `/myaccount`,
      state: { text: "vehicle" },
    });
  };

  const editHandler = (id) => {
    history.push({
      pathname: `/editvehicle/${id}`,
      state: { data: 0 },
    });
  };
  return (
    <>
      {modalStatus ? (
        <Modal>
          <div>
            <div className="actionHeader">Do you want to Delete?</div>
          </div>
          <ModalAction action={actionModalHandler} close={closeModalHandler} />
        </Modal>
      ) : (
        ""
      )}
      {deleteStatus ? (
        <Modal>
          <div>
            <div className="actionHeader">Successfully deleted</div>
          </div>
          <ModalAction close={deleteSuccessHandler} action={false} />
        </Modal>
      ) : (
        ""
      )}
      <div className="flex flex-wrap  gap-4 ">
        {vehicle.map((v, i) => {
          return (
            <div
              key={i}
              className="flex p-4 py-6 gap-3 items-center shadow-md bg-white rounded-lg max-w-xs w-full relative"
            >
              <img src={carlogo} alt="" width={"80px"} />

              <div>
                <p className="text-sm m-0">Name : {v?.nickname}</p>
                <p className="text-sm m-0">
                  Type : {v?.bodytype?.bodytypeName}
                </p>
                <p className="text-sm m-0">Number Plate : {v?.numberPlate}</p>
                <div className="absolute top-0 right-0">
                  <button className="border-none rounded-md bg-white p-2 text-sm text-black peer ">
                    <BiDotsVerticalRounded size={"1.4rem"} />
                  </button>
                  <div className="hidden absolute right-7 top-2 max-w-xs w-[8rem] h-auto bg-white shadow-xl rounded-lg p-1 ring-1 ring-slate-400 peer-hover:flex hover:flex flex-col gap-1">
                    <button
                      className="w-full border-none p-1 py-2 rounded-md bg-white shadow-inner hover:bg-[#17b3f0]/70 hover:text-white text-sm font-medium"
                      onClick={() => editHandler(v._id)}
                    >
                      Edit
                    </button>
                    <button
                      className="w-full border-none p-1 py-2 rounded-md bg-white shadow-inner hover:bg-[#17b3f0]/70 hover:text-white text-sm font-medium"
                      onClick={() => deleteHandler(v._id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
          // }
        })}
        {vehicle.length <= 4 ? (
          <div style={{ width: "100%", paddingTop: "1em" }}>
            <AddContainer onClickHandler={addVehicleHandler}>
              Add New Vehicle
            </AddContainer>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const MyLocations = ({ locations = [] }) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const history = useHistory();
  const addLocationHandler = () => {
    history.push({
      pathname: "/addlocation",
      state: { data: 2, text: "location" },
    });
  };
  const deleteHandler = (id) => {
    setDeleteId(id);
    setModalStatus(true);
  };
  const actionModalHandler = (e) => {
    deleteAddressDetails(deleteId)
      .then((response) => {
        if (response.data.status === true) {
          setDeleteStatus(true);
          setModalStatus(false);
          e.preventDefault();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const closeModalHandler = () => {
    setModalStatus(false);
  };

  const deleteSuccessHandler = (e) => {
    setDeleteStatus(false);
    window.location.reload(false);
    e.preventDefault();
    history.push({
      pathname: `/myaccount`,
      state: { text: "location" },
    });
  };
  const editHandler = (id) => {
    history.push({
      pathname: `/editlocation/${id}`,
      state: { data: 0 },
    });
  };
  return (
    <>
      {modalStatus ? (
        <Modal>
          <div>
            <div className="actionHeader">Do you want to Delete?</div>
          </div>
          <ModalAction action={actionModalHandler} close={closeModalHandler} />
        </Modal>
      ) : (
        ""
      )}
      {deleteStatus ? (
        <Modal>
          <div>
            <div className="actionHeader">Successfully deleted</div>
          </div>
          <ModalAction close={deleteSuccessHandler} action={false} />
        </Modal>
      ) : (
        ""
      )}
      <div className="flex flex-wrap gap-4">
        {locations.map((location, i) => {
          return (
            <div
              key={i}
              className="flex p-4 gap-3 items-center shadow-md bg-white max-w-xs w-full rounded-md relative"
            >
              <img src={locationcar} alt="" width={"50px"} />

              <div>
                <p className="text-MD m-0">
                  Address : {location?.streetAddress}
                </p>
                <p className="text-MD m-0">City : {location?.city}</p>
                <p className="text-MD m-0">Country : {location?.country}</p>
              </div>
              <div className="absolute top-0 right-0">
                <button className="border-none rounded-md bg-white p-2 text-sm text-black peer ">
                  <BiDotsVerticalRounded size={"1.4rem"} />
                </button>
                <div className="hidden absolute right-7 top-2 max-w-xs w-[8rem] h-auto bg-white shadow-xl rounded-lg p-1 ring-1 ring-slate-400 peer-hover:flex hover:flex flex-col gap-1">
                  <button
                    className="w-full border-none p-1 py-2 rounded-md bg-white shadow-inner hover:bg-[#17b3f0]/70 hover:text-white text-sm font-medium"
                    onClick={() => editHandler(location._id)}
                  >
                    Edit
                  </button>
                  <button
                    className="w-full border-none p-1 py-2 rounded-md bg-white shadow-inner hover:bg-[#17b3f0]/70 hover:text-white text-sm font-medium"
                    onClick={() => deleteHandler(location._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
          // }
        })}
        {locations.length <= 4 ? (
          <div style={{ width: "100%", paddingTop: "1em" }}>
            <AddContainer onClickHandler={addLocationHandler}>
              Add New Location
            </AddContainer>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MyAccountDesktop;
