import React from "react";
import Button from "../../Button/Button";
import style from "./ModalAction.module.css";
function ModalAction(props) {
  const actionStyle = `${style.actions}`;
  return (
    <div className={actionStyle}>
      <Button onClickEvent={props.close} className={style["button--alt"]}>
        <div className={style["content-shadow"]}>Close</div>
      </Button>
      {props.action ? (
        <Button onClickEvent={props.action} className={style.button}>
          <div className={style["content-shadow"]}>Confirm</div>
        </Button>
      ) : (
        ""
      )}
    </div>
  );
}

export default ModalAction;
