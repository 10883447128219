import styles from "./style.module.css";
import { IoCashOutline } from "react-icons/io5";
import dot from "../../../assets/dot.svg";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineClockCircle,
} from "react-icons/ai";
import helper from "../../../utils/helper";
import React, { useState } from "react";
import { cancelBooking } from "../../../utils/api";
import Modal from "../../../Container/Modal/Modal";
import ModalAction from "../../../Container/Modal/ModalAction/ModalAction";
const getStatusCard = (status) => {
  switch (status) {
    case "payment-success":
      return (
        <div className={[styles.statusIcon, styles.completedStatus].join(" ")}>
          <AiOutlineCheckCircle color="#73C16F" />
          <div>{status}</div>
        </div>
      );
    case "timeout":
      return (
        <div className={[styles.statusIcon, styles.upcomingStatus].join(" ")}>
          <AiOutlineClockCircle color="#DB9316" />
          <div>{status}</div>
        </div>
      );
    case "initiated":
      return (
        <div className={[styles.statusIcon, styles.cancelledStatus].join(" ")}>
          <AiOutlineCloseCircle color="#FC2121" />
          <div>{status}</div>
        </div>
      );
    case "refund_created":
      return (
        <div className={[styles.statusIcon, styles.cancelledStatus].join(" ")}>
          <AiOutlineCloseCircle color="#FC2121" />
          <div>{status}</div>
        </div>
      );
    default:
      return <></>;
  }
};
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const BookingCard = ({ data, selectedTab }) => {
  const [Id, setId] = useState();
  const [bookingId, setBookingId] = useState();
  const [cancelModal, setCancelModal] = useState();
  const [successModal, setSuccessModal] = useState();
  const [modalContent, setModalContent] = useState();
  const bookingDate = new Date(
    helper.formatZonalDate(new Date(data.createdAt))
  );
  const serviceDate = new Date(
    helper.formatZonalDate(new Date(data.timeslot.startTime))
  );
  const serviceTimeDetailed = serviceDate.toLocaleTimeString().split(" ");
  const serviceTime = `${serviceTimeDetailed[0]
    .split(":")
    .slice(0, 2)
    .join(":")} ${serviceTimeDetailed[1]}`;

  const dropdownHandler = (id) => {
    if (data._id === id) {
      setId(id);
      data.showDropdown = true;
    }
  };
  const onblurhandler = (id) => {
    if (data._id === id) {
      setId("");
      data.showDropdown = false;
    }
  };

  const cancelHandler = (bookingId, dateString) => {
    const date = dateString;
    const givenDate = new Date(date);
    const currentDate = new Date();

    if (givenDate.getTime() < currentDate.getTime()) {
      setSuccessModal(true);
      setModalContent("You can't cancel the booking after the start time");
    } else {
      setCancelModal(true);
      setBookingId(bookingId);
    }
  };

  const actionModalHandler = (e) => {
    const body = {
      bookingId: bookingId,
    };
    console.log("check 2", bookingId);
    cancelBooking(body)
      .then((response) => {
        setCancelModal(false);
        setSuccessModal(true);
        console.log(response.data.message, "check3");
        setModalContent(response.data.message);
        // setSuccessModal(() => {
        //   setModalContent(() => {
        //     // Render the success message here
        //     const prevModal = response.message;
        //     return prevModal;
        //   });
        //   return true;
        // });
      })
      .catch((err) => {
        console.log(err);
        setCancelModal(false);
      });
  };
  const closeModalHandler = () => {
    setCancelModal(false);
  };

  const deleteSuccessHandler = (e) => {
    setSuccessModal(false);
    window.location.reload(false);
    e.preventDefault();
  };

  const convertMinutes = (minutes) => {
    const hour = Math.floor(minutes / 60);
    const min = minutes % 60;
    if (min > 0) return ` ${hour}H ${min}Min `;
    else return ` ${hour}H `;
  };

  return (
    <React.Fragment key={data._id}>
      {cancelModal ? (
        <Modal>
          <div>
            <div className="actionHeader">
              Do you want to Cancel this Booking?
            </div>
          </div>
          <ModalAction action={actionModalHandler} close={closeModalHandler} />
        </Modal>
      ) : (
        ""
      )}
      {successModal ? (
        <Modal>
          <div>
            <div className="actionHeader">{modalContent}</div>
          </div>
          <ModalAction close={deleteSuccessHandler} action={false} />
        </Modal>
      ) : (
        ""
      )}

      <div className={styles.bookingCard}>
        <div className={styles.mainArea}>
          {data && data.status === "payment-success" ? (
            <div className={styles.dotplacer}>
              <div className={styles.date}>
                {`${data.vehicle?.numberPlate}`}
                {/* {`${
                  monthNames[bookingDate?.getMonth()]
                } ${bookingDate?.getDate()},  ${bookingDate?.getFullYear()} `} */}
              </div>
              <div tabIndex={0} onBlur={() => onblurhandler(data._id)}>
                <div
                  className={styles.dot}
                  onClick={() => dropdownHandler(data._id)}
                >
                  <img src={dot} alt="" />
                </div>
                {data.showDropdown && data._id === Id ? (
                  <div className={styles.cancelDropdown}>
                    <ul className={styles.cancellist}>
                      <li
                        className={styles["cancel-content"]}
                        onClick={() =>
                          cancelHandler(data.bookingId, data.timeslot.startTime)
                        }
                      >
                        Cancel
                      </li>
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className={styles.date}>
              {`${data.vehicle?.numberPlate}`}
              {/* {`${
                monthNames[bookingDate?.getMonth()]
              } ${bookingDate?.getDate()},  ${bookingDate?.getFullYear()} `} */}
            </div>
          )}

          {console.log(data, "ajay")}
          <div className={styles.content}>
            <div className={styles.packageDetails}>
              <div className={styles.packageName}>
                {data && data.packageDetails && data.packageDetails.packageName}
              </div>
            </div>
            {/* <div className={styles.amount}>
              ${data.totalBookingAmountInCents / 100}
            </div> */}
          </div>
          <div className={styles.bookingId}>Booking ID: {data.bookingId}</div>
          <div className={styles.bookingId}>
            Service Date:{" "}
            {`${
              monthNames[serviceDate?.getMonth()]
            } ${serviceDate?.getDate()},  ${serviceDate?.getFullYear()} `}
          </div>
          <div className={styles.bookingId}>
            Service Time :{" "}
            {console.log(serviceTimeDetailed[0].split(":").slice(0, 2))}
            {serviceTime}
          </div>
        </div>
        <div className={styles.actionArea}>
          <div className={styles.status}>{getStatusCard(data.status)}</div>
          <div className={styles.amount}>
            ${data.totalBookingAmountInCents / 100}
          </div>
          {/* <div className={styles.paymentStatus}>
            {data.status === "cancelled" ? (
              <div className={styles.notPaid}>
                <IoCashOutline color="#FC0D0D" />
                <div>Not Paid</div>
              </div>
            ) : (
              <div className={styles.paid}>
                <IoCashOutline color="#73C16F" />
                <div>Paid</div>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default BookingCard;
