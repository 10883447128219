import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    console.log(pathname);
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  }, [pathname]);

  return null;
}
