import React, { useEffect, useState } from "react";
import locationcar from "../../assets/loactioncar.svg";
import AddContainer from "../../Container/AddContainer/AddContainer";
import selecttick from "../../assets/selecttick.svg";
import bluetick from "../../assets/bluetick.svg";
import dot from "../../assets/dot.svg";
import "./SelectLocation.css";
import { getAccountDetails } from "../../utils/api";
import { getAddressdata } from "../../Store/userLocation/userLocationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  bookingData,
  checkValidation,
  updateData,
} from "../../Store/userBooking/userBookingSlice";
import Loading from "../../Container/Loading/Loading";
function SelectLocation() {
  const [selectIndex, setSelectIndex] = useState(0);
  const [size, setSize] = useState(0);
  const [loading, setloading] = useState(false);
  const location = useSelector((state) => state.location.location);
  const existingData = useSelector((state) => state.booking.selectedData);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getAccountDetails()
      .then((response) => {
        dispatch(getAddressdata(response.data.data.address));
        setSize(response.data.data.address.length);
        if (response.data.data.address.length === 0) {
          history.push({
            pathname: "/addlocation",
            state: { data: 1, backflag: false },
          });
        } else {
          if (existingData.hasOwnProperty("location")) {
            setSelectIndex(existingData.location._id);
            dispatch(checkValidation("location"));
            setloading(true);
          } else {
            const actionType = {
              index: "location",
              item: response.data.data.address[0],
            };
            setSelectIndex(response.data.data.address[0]._id);
            dispatch(updateData(actionType));
            dispatch(bookingData(actionType));
            setloading(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addLocationHandler = () => {
    history.push({
      pathname: "/addlocation",
      state: { data: 1 },
    });
  };

  const selectlocationHandler = (id, item) => {
    const actionType = {
      index: "location",
      item: item,
    };
    dispatch(updateData(actionType));
    dispatch(bookingData(actionType));
    setSelectIndex(id);
  };

  return (
    <>
      {!loading ? (
        <Loading />
      ) : (
        <div className="selectLocation-container">
          <div className="selectLocation-placer">
            <div className="selectLocation-header">
              <h2>SELECT YOUR LOCATION</h2>
            </div>
            <div className="selectLocation-divider-card">
              {location.map((item) => {
                return (
                  <div
                    key={item._id}
                    onClick={() => selectlocationHandler(item._id, item)}
                    className="selectLocation-card "
                  >
                    <div className="selectLocation-tick">
                      {selectIndex === item._id ? (
                        <img src={bluetick} alt="" />
                      ) : (
                        <img src={selecttick} alt="" />
                      )}
                    </div>
                    <div className="selectLocation-dot">
                      <img src={dot} alt="" />
                    </div>
                    <div className="selectLocation-vehicle">
                      <div className="selectLocation-carlogo">
                        <img src={locationcar} alt="" />
                      </div>

                      <div className="selectLocation-card-section">
                        <div className="selectLocation-card-details">
                          Address : {item.streetAddress}
                        </div>
                        <div className="selectLocation-card-details">
                          City : {item.city}
                        </div>
                        <div className="selectLocation-card-details">
                          Nick Name :{item.nickname}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {size <= 4 ? (
              <AddContainer onClickHandler={addLocationHandler}>
                Add New Location
              </AddContainer>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default SelectLocation;
