import React from "react";
import "./CarWash.css";
function CarWash() {
  return (
    <div className="car-wash-container">
      <div>
        <div className="car-wash-heading">
          MOBILE STEAM CAR WASH & DETAILING
        </div>
        <div className="car-wash-content">
          KIVLIK is a mobile steam car wash company, which offers premium car
          wash and detailing services at your doorstep. KIVLIK has several
          packages to meet your budget and requirements. Conveniently book a
          carwash with a few taps using the KIVLIK App or website. You can pick
          a time, location and package from the App or web. KIVLIK washes each
          vehicle using our Steam Cleaning system.
        </div>
      </div>
    </div>
  );
}

export default CarWash;
