import React from "react";
import ReactDOM from "react-dom";
import style from "./ReviewModal.module.css";
import mainstar from "../../assets/modalstar.svg";
import star from "../../assets/star.svg";
import Button from "../../Container/Button/Button";
const Backdrop = (props) => {
  return <div className={style.backdrop} onClick={props.onClose}></div>;
};
const ModalOverlay = (props) => {
  return (
    <div className={style.modal}>
      <div>{props.children}</div>
    </div>
  );
};
function ReviewModal(props) {
  const data = [1, 2, 3, 4];
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onCloseModal} />,
        document.getElementById("overlays")
      )}

      {ReactDOM.createPortal(
        <ModalOverlay>
          <div>
            <div className={style["modal-head"]}>
              <img src={mainstar} alt="" />
            </div>
            <div className={style.modalsection}>
              <div className={style.modalstar}>
                <div>How did we do?</div>
                <div className={style.star}>
                  {data.map((item) => {
                    return <img src={star} alt="" />;
                  })}
                </div>
              </div>
              <div className={style.modalstar}>
                <div>Rate Executive</div>
                <div className={style.star}>
                  {data.map((item) => {
                    return <img src={star} alt="" />;
                  })}
                </div>
              </div>
              <div className={style.modalstar}>
                <div>Rate Wash</div>
                <div className={style.star}>
                  {data.map((item) => {
                    return <img src={star} alt="" />;
                  })}
                </div>
              </div>
            </div>

            <div className={style["modal-card-textarea"]}>
              <div className={style["modal-card-textarea-head"]}>
                Care to share more about it?
              </div>

              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Message"
              ></textarea>
            </div>
            <div style={{ paddingLeft: "3em" }}>
              <Button
                style={{
                  backgroundColor: "#20E493",
                  color: "#ffffff",
                  width: "16em",
                }}
              >
                PUBLISH FEEDBACK
              </Button>
            </div>
          </div>
        </ModalOverlay>,
        document.getElementById("overlays")
      )}
    </>
  );
}

export default ReviewModal;
