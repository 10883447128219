import React from "react";
import booking from "../../assets/booking.svg";
import contract from "../../assets/contract.svg";
import executive from "../../assets/executive.svg";
import "./SectionScreen.css";

function SectionScreen() {
  return (
    <div className="section-container">
      <div className="section-header">HOW DOES IT WORK ?</div>
      <div className="section-provider">
        <div className="section-iconholder">
          <div className="image-layout">
            <img src={booking} alt="" />
          </div>
          <div className="subsection-layout">BOOK A WASH</div>
          <div className="subsection-para">
            Book a carwash with a few taps using the Kivlik App or website. You
            can pick a time, location and package from the App or web.
          </div>
        </div>

        <div className="section-iconholder">
          <div className="image-layout">
            <img src={executive} alt="" />
          </div>
          <div className="subsection-layout">KIVLIK EXECUTIVE COMES TO YOU</div>
          <div className="subsection-para">
            Kivlik executives are fully equipped to perform a full car wash at
            your doorstep which includes power and water.
          </div>
        </div>

        <div className="section-iconholder">
          <div className="image-layout">
            <img src={contract} alt="" />
          </div>
          <div className="subsection-layout">
            REVIEW AND <br /> RATE
          </div>
          <div className="subsection-para">
            Once the wash is finished you will receive a notification on the
            App. Collect the sanitized key from the Kivlik executive and rate
            the service.
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionScreen;
