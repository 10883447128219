import React, { useState, useContext } from "react";
import Button from "../../Container/Button/Button";
import "./LoginOtp.css";
import { Link } from "react-router-dom";
import kiviklogo from "../../assets/KivlikLogocolor.jpg";
import { useHistory } from "react-router-dom";
import { userAuthContext } from "../../context/UserAuthContext";
import { useDispatch } from "react-redux";
import { authLogin, authLogout } from "../../Store/userAuth/userAuthSlice";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import helper from "../../utils/helper";
import { Helmet } from "react-helmet";
const whitelisted_countries = ["US", "CA", "IN"];
function LoginOtp() {
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const { setUpRecaptcha, logOut } = useContext(userAuthContext);
  const [confirmObj, setConfirmobj] = useState("");
  const dispatch = useDispatch();

  const onSignInSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const validateNumber = helper.validatePhoneNumber(phoneNumber);
    if (phoneNumber === "" || phoneNumber === undefined || !validateNumber) {
      return setError("Please Enter a valid Phone Number");
    }
    try {
      const response = await setUpRecaptcha(phoneNumber);
      setConfirmobj(response);
      setShowOtp(true);
    } catch (err) {
      setError(err.message);
    }
  };
  const verifyOtp = async (e) => {
    e.preventDefault();
    if (otp === "" || otp === null) {
      return;
    }
    try {
      setError("");
      const result = await confirmObj.confirm(otp);
      dispatch(authLogin());
      setTimeout(async () => {
        await logOut();
        dispatch(authLogout());
        history.push("login");
      }, 1000000);
      history.push("myaccount");
      if (result._tokenResponse.isNewUser) {
        // do register with server
        history.push("adduser");
      } else {
        history.push("myaccount");
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };
  const redirectToLogin = () => {
    setError(false);
    setPhoneNumber("");
  };
  const otpHandler = (e) => {
    setOtp(e.target.value);
  };

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber || "");

  return (
    <>
      <Helmet>
        <title>Kivlik - Log In</title>
      </Helmet>
      <div className="LoginOtp-container">
        <div className="LoginOtp-placer">
          <div className="LoginOtp-header">
            <h3>Sign In</h3>
          </div>
          <div className={showOtp ? "LoginOtp-card2" : "LoginOtp-card"}>
            <form
              className="LoginOtp-form"
              style={{ display: !showOtp ? "block" : "none" }}
            >
              <div className="login-logo">
                <Link to="/">
                  <img src={kiviklogo} alt=""></img>
                </Link>
              </div>
              {!showOtp && (
                <>
                  <div style={{ textAlign: "left", paddingLeft: "1em" }}>
                    <div className="login-card-header">
                      Welcome back to Kivlik
                    </div>
                    <label htmlFor="">
                      {" "}
                      We'll send a text to verify your phone.
                    </label>
                  </div>
                </>
              )}

              <PhoneInput
                addInternationalOption={false}
                defaultCountry="CA"
                className="LoginOtp-input"
                countries={whitelisted_countries}
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
              {error ? (
                <div className="error">{error}</div>
              ) : (
                <div id="recaptcha-container" />
              )}
            </form>
            <form
              className="LoginOtp-form"
              style={{ display: showOtp ? "block" : "none" }}
            >
              <div className="login-logo">
                <Link to="/">
                  <img src={kiviklogo} alt=""></img>
                </Link>
              </div>
              {showOtp ? (
                <div style={{ textAlign: "left", paddingLeft: "1em" }}>
                  <div className="login-card-header" htmlFor="">
                    Enter verification code
                  </div>
                  <label htmlFor="">
                    {" "}
                    Verify your account by typing in the 6 digit code we sent
                    via text.
                  </label>
                </div>
              ) : (
                ""
              )}
              <div style={{ paddingLeft: "1em" }}>
                <input
                  className="LoginOtp-input"
                  type="number"
                  name="otp"
                  placeholder="Enter Code"
                  required=""
                  value={otp}
                  onChange={otpHandler}
                />
                <div>
                  We sent a code to the following phone number:{" "}
                  {console.log(
                    parsePhoneNumber(phoneNumber || ""),
                    "parsed phone number"
                  )}
                  {parsedPhoneNumber &&
                    `+${parsedPhoneNumber.countryCallingCode} - ${parsedPhoneNumber.nationalNumber}`}
                  {/* {helper.formatPhoneNumber(phoneNumber)} */}
                </div>
              </div>
            </form>
          </div>
          {!showOtp ? (
            <div className="auth-submit">
              <div onClick={redirectToLogin} className="auth-sumbit-section">
                Cancel
              </div>
              <div className="">
                <Button onClickEvent={onSignInSubmit} style={{ width: "12em" }}>
                  Next
                </Button>
              </div>
            </div>
          ) : (
            <div className="auth-submit">
              <div onClick={redirectToLogin} className="auth-sumbit-section">
                Cancel
              </div>
              <div className="">
                <Button onClickEvent={verifyOtp} style={{ width: "12em" }}>
                  Verify OTP
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default LoginOtp;
