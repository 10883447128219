import React from "react";
import Content from "./Content/Content";
import MainPage from "./MainPage/MainPage";
import { Helmet } from "react-helmet";

function PricingPage() {
  return (
    <>
      <Helmet>
        <title>Kivlik - Pricing</title>
      </Helmet>
      <div style={{ backgroundColor: "#E4FEF5" }}>
        <MainPage />
        <Content />
      </div>
    </>
  );
}

export default PricingPage;
