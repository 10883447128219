import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./PaymentStripe.css";
import CheckoutForm from "./CheckoutForm/CheckoutForm";
import { useSelector } from "react-redux";

const publishablekey = localStorage.getItem("stripe-publishablekey") || "";
const stripePromise = loadStripe(publishablekey);

export default function PaymentStripe() {
  const clientSecret = useSelector(
    (state) => state.booking.selectedData.bookingResponse.secret
  );

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="stripe-style">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}
