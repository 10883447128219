import React, { useState } from "react";
import "./CustomerReview.css";
import ReviewModal from "../ReviewModal/ReviewModal";
import profile1 from "../../assets/profileicon.svg";
import { Helmet } from "react-helmet";

function CustomerReview() {
  const [show, setShow] = useState(false);
  const modalShowHandler = () => {
    setShow(false);
  };
  const modalHideHandler = () => {
    setShow(false);
  };
  const reviews = [
    {
      profileUrl: profile1,
      name: "Ashwin Shaji",
      provider: "Google",
      subHeading: "Extremely happy and satisfied...",
      content:
        "Extremely happy and satisfied with their work. My car had a few swirl marks and minor scratches on the car seats which the team was able to rectify within no time. Their attention to detail is of special mention.",
    },
    {
      profileUrl: profile1,
      name: "Deirdre Quinn",
      provider: "Google",
      subHeading: "My car has never looked so clean...",
      content:
        "My car has never looked so clean, and it feels brand new! Young children create many messes in cars, but now my car is spotless! The service, punctuality, and professionalism of this company was exceptional! I will be recommending to friends and family!!",
    },
    {
      profileUrl: profile1,
      name: "Santhosh George",
      provider: "Google",
      subHeading: "I had salt deposit to the driver...",
      content:
        "I had salt deposit to the driver and passenger seat floor carpets in my 2020 BMW X1. Kivlik came to my driveway and they did a good job. Very happy with the quality and timeliness of service. I recommend it.",
    },
    {
      profileUrl: profile1,
      name: "Cody Lachance",
      provider: "Google",
      subHeading: "I was extremely happy with...",
      content:
        "I was extremely happy with the job. I have a dirty 2015 civic and he managed to make it look bran new. He deserved much more then he charged me. It was also, very polite",
    },
    {
      profileUrl: profile1,
      name: "Meryem Benhoummane",
      provider: "Google",
      subHeading: "If you want to make sure...",
      content:
        "If you want to make sure you get the best car cleaning, then kivlik are your guys !!! Very professional, they did an amazing job with my car and made sure I was fully satisfied before paying",
    },
    {
      profileUrl: profile1,
      name: "Shiju Haridas",
      provider: "Google",
      subHeading: "The team has done an awesome job...",
      content:
        "The team has done an awesome job. My car looks as good as new. I highly recommend their service.",
    },
    {
      profileUrl: profile1,
      name: "Norman Adams",
      provider: "Google",
      subHeading: "An absolutely fantastic service...",
      content:
        "An absolutely fantastic service. They brought my 14 year old F150 and my 26 year old Mustang GT Coupe back to better than showroom quality. The '97 Mustang is car show quality and my F150 is almost pretty. Great service and great people.I recommend then as highly as possible",
    },
    {
      profileUrl: profile1,
      name: "Jimson James",
      provider: "Google",
      subHeading: "Thank you so much Kilvic for the ...",
      content:
        "Thank you so much Kilvic for the professional detailing.. It is my first time with Kilvic and my car looked like brand new inside..The guys had done such an amazing job. I would highly recommend others to use this service with 100% satisfaction guaranteed....",
    },
    {
      profileUrl: profile1,
      name: "Augustine Kallumkatharayil",
      provider: "Google",
      subHeading: "Thank you Kilvik team for your excellent ...",
      content:
        "Thank you Kilvik team for your excellent service and I appreciate your professionalism. The price is reasonable and I highly recommend Kilvik. Best wishes in your business.",
    },
    {
      profileUrl: profile1,
      name: "Dcn. Mathew",
      provider: "Google",
      subHeading: "It was my daughter's wedding and I tried...",
      content:
        "It was my daughter's wedding and I tried to rent a car. Unfortunately, I didn’t get a good luxury rental car for that date. A friend of mine recommended using my own black Ford Flex for it and suggested Kivilik for cleaning and detailing.  So I contacted Nevin of Kivilik to prepare the car for the ceremony. Nevin and his staff came early in the morning and their magical touch brought it back to the time it was brand new. They did a fabulous work inside and out and it was amazing to see the car and it made our day. Kivilik’s dedication and commitment to their customers is outstanding. I’m completely satisfied and in awe of what a great job they did. I would definitely recommend this business to anybody who expects the best service to their satisfaction.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Kivlik - Reviews</title>
      </Helmet>
      {show && <ReviewModal onCloseModal={modalHideHandler} />}
      <div className="customerReview-container">
        <div>
          <div className="customerReview-header">
            <div>CUSTOMERS REVIEWS</div>
            <div style={{ fontSize: ".6em" }}>
              What our customers say about our service
            </div>
          </div>
          <div className="customerReview-placer">
            <div className="customerReview-card" onClick={modalShowHandler}>
              <div className="customerReview-cardconatiner">
                <div className="customerReview-head">
                  <div>
                    <img src={profile1} alt="" style={{ width: " 6em" }} />
                  </div>
                  <div className="customerReview-card-header">
                    <div>Kyra Serene</div>
                    <div>Google</div>
                    <div>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="customerReview-card-section">
                    Great friendly service...
                  </div>
                  <div>
                    Great friendly service. My car looks great and for my first
                    experience with a steam cleaning interior and exterior, I'm
                    impressed! Thank you :)
                  </div>
                </div>
              </div>
            </div>
            <div className="customerReview-card" onClick={modalShowHandler}>
              <div className="customerReview-cardconatiner">
                <div className="customerReview-head">
                  <div>
                    <img src={profile1} alt="" style={{ width: " 6em" }} />
                  </div>
                  <div className="customerReview-card-header">
                    <div>Urvish Patel</div>
                    <div>Google</div>
                    <div>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="customerReview-card-section">
                    The people are professional...
                  </div>
                  <div>
                    The people are professional and loved their service. The
                    price quoted are very reasonable and they give you proper
                    information about every service they provide.
                  </div>
                </div>
              </div>
            </div>
            <div className="customerReview-card" onClick={modalShowHandler}>
              <div className="customerReview-cardconatiner">
                <div className="customerReview-head">
                  <div>
                    <img src={profile1} alt="" style={{ width: " 6em" }} />
                  </div>
                  <div className="customerReview-card-header">
                    <div>Jeff Benenati</div>
                    <div>Google</div>
                    <div>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="customerReview-card-section">
                    They did an excellent job...
                  </div>
                  <div>
                    They did an excellent job at getting the Latte out of my
                    carpets. Knowing my clumsiness, it probably won't be the
                    last time I call Kivlik for a similar fix.
                  </div>
                </div>
              </div>
            </div>

            <div className="customerReview-card" onClick={modalShowHandler}>
              <div className="customerReview-cardconatiner">
                <div className="customerReview-head">
                  <div>
                    <img src={profile1} alt="" style={{ width: " 6em" }} />
                  </div>
                  <div className="customerReview-card-header">
                    <div>Nirmal Kannan</div>
                    <div>Google</div>
                    <div>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="customerReview-card-section">
                    The guys from Kivlik were...
                  </div>
                  <div>
                    The guys from Kivlik were professional in offering their
                    mobile car detailing services. They were able to remove the
                    tough stains I have been struggling to clean for a long time
                    from my front grille using their steam cleaning method. It
                    was easy to book their service, they offer reasonable prices
                    and they can come to our doorstep which is very convenient.
                    My car came out looking good as new after the wash. Highly
                    recommended and very happy with their service.
                  </div>
                </div>
              </div>
            </div>
            {reviews.map((review) => (
              <div className="customerReview-card" onClick={modalShowHandler}>
                <div className="customerReview-cardconatiner">
                  <div className="customerReview-head">
                    <div>
                      <img
                        src={review.profileUrl}
                        alt=""
                        style={{ width: " 6em" }}
                      />
                    </div>
                    <div className="customerReview-card-header">
                      <div>{review.name}</div>
                      <div>{review.provider}</div>
                      <div>
                        <img src="" alt="" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="customerReview-card-section">
                      {review.subHeading}
                    </div>
                    <div>{review.content}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerReview;
