import React, { useState } from "react";
import "./FlowCard.css";
import flowcarlogo from "../../assets/flowcarlogo.svg";
import flowlocation from "../../assets/flowlocation.svg";
import flowlocation1 from "../../assets/flowlocation1.svg";
import flowpackage from "../../assets/flowpackage.svg";
import flowpackage1 from "../../assets/flowpackage1.svg";
import flowreview from "../../assets/flowreview.svg";
import flowreview1 from "../../assets/flowreview1.svg";
import flowtime from "../../assets/flowtime.svg";
import flowtime1 from "../../assets/flowtime1.svg";
import flowcarlogo1 from "../../assets/flowcarlogo1.svg";
import { ReactComponent as Arrow } from "../../assets/arrow2.svg";
import SelectVechile from "../../Component/SelectVechile/SelectVechile";
import SelectLocation from "../../Component/SelectLocation/SelectLocation";
import Package from "../../Component/Package/Package";
import BookingReview from "../../Component/BookingReview/BookingReview";
import GradientBase2 from "../Gradient-base2/GradientBase2";
import SchedulerNew from "../../Component/SchedulerNew/Scheduler";
import Button from "../Button/Button";
import line from "../../assets/line.svg";
import { useDispatch } from "react-redux";
import {
  backwardHandler,
  bookingData,
  forwardHandler,
  setModalStatus,
} from "../../Store/userBooking/userBookingSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { finalBooking } from "../../utils/api";
import { useEffect } from "react";

function FlowCard() {
  const [screen, setScreen] = useState("vehicle");
  const index = useSelector((state) => state.booking.page);
  const selectedData = useSelector((state) => state.booking.selectedData);
  const bookingID = useSelector((state) => state.booking.formData);
  const validation = useSelector((state) => state.booking.validationStatus);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // adjust 768 to your desired breakpoint
    };

    handleResize(); // set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const component = [
    {
      screenId: "vehicle",
      index: 0,
      image: flowcarlogo1,
      inActiveImage: flowcarlogo,
      screen: <SelectVechile />,
    },
    {
      screenId: "location",
      index: 1,
      image: flowlocation,
      inActiveImage: flowlocation1,
      screen: <SelectLocation />,
    },
    {
      screenId: "package",
      index: 2,
      image: flowpackage,
      inActiveImage: flowpackage1,
      screen: <Package />,
    },
    {
      screenId: "timeslot",
      index: 3,
      image: flowtime,
      inActiveImage: flowtime1,
      screen: <SchedulerNew />,
    },
    {
      screenId: "review",
      index: 4,
      image: flowreview,
      inActiveImage: flowreview1,
      screen: <BookingReview />,
    },
    // {
    //   screenId: "payment",
    //   index: 5,
    //   image: flowpayment,
    //   inActiveImage: flowpayment1,
    //   screen: <Payment />,
    // },
  ];
  const forwardflowHandler = () => {
    if (index === 3) {
      dispatch(setModalStatus(true));
    } else if (index === 1) {
      dispatch(forwardHandler());
    } else {
      dispatch(forwardHandler());
    }
    setScreen(component.find((item) => item.index === index + 1).screenId);

    window.scrollTo({ top: 0, behavior: "smooth" });
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  };

  const backwardFlowHandler = () => {
    if (index === 0) {
      history.push("myaccount");
    } else {
      dispatch(backwardHandler());
    }
    setScreen(component.find((item) => item.index === index - 1).screenId);

    window.scrollTo({ top: 0, behavior: "smooth" });
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  };

  const finalBookingHandler = () => {
    const additionalServices = selectedData.package.additionalServiceAdded.map(
      (obj) => obj.id.toString()
    );
    const finalBookingData = {
      timeslotId: bookingID.timeslot,
      addressId: bookingID.location,
      vehicleId: bookingID.vehicle,
      packageId: bookingID.package,
      additionalServices: additionalServices,
      note: selectedData.comment,
    };
    finalBooking(finalBookingData)
      .then((response) => {
        if (response.data.status) {
          const bookingResponse = {
            secret: response.data.secret,
            totalBookingAmountInCents: response.data.totalBookingAmountInCents,
            bookingId: response.data.bookingId,
          };
          const actionType = {
            index: "bookingResponse",
            item: bookingResponse,
          };
          dispatch(bookingData(actionType));
          history.push("/payment");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flowcard-conatiner">
      <div className="flowcard-mainsection">
        <div className="flowcard-section">
          <div className="flowcard-line">
            <img src={line} alt="" />
          </div>
          {component.map((items, index1) => {
            return (
              <div
                key={index1}
                className={`${
                  items.index === index ? "flowcard-image" : "flowcard-image1"
                }`}
              >
                {items.index === index ? (
                  <img src={items.inActiveImage} alt="" />
                ) : (
                  <img src={items.image} alt="" />
                )}
              </div>
            );
          })}
        </div>
      </div>

      <GradientBase2>
        {component.find((item) => item.index === index).screen}
        <div className="back-submit">
          <div>
            <Button
              onClickEvent={backwardFlowHandler}
              style={{
                gridGap: "1em",
                display: "flex",
                borderRadius: "5px",
                backgroundColor: "#17B3F0",
              }}
            >
              {!isMobile && (
                <Arrow
                  className="arrow-button "
                  style={{
                    transform: "rotate(180deg)",
                  }}
                  fill="#03577B"
                  stroke="#03577B"
                />
              )}
              <div>Back</div>
            </Button>
          </div>
          {validation ? (
            ""
          ) : screen !== "review" ? (
            <Button
              onClickEvent={forwardflowHandler}
              style={{
                gridGap: "1em",
                padding: `${isMobile ? "0.75em 1.9em" : "0.75em 2rem"}`,
                display: "flex",
                borderRadius: "5px",
              }}
              disabled={
                index === 3 &&
                selectedData?.package?.packageName === "STEAM GOLD"
              }
            >
              <div
                title={
                  index === 3 &&
                  selectedData?.package?.packageName === "STEAM GOLD" &&
                  "We are working on Gold Package, Kindly co-operate and select another package to continue."
                }
              >
                Continue
              </div>
              {!isMobile && (
                <Arrow
                  className="arrow-button"
                  fill="#17B3F0"
                  stroke="#17B3F0"
                />
              )}
            </Button>
          ) : (
            <Button
              onClickEvent={finalBookingHandler}
              style={{
                gridGap: "1em",
                padding: "0.75em 1rem",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <div>PAY NOW</div>
              <Arrow className="arrow-button" fill="#17B3F0" stroke="#17B3F0" />
            </Button>
          )}
        </div>
      </GradientBase2>
    </div>
  );
}

export default FlowCard;
