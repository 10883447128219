import React, { useEffect } from "react";
import carlogo from "../../assets/homeimage.svg";
import gradient from "../../assets/gradient.png";
import "./Landingscreen1.css";
import Button from "../../Container/Button/Button";
import { useHistory, useLocation } from "react-router";
import { postSubscriptionSuccess } from "../../utils/api";

function Landingscreen1() {
  const history = useHistory();
  const redirectToApp = () => {
    let path = `booking`;
    history.push(path);
  };

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const sessionId = searchParams.get("session_id");
  console.log(sessionId, "sessionId");
  // useEffect(() => {
  //   postSubscriptionSuccess({ sessionId })
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //     })
  //     .then((data) => {
  //       console.log(data, "data");
  //     })
  //     .catch((error) => console.log(error, "error"));
  // }, [sessionId]);
  return (
    <>
      <div className="main-content">
        <div className="image-wrapper">
          <div className="bg-image">
            <img
              style={{ width: "100%", height: "100%" }}
              src={gradient}
              alt=""
            />
          </div>
          <div className="landingpage-wrapper">
            <div className="landingpage-content">
              <div className="landingpage-heading">
                <strong>
                  <div className="span-heading flex flex-col">
                    <span className="text-2xl sm:text-2xl md:text-4xl">
                      CAR WASH & DETAILING{" "}
                      <span className="hidden lg:inline">|</span>
                    </span>
                    <span className="text-2xl sm:text-2xl md:text-4xl">
                      ON-DEMAND <span className=" hidden lg:inline">|</span>
                    </span>
                  </div>
                </strong>
                <span className="span-subheading text-xl sm:text-2xl md:text-4xl">
                  AT YOUR DOORSTEP
                </span>
              </div>
              <div className="landingpage-para">
                <p>
                  Need a car wash? We come to you. Avoid the long waiting and
                  get car washed at the comfort of your home.
                </p>
              </div>
              <Button onClickEvent={redirectToApp}>Book Now</Button>
            </div>
            <div className="landingpage-image">
              <img style={{ width: "100%" }} src={carlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landingscreen1;
