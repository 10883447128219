import React from "react";
import "./Footer.css";
function Footer() {
  return (
    <div className="footer-maincontainer">
      <div className="footer-container">
        <div className="footer-left">
          ©2023 KIVLIK Technologies Inc. All rights reserved.
        </div>
        <div className="footer-right">
          <div>
            <a href="/privacyPolicy">Privacy </a>
          </div>
          <span className="footer-span">|</span>
          <div>
            <a href="/termsAndCondition">Terms</a>
          </div>
          <span className="footer-span">|</span>
          <div>
            <a href="/">Security</a>
          </div>
          <span className="footer-span">|</span>
          <div>
            <a href="/sitemap.xml">Sitemap</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
