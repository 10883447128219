import React, { useState } from "react";
import Button from "../../Container/Button/Button";
import style from "./ProfileEdit.module.css";
import { useLocation } from "react-router-dom";
import { editUserProfile } from "../../utils/api";
import { useHistory } from "react-router-dom";

function ProfileEdit() {
  const history = useHistory();
  const { state: locationState } = useLocation();
  const [email, setEmail] = useState(locationState?.email);
  const [firstName, setFirstName] = useState(locationState?.firstName);
  const [lastName, setLastName] = useState(locationState?.lastName);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const registerData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    };
    editUserProfile(registerData)
      .then((response) => {
        if (response.data.status) {
          history.push("myaccount");
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message || "Something went wrong!");
      });
  };
  return (
    <div className={style["signup-container"]}>
      <div className={style["signup-placer"]}>
        <div className={style["signup-header"]}>
          <h3>Edit Profile</h3>
        </div>
        <div className={style["signup-card"]}>
          <form className={style["signup-form"]} onSubmit={handleSubmit}>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className={style["signup-divider"]}>
              <div className={style["signup-divider-section"]}>
                <input
                  className={style["signup-input"]}
                  type="text"
                  name="fname"
                  placeholder="First name"
                  onChange={(e) => setFirstName(e.target.value)}
                  defaultValue={firstName}
                />
                <input
                  className={style["signup-input"]}
                  type="text"
                  name="lname"
                  placeholder="Last name"
                  required
                  onChange={(e) => setLastName(e.target.value)}
                  defaultValue={lastName}
                />
                <input
                  className={style["signup-input"]}
                  type="text"
                  name="email"
                  placeholder="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  defaultValue={email}
                />
              </div>
            </div>
          </form>
        </div>
        <div className={style["signup-submit"]}>
          <div>
            <Button onClickEvent={handleSubmit}>Edit Profile</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileEdit;
