import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../userAuth/userAuthSlice";
import bookingReducer from "../userBooking/userBookingSlice";
import locationReducer from "../userLocation/userLocationSlice";
import calenderReducer from "../userBooking/userTime";
import packageReducer from "../userBooking/userPackage";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    booking: bookingReducer,
    location: locationReducer,
    calender: calenderReducer,
    package: packageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
