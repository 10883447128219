import gradient1 from "../../assets/background-gradient2.svg";
import React, { useEffect, useState } from "react";
import style from "./GradientBase.module.css";

const GradientBase = (props) => {
  return (
    <div className={style["signup-container"]}>
      <div className={style["signup-gradient-base"]}>
        <div className={`${style["signup-gradient"]} ${style.firstGradient}`}>
          <img
            className={style["signup-background"]}
            style={{ width: "100%", transform: "rotate(180deg)" }}
            src={gradient1}
            alt=""
          />
        </div>
        <div className={style.baseCard}>{props.children}</div>

        <div className={`${style["signup-gradient"]} ${style.secondGradient} `}>
          <img
            className={style["signup-background"]}
            style={{ width: "100%" }}
            src={gradient1}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default GradientBase;
