import React from "react";
import "./MainPage.css";
import gradient from "../../../assets/gradient.png";
import pricingcar from "../../../assets/pricingcar.svg";
function MainPage() {
  return (
    <div className="about-main-content">
      <div className="about-image-wrapper">
        <div className="about-bg-image">
          <img
            style={{ width: "100%", height: "100%" }}
            src={gradient}
            alt=""
          />
        </div>
        <div className="about-landingpage-wrapper">
          <div className="about-landingpage-content">
            <div className="about-landingpage-heading">PRICING </div>
          </div>
          <div className="about-landingpage-image">
            <img style={{ width: "86%" }} src={pricingcar} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
