import React, { useState } from "react";
import "./BookingReview.css";
import package1 from "../../assets/packageicon.svg";
import arrow from "../../assets/arrow.svg";
import time from "../../assets/time.svg";
import bookingcar from "../../assets/bookingcar.svg";
import location from "../../assets/bookinglocation.svg";
import date from "../../assets/reviewdate.svg";
import timesvg from "../../assets/reviewtime.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { bookingData } from "../../Store/userBooking/userBookingSlice";
import helper from "../../utils/helper";
import { useEffect } from "react";
import { bookingCost } from "../../utils/api";
import { axiosInstance } from "../../axios/axios";

function BookingReview() {
  const convertMinutes = (minutes) => {
    const hour = Math.floor(minutes / 60);
    const min = minutes % 60;
    if (min > 0) return ` ${hour}H ${min}Min `;
    else return ` ${hour}H `;
  };
  const selectedData = useSelector((state) => state.booking.selectedData);
  const calenderData = useSelector((state) => state.calender.calenderData);
  const [calculatedTax, setCalculatedTax] = useState("");
  const [totalBookingAmount, settotalBookingAmount] = useState("");
  const [note, setNote] = useState("");
  const dispatch = useDispatch();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    const additional = selectedData.package.additionalServiceAdded;
    const additionalServices = additional.map((obj) => obj.id.toString());
    const body = {
      vehicleId: selectedData.vehicle._id,
      packageId: selectedData.package._id,
      additionalServices: additionalServices,
    };
    bookingCost(body)
      .then((response) => {
        if (response.data.status) {
          const resp = response.data;
          settotalBookingAmount(resp.totalBookingAmountInCents);
          setCalculatedTax(resp.taxInCents);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    axiosInstance().then((axios) => {
      axios.get("/payment/stripe-publishablekey").then((res) => {
        localStorage.setItem("stripe-publishablekey", res.data.key || null);
      });
    });
  }, []);

  const convertDate = (d) => {
    const localDate = new Date(d.startTime);
    const zonalDate = helper.formatZonalDate(localDate);
    const dateFrom = new Date(zonalDate);
    const localDate1 = new Date(d.endTime);
    const zonalDate1 = helper.formatZonalDate(localDate1);
    const dateTo = new Date(zonalDate1);
    let startHours = dateFrom.getHours();
    startHours = ("0" + startHours).slice(-2);
    let endHour = dateTo.getHours();
    endHour = ("0" + endHour).slice(-2);
    return (
      startHours +
      ":" +
      dateFrom.getMinutes() +
      ` ${formatDay(d.startTime)}` +
      " - " +
      endHour +
      ":" +
      dateTo.getMinutes() +
      ` ${formatDay(d.endTime)}`
    );
  };
  const formatDay = (time) => {
    let format = "";
    let ndate = new Date(time);
    let hr = ndate.getHours();

    if (hr < 12) {
      format = "AM";
    } else if (hr >= 12 && hr <= 24) {
      format = "PM";
    }
    return format;
  };

  const commentChangeHandler = (e) => {
    const actionType = {
      index: "comment",
      item: e.target.value,
    };
    dispatch(bookingData(actionType));
    setNote(e.target.value);
  };
  return (
    <div className="bookingReview-container">
      <div className="bookingReview-placer">
        <div className="bookingReview-header">
          <h2>BOOKING REVIEW</h2>
        </div>
        <div className="bookingReview-divider">
          <div>
            <div className="bookingReview-card">
              <div className="bookingReview-card-layout">
                <div className="bookingReview-card-image">
                  <img src={bookingcar} alt="" />
                  VEHICLE
                </div>
                <div className="bookingReview-card-content">
                  <div>{selectedData.vehicle.nickname}</div>
                  <div>{selectedData.vehicle.make}</div>
                </div>
              </div>
              <div className="bookingReview-card-layout">
                <div className="bookingReview-card-image">
                  <img src={location} alt="" />
                  LOCATION
                </div>
                <div className="bookingReview-card-content">
                  <div>{selectedData.location.nickname}</div>
                  <div>{selectedData.location.streetAddress}</div>
                </div>
              </div>
              <div className="bookingReview-card-layout">
                <div className="bookingReview-card-image">
                  <img src={date} alt="" />
                  DATE
                </div>
                <div className="bookingReview-card-content">
                  {`${calenderData.date.getDate()} ${
                    monthNames[calenderData.date.getMonth()]
                  } `}
                </div>
              </div>
              <div className="bookingReview-card-layout">
                <div className="bookingReview-card-image">
                  <img src={timesvg} alt="" />
                  TIME
                </div>
                <div className="bookingReview-card-content">
                  {convertDate(selectedData.timeslot)}
                </div>
              </div>
            </div>
          </div>
          <div className="card-right-divider">
            <div className="bookingReview-card ">
              <div className="bookingReview-vehicle">
                <div className="bookingReview-card-data">
                  <div className="bookingReview-card-header">
                    {selectedData.package.packageName}
                  </div>
                  {selectedData.package.description}
                </div>
                <div className="bookingReview-card-packageimage">
                  <img src={arrow} alt="" />
                </div>
                <div className="bookingReview-card-subsection">
                  <img src={package1} alt="" />
                  <div className="bookingReview-price">
                    ${selectedData.package.packagePrice / 100}
                  </div>
                </div>
              </div>
              <div className="bookingReview-card-time">
                <div className="bookingReview-card-timeimage">
                  <img src={time} alt="" />
                </div>
                <div className="bookingReview-card-timevalue">
                  From {convertMinutes(selectedData.package.totalDuration)} to{" "}
                  {convertMinutes(selectedData.package.totalDuration + 15)}{" "}
                </div>
              </div>
            </div>
            <div className="bookingReview-card">
              <div className="bookingReview-headercard-placer">
                <div className="bookingReview-headercard">Total Cost</div>
              </div>

              <div className="bookingReview-card-section">
                <div className="bookingReview-card-value">
                  <div> Package Price</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {console.log(selectedData, "from review")}
                    <div style={{ paddingRight: "1em" }}>
                      <s>
                        $
                        {Number(
                          selectedData?.package?.packagePrices?.find(
                            (data) =>
                              data?.bodytypeId ==
                              selectedData?.vehicle?.bodytype?._id
                          )?.priceInCents
                        ) / 100}
                      </s>
                    </div>
                    <div>${selectedData.package.packagePrice / 100}</div>
                  </div>
                </div>
                <div className="bookingReview-card-value">
                  <div> Additional Service Cost</div>
                  <div>${selectedData.package.extraAmount}</div>
                </div>
                <div className="bookingReview-card-value">
                  <div>Taxes</div>
                  <div>${calculatedTax / 100}</div>
                </div>
                <div className="bookingReview-card-value">
                  <div>Total Cost</div>
                  <div>${totalBookingAmount / 100}</div>
                </div>
              </div>
            </div>
            <div className="bookingReview-card">
              <div className="bookingReview-headercard-placer">
                <div className="bookingReview-headercard">
                  Write Your Comment
                </div>
              </div>

              <div className="bookingReview-card-textarea">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  value={note}
                  onChange={commentChangeHandler}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingReview;
