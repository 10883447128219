import React from "react";
import "./AboutUsContent.css";
function AboutUsContent() {
  return (
    <div className="about-us-container">
      <div>
        <div className="about-us-heading">
          <div style={{ fontWeight: "none" }}>OUR COMPANY</div>
          <div>
            <strong>Car Wash | On Demand | At Your Doorstep</strong>
          </div>
        </div>
        <div className="about-us-content">
          KIVLIK is a Canadian Startup which provides On-Demand Steam car wash
          and detailing services anywhere in your city. Offering a better
          alternative to traditional car detailing - including exterior and
          interior detail, polishing and waxing, salt removal, pet hair removal,
          odor removal, engine bay cleaning and many more. Avoid the long
          waiting and get car washed at the comfort of your home/work.
        </div>
      </div>
    </div>
  );
}

export default AboutUsContent;
