import React, { useState } from "react";
import "./Contact.css";
import mail from "../../assets/mail.svg";
import chat from "../../assets/chat.svg";
import call from "../../assets/call.svg";
import contactus from "../../assets/contactus.svg";
import Button from "../../Container/Button/Button";
import { notify } from "react-notify-toast";
import { postContactUsForm } from "../../utils/api";
import { CgSpinner } from "react-icons/cg";
import { Helmet } from "react-helmet";

function Contact() {
  const [submitLoading, setSubmitLoading] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const sendForm = async () => {
    if (!form) notify.show("Please check the form", "#ffff", 5000);
    if (
      !form?.email ||
      !validateEmail(form?.email) ||
      !form?.phone ||
      !form?.name ||
      !form?.message
    )
      notify.show("Please validate the fields", "#ffff", 5000);

    setSubmitLoading(true);
    const response = await postContactUsForm(form);
    console.log(response);
    if (response.status === 200) {
      setSubmitLoading(false);
      notify.show("Thank you for contacting us.", "success", 5000);
      setForm((f) => ({ ...f, name: "", email: "", phone: "", message: "" }));
    } else {
      setSubmitLoading(false);
      notify.show("Unfortunately something went wrong!", "error", 5000);
    }
  };

  return (
    <>
      <Helmet>
        <title>Kivlik - Contact Us</title>
      </Helmet>
      <div className="contact-container">
        <div className="contact-placer">
          <div className="contact-header">
            <div>CONTACT US</div>
            <div style={{ fontSize: ".6em" }}>
              Any question or remarks? Just write us a message!
            </div>
          </div>
          <div className="contact-divider">
            <div>
              <div className="contact-card">
                <div className="contact-card-image">
                  <img src={contactus} alt="" />
                </div>
                <div className="conatact-card-header">HELP CENTRE</div>
                <div className="contact-card-para">
                  <div className="contact-card-para-head">
                    Tell us how we can help
                  </div>
                  <div
                    className="contact-card-parasection"
                    style={{ color: "whitesmoke", fontSize: "0.8em" }}
                  >
                    Our crew of superheroes are standing by for Service &
                    Support
                  </div>
                </div>
                <div className="contact-us-block">
                  <div className="contact-us-options">
                    <div className="contact-us-option">
                      <img src={chat} alt="" />
                    </div>
                    <div>
                      <div>Chat</div>
                      <div>Start a conversation now!</div>
                    </div>
                  </div>
                  <div className="contact-us-options">
                    <div className="contact-us-option">
                      <img src={call} alt="" />
                    </div>
                    <div>
                      <div>Call</div>
                      <div>
                        {" "}
                        <a
                          href="tel:1-877-754-8545"
                          style={{ color: "#03577b", textDecoration: "none" }}
                        >
                          {" "}
                          1-877-754-8545
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="contact-us-options">
                    <div className="contact-us-option">
                      <img src={mail} alt="" />
                    </div>
                    <div>
                      <div>E-mail Us At</div>
                      <div>
                        <a
                          href="mailto:helpdesk@kivlik.ca"
                          style={{ color: "#03577b", textDecoration: "none" }}
                        >
                          helpdesk@kivlik.ca
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-right-divider">
              <div className="contact-card2 ">
                <div style={{ padding: "2em" }}>
                  <div className="contact-headercard2">Contact Information</div>
                  <div>
                    Fill up the form and our Team will get back to you within 24
                    hours
                  </div>
                  <div>
                    <input
                      className="contact-input"
                      style={{ marginTop: "10px" }}
                      type="text"
                      name="Name"
                      placeholder="Name"
                      required=""
                      value={form.name}
                      onChange={(e) =>
                        setForm((pre) => ({ ...pre, name: e?.target?.value }))
                      }
                    />
                    <input
                      className="contact-input"
                      style={{ marginTop: "10px" }}
                      type="text"
                      name="Email"
                      placeholder="Email"
                      required=""
                      value={form.email}
                      onChange={(e) =>
                        setForm((pre) => ({ ...pre, email: e?.target?.value }))
                      }
                    />
                    <input
                      className="contact-input"
                      style={{ marginTop: "10px" }}
                      type="number"
                      name="Phone"
                      placeholder="Phone"
                      required=""
                      value={form.phone}
                      onChange={(e) =>
                        setForm((pre) => ({ ...pre, phone: e?.target?.value }))
                      }
                    />
                    <div className="contact-card-textarea">
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        placeholder="Message"
                        value={form.message}
                        onChange={(e) =>
                          setForm((pre) => ({
                            ...pre,
                            message: e?.target?.value,
                          }))
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-submit">
            <Button onClickEvent={sendForm}>
              {submitLoading ? (
                <CgSpinner className="animate-spin" />
              ) : (
                "Send Message"
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
