import React from "react";
import { BsArrowRight } from "react-icons/bs";

const AVAILABLE_SERVICE_FEATURES = [
  "Customised notifications regarding the booking and the wash progress, ",
  "personalised summary of every wash provided to the customer.",
  "Service providers will get complete control over the bookings in their territory",
  "Technology support, training in the auto detailing industry and Helpdesk",
];

function BothSideContents() {
  return (
    <div className="gallery-content-padding ">
      <div className="both-side-content">
        <span className="both-side-content-header-section">
          <h1 className="both-side-content-header text-3xl">
            One platform for thousands of{" "}
            <span className="text-indigo-800">Car Detailing entrepreneurs</span>
          </h1>
          <p className="both-side-content-para">
            KIVLIK’s software platform includes Mobile Apps (Customer and
            Executive), Website and Admin Console.{" "}
          </p>
        </span>
        <span className="both-side-content-para-section">
          <span style={{ paddingTop: ".4rem" }}>
            <b>
              {" "}
              <p>
                This enables customers to book a wash with a few taps on the
                phone.{" "}
              </p>
            </b>

            {AVAILABLE_SERVICE_FEATURES?.map((item) => {
              return (
                <p style={{ marginLeft: ".7rem" }}>
                  <BsArrowRight /> {item}
                </p>
              );
            })}
          </span>
        </span>
      </div>
    </div>
  );
}

export default BothSideContents;
