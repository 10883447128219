import React from "react";
import "./Dropdown.css";
function Dropdown(props) {
  return props.show ? (
    <div className="edit-delete-dropdown">
      <ul className="edit-delete-list">
        <li className="edit-delete-content" onClick={props.edit}>
          Edit
        </li>
        <li className="edit-delete-content" onClick={props.delete}>
          Delete
        </li>
      </ul>
    </div>
  ) : (
    ""
  );
}

export default Dropdown;
