import React, { useEffect, useState } from "react";
import "./GooglemapStyle.css";
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import { useDispatch } from "react-redux";
import { setAddressData } from "../../Store/userLocation/userLocationSlice";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useParams } from "react-router";
import { getSingleAddress } from "../../utils/api";
Geocode.setApiKey("AIzaSyAtT5xA2mu-WVx3URThZOBh5QDFDDcIEqs");
const state = {
  address: "",
  city: "",
  area: "",
  state: "",

  zoom: 15,
  height: 400,
  mapPosition: {
    lat: 0,
    lng: 0,
  },
  markerPosition: {
    lat: 0,
    lng: 0,
  },
};
function GoogleMapSection() {
  const [location, setLocation] = useState(state);
  const [searchAddress, setSearchAddress] = useState("");
  const params = useParams();
  const dispatch = useDispatch();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAtT5xA2mu-WVx3URThZOBh5QDFDDcIEqs",
  });
  useEffect(() => {
    const locationID = params.id;
    if (locationID) {
      getSingleAddress(locationID)
        .then((resp) => {
          const address = resp.data.address;
          const finalLocation = {
            nickname: address.nickname,
            streetAddress: address.streetAddress,
            apartment: address.apartment,
            city: address.city,
            province: address.province,
            pincode: address.postalcode,
            country: address.country,
            latitude: address.cordinates[0],
            longitude: address.cordinates[1],
          };
          dispatch(setAddressData(finalLocation));
          setLocation((prevState) => ({
            ...prevState,
            address: address ? address : "",
            city: address.city ? address.city : "",
            area: address.city ? address.city : "",
            state: state ? state : "",
            markerPosition: {
              lat: Number(address.cordinates[0]),
              lng: Number(address.cordinates[1]),
            },
            mapPosition: {
              lat: Number(address.cordinates[0]),
              lng: Number(address.cordinates[1]),
            },
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          let newLat = position.coords.latitude;
          let newLng = position.coords.longitude;
          Geocode.fromLatLng(newLat, newLng).then((response) => {
            const address = response.results[0].formatted_address;
            const addressArray = response.results[0].address_components;
            const streetNumber = getStreetNumber(addressArray);
            const city = getCity(addressArray);
            const area = getArea(addressArray);
            const state = getState(addressArray);
            const country = getCountry(addressArray);
            const place = getPlace(addressArray);
            const pincode = getPincode(addressArray);
            const adress = streetNumber ? `${streetNumber}  ${place}` : place;
            const finalLocation = {
              streetAddress: adress,
              city: place === city ? (area ? area : city) : city,
              province: `${state && state[0]} (${state && state[1]})`,
              pincode: pincode,
              country: country,
              latitude: newLat,
              longitude: newLng,
            };
            dispatch(setAddressData(finalLocation));
            setLocation((prevState) => ({
              ...prevState,
              address: address ? address : "",
              city: city ? city : "",
              area: area ? area : "",
              state: state ? state : "",
              markerPosition: {
                lat: newLat,
                lng: newLng,
              },
              mapPosition: {
                lat: newLat,
                lng: newLng,
              },
            }));
          });
        });
      }
    }
  }, []);

  const getPlace = (addressArray) => {
    let place = "";
    place = addressArray[1].long_name;
    return place;
  };
  const getCountry = (addressArray) => {
    let country = "";
    for (let index = 0; index < addressArray.length; index++) {
      if (
        addressArray[index].types[0] &&
        "country" === addressArray[index].types[0]
      ) {
        country = addressArray[index].long_name;
        return country;
      }
    }
  };

  const getStreetNumber = (addressArray) => {
    let streetNumber = "";
    for (let index = 0; index < addressArray.length; index++) {
      if (
        addressArray[index].types[0] &&
        addressArray[index].types[0] === "street_number"
      ) {
        streetNumber = addressArray[index].long_name;
        return streetNumber;
      }
    }
  };
  const getPincode = (addressArray) => {
    let pincode = "";
    for (let index = 0; index < addressArray.length; index++) {
      if (
        addressArray[index].types[0] &&
        "postal_code" === addressArray[index].types[0]
      ) {
        pincode = addressArray[index].long_name;
        return pincode;
      }
    }
  };
  const getCity = (addressArray) => {
    let city = "";
    for (let index = 0; index < addressArray.length; index++) {
      if (
        (addressArray[index].types[0] &&
          "administrative_area_level_2" === addressArray[index].types[0]) ||
        (addressArray[index].types[0] &&
          "administrative_area_level_3" === addressArray[index].types[0])
      ) {
        city = addressArray[index].long_name;
        return city;
      }
    }
  };
  const getArea = (addressArray) => {
    let area = "";
    for (let index = 0; index < addressArray.length; index++) {
      if (addressArray[index].types[0]) {
        for (let j = 0; j < addressArray.length; j++) {
          if (
            "sublocality_level_1" === addressArray[index].types[j] ||
            "locality" === addressArray[index].types[j]
          ) {
            area = addressArray[index].long_name;
            return area;
          }
        }
      }
    }
  };

  const getState = (addressArray) => {
    let state = "";
    let province = "";
    for (let index = 0; index < addressArray.length; index++) {
      for (let index = 0; index < addressArray.length; index++) {
        if (
          addressArray[index].types[0] &&
          "administrative_area_level_1" === addressArray[index].types[0]
        ) {
          state = addressArray[index].long_name;
          province = addressArray[index].short_name;
          return [state, province];
        }
      }
    }
  };
  const onClickLocation = (event) => {
    let newLat = event.latLng.lat();
    let newLng = event.latLng.lng();
    Geocode.fromLatLng(newLat, newLng).then((response) => {
      const address = response.results[0].formatted_address;
      const addressArray = response.results[0].address_components;
      const streetNumber = getStreetNumber(addressArray);
      const city = getCity(addressArray);
      const area = getArea(addressArray);
      const state = getState(addressArray);
      const place = getPlace(addressArray);
      const country = getCountry(addressArray);
      const pincode = getPincode(addressArray);
      const adress = streetNumber ? `${streetNumber}  ${place}` : place;
      const finalLocation = {
        streetAddress: adress,
        city: place === city ? (area ? area : city) : city,
        province: `${state && state[0]} (${state && state[1]})`,
        pincode: pincode,
        country: country,
        latitude: newLat,
        longitude: newLng,
      };
      dispatch(setAddressData(finalLocation));
      setLocation((prevState) => ({
        ...prevState,
        address: address ? address : "",
        city: city ? city : "",
        area: area ? area : "",
        state: state ? state : "",
        markerPosition: {
          lat: newLat,
          lng: newLng,
        },
        mapPosition: {
          lat: newLat,
          lng: newLng,
        },
      }));
    });
  };
  const onMarkerGragEnd = (event) => {
    let newLat = event.latLng.lat();
    let newLng = event.latLng.lng();
    Geocode.fromLatLng(newLat, newLng).then((response) => {
      const address = response.results[0].formatted_address;
      const addressArray = response.results[0].address_components;
      const streetNumber = getStreetNumber(addressArray);
      const city = getCity(addressArray);
      const area = getArea(addressArray);
      const state = getState(addressArray);
      const place = getPlace(addressArray);
      const country = getCountry(addressArray);
      const pincode = getPincode(addressArray);
      const adress = streetNumber ? `${streetNumber}  ${place}` : place;
      const finalLocation = {
        streetAddress: adress,
        city: place === city ? (area ? area : city) : city,
        province: `${state && state[0]} (${state && state[1]})`,
        pincode: pincode,
        country: country,
        latitude: newLat,
        longitude: newLng,
      };
      dispatch(setAddressData(finalLocation));
      setLocation((prevState) => ({
        ...prevState,
        address: address ? address : "",
        city: city ? city : "",
        area: area ? area : "",
        state: state ? state : "",
        markerPosition: {
          lat: newLat,
          lng: newLng,
        },
        mapPosition: {
          lat: newLat,
          lng: newLng,
        },
      }));
    });
  };
  const onPlaceSelected = (value) => {
    const address = value.formatted_address;
    const addressArray = value.address_components;
    const streetNumber = getStreetNumber(addressArray);
    const city = getCity(addressArray);
    const area = getArea(addressArray);
    const state = getState(addressArray);
    const place = getPlace(addressArray);
    const country = getCountry(addressArray);
    const pincode = getPincode(addressArray);
    const newLat = value.geometry.location.lat();
    const newLng = value.geometry.location.lng();
    const adress = streetNumber ? `${streetNumber}  ${place}` : place;
    const finalLocation = {
      streetAddress: adress,
      city: place === city ? (area ? area : city) : city,
      province: `${state && state[0]} (${state && state[1]})`,
      pincode: pincode,
      country: country,
      latitude: newLat,
      longitude: newLng,
    };
    dispatch(setAddressData(finalLocation));
    setLocation((prevState) => ({
      ...prevState,
      address: address ? address : "",
      city: city ? city : "",
      area: area ? area : "",
      state: state ? state : "",
      markerPosition: {
        lat: newLat,
        lng: newLng,
      },
      mapPosition: {
        lat: newLat,
        lng: newLng,
      },
    }));
  };
  const handleChange = (address) => {
    setSearchAddress(address);
  };
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    const address = result[0].formatted_address;
    const addressArray = result[0].address_components;
    const streetNumber = getStreetNumber(addressArray);
    const city = getCity(addressArray);
    const area = getArea(addressArray);
    const state = getState(addressArray);
    const place = getPlace(addressArray);
    const country = getCountry(addressArray);
    const pincode = getPincode(addressArray);
    const newLat = latLng.lat;
    const newLng = latLng.lng;
    const adress = streetNumber ? `${streetNumber}  ${place}` : place;
    const finalLocation = {
      streetAddress: adress,
      city: place === city ? (area ? area : city) : city,
      province: `${state && state[0]} (${state && state[1]})`,
      pincode: pincode,
      country: country,
      latitude: newLat,
      longitude: newLng,
    };
    dispatch(setAddressData(finalLocation));
    setLocation((prevState) => ({
      ...prevState,
      address: address ? address : "",
      city: city ? city : "",
      area: area ? area : "",
      state: state ? state : "",
      markerPosition: {
        lat: newLat,
        lng: newLng,
      },
      mapPosition: {
        lat: newLat,
        lng: newLng,
      },
    }));
  };

  return (
    <div>
      <PlacesAutocomplete
        value={searchAddress}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ width: "75%", margin: "auto" }}>
            <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input addYourLocation-input1",
              })}
            />

            <div className="pac-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active "
                  : "suggestion-item pac-item pac-item-query";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <>
                    <div className="search-wrapper">
                      <div className="pac-icon"></div>
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={suggestion.placeId}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {/* <MapWithAMarker
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAtT5xA2mu-WVx3URThZOBh5QDFDDcIEqs&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      /> */}
      {isLoaded && (
        <GoogleMap
          center={{
            lat: location.mapPosition.lat,
            lng: location.mapPosition.lng,
          }}
          zoom={15}
          onClick={onClickLocation}
          mapContainerStyle={{
            margin: "20px auto",
            height: "60vh",
            width: "75%",
          }}
        >
          <Marker
            position={{
              lat: location.mapPosition.lat,
              lng: location.mapPosition.lng,
            }}
            draggable={true} // Allow marker to be dragged
            onDragEnd={onMarkerGragEnd}
          />
        </GoogleMap>
      )}
    </div>
  );
}

export default GoogleMapSection;
