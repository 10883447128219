import * as React from "react";
const dateOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
  year: "numeric",
};
const DateButton = ({ date, active, onClick, selected, disabledDay }) =>
  React.createElement(
    "button",
    {
      className: `sdp--square-btn sdp--date-btn ${
        selected ? "sdp--date-btn__selected" : ""
      } sdp--text ${!active ? "sdp--text__inactive" : ""} ${
        disabledDay ? "sdp--text__inactive color-red" : ""
      }`,
      onClick: () => onClick(date),
      tabIndex: active ? 0 : -1,
      "aria-label": `${
        selected ? "Currently selected" : "Select"
      } ${date.toLocaleDateString("en-US", dateOptions)}`,
      type: "button",
    },
    date.getDate()
  );
export default React.memo(
  DateButton,
  (p, n) =>
    p.date.getDay() === n.date.getDay() &&
    p.active === n.active &&
    p.selected === n.selected &&
    p.disabledDay === n.disabledDay
);
