import React from "react";
import addimage from "../../assets/add.svg";
import "./AddContainer.css";

function AddContainer(props) {
  return (
    <div className="add-image">
      <img src={addimage} alt="" onClick={props.onClickHandler} />
      <div className="addcontainer-text">{props.children}</div>
    </div>
  );
}

export default AddContainer;
