import { Helmet } from "react-helmet";
import "./PrivacyPolicy.css";

const PRIVACY_POLICY_ITEMS = [
  {
    label: "INTRODUCTION",
    content:
      "AGREEMENT AND PARTIES KIVLIK, Technologies Inc. (“Company,” “us,” or “we”) respects the privacy of the visitors to and users of the KIVLIK Mobile Application (“Application) available on the Apple, LLC. platform or Store, the Google Android platform of Google Play and the KIVLIK website with the URL www.KIVLIK.ca, (“website” or “site”). This privacy policy (“privacy policy” or “policy”) is an agreement between KIVLIK, Technologies Inc and you, as the user of the Application or the website (“you”). This agreement tells you when this policy is effective, what information we collect from you, how we might use it, how you can review or change the personal information you provided to us, how we may change this policy, and the steps we take to ensure that your personal information is protected.",
  },
  {
    label: "AGREEMENT POLICY AND TERMS OF USE",
    content:
      "AGREEMENT TO TERMS. All activities in which you maengage through the Application or on this website are voluntary. You are not required to provide any personal information to us unless you choose to access features on the Application or the site which require the information. If you do not agree with the terms of this policy or other terms of use on this website, then you should immediately exit the Application or website and discontinue using the Application and site. If you do not agree with the terms of our privacy policy and our terms of use posted at https://www.KIVLIK.ca/privacy-policy/, please do not provide us with personal information, and leave the Application or website. The terms of use of the Application or the website are expressly incorporated herein by reference and made a part of this policy. By using the Application or website, you signify that you agree to the terms of this privacy policy as well as to our terms of use.",
  },
  {
    label: "EFFECTIVE DATE OF AND CHANGES",
    content:
      "EFFECTIVE DATES. This privacy policy is effective as of November 1, 2022, and will remain in effect except with respect to any of its provisions that are changed in the future, in which case the changes will become effective on the date they are posted through the Application or on the website or we otherwise notify you of the changes. We reserve the right to change this privacy policy at any time. Changes, modifications, additions, or deletions will be effective immediately on their posting to this site. You should check this policy periodically as its terms may change from time to time. Your continued use of the Application and website after we post any such modifications will constitute your acknowledgment of the modified policy and your agreement to abide and be bound by the modified policy. We will also revise the “last updated” date found at the beginning of this privacy policy when we post changes to it.",
  },
  {
    label: "POLICY APPLICABLE TO ONLINE ACTIVITIES ONLY",
    content:
      "DISCLAIMERS. The privacy policy does not extend to anything that is inherent in the operation of the Internet, and therefore beyond the Company’s control, and is not to be applied in any manner contrary to applicable law or governmental regulation. This online privacy policy only applies to information collected through the Application or website. This privacy policy does not apply to any information we may collect from you in any place other than the Application and this website.",
  },
  {
    label: "PERSONAL INFORMATION WE MAY COLLECT",
    content:
      "TYPES OF INFORMATION COLLECTED. We may ask you for certain information referred to on this site as “personal information,” which includes information that pertains to your identity. Such information may include, but is not limited to, items such as your name, address, date of birth, age, gender, e-mail address, telephone number, car make, model and plate number, or credit card or debit card number. You may be required to provide personal information to access or use certain parts of our website or features of the site. If you do not provide the requested personal information, you may not be able to access or use the features of the site where such information is requested.",
  },
  {
    label: "GENERAL INFORMATION WE MAY COLLECT",
    content: `TYPES OF INFORMATION COLLECTED. There may be other instances in which we collect information from you that is more general in nature (“general information”). Such information may include your Internet Protocol (IP) address, which enables us to identify your computer or device on a Transmission Control Protocol/Internet Protocol (TCP/IP) network, which includes the World Wide Web. Your computer has an IP address, which is required in order for you to communicate with others via the Internet. Such general information, including the click stream data and cookies, as mentioned below, may be collected from you whenever you access the site, whether or not you provide us with any other personal information.
		A set of website features may be accessible from web-enabled mobile devices. The intent is to provide screens that are optimized for the size of the screen and operating systems. On some devices, functionality may be limited. If you access this website through a mobile device, then we may collect information about your mobile device whether or not you provide us with any other personal information.
		6.1. Your rights under the Canada Consumer Privacy Act.
		You have the right to know what categories and specific pieces of personal information are collected about you. As such, you may submit a verifiable consumer request via the following methods: send an e-mail to carwash@KIVLIK.ca.
		You have the right to know what personal information is sold, shared, or disclosed with third parties. You may submit a verifiable consumer request via the following methods: send an e-mail to carwash@KIVLIK.ca
		You have the right to opt-out of the sale of your personal information. You may opt-out by visiting this link “Do Not Sell My Pesonal Information” and following the directions.
		Subject to statutory exclusions, you have the right to request that we delete the personal information that we have collected about you. You may submit a request for deletion via the following methods: send an e-mail to carwash@KIVLIK.ca
		If you exercise your rights under the Canada Consumer Privacy Act (CCPA), you have the right to not be discriminated against.
		6.2. Click Stream Data
		When you use our Application or visit our website we may also collect “click stream data” (server address, domain name). This information can be combined with information you have provided to us by registering, for example, which will enable us to analyze and better customize your us of the Application or visits to our website. We may use click stream data for traffic analysis or e-commerce analysis of our Application or website, to determine which features of the Application or our site are most or least effective or useful to you
		6.3. Cookies
		We may also use cookies to gather information about our Application or your visits to our website and enhance your experience. More information on how we use cookies is available in Section 11.
		6.4. How We Respond to “Do Not Track” Disclosures
		We are strongly committed to not sharing personal information with third parties. Under this Privacy Policy, we may share your information only under particular situations, as described in this Policy. Because we protect all users in this manner, we do not change our behavior in response to an application’s, a device’s or a web browser’s “do not track” signal.
		6.5. Use of Social Media Tools
		The Application and website allows you to use your social media accounts to pre-populate certain fields. If you choose to use this feature, you are allowing us to access your personal information from the social media account(s). We encourage you to review the privacy policy and any terms and conditions for the social media platform you select.
		`,
  },
  {
    label: "GENERAL INFORMATION ABOUT YOU",
    content: `USE OF GENERAL INFORMATION. We will primarily use the personal information you provide and any general information we receive from you to provide products and services to you, including, but not limited to, the following:
		1. To administer a content promotion survey on the Application or site and to provide to you requested and relevant products or services (for example, to deliver a prize you may have won in a contest).
		2. To send you information or materials via e-mail about products, offers, and news we think might interest you. This information may relate to products, offers, and news of KIVLIK’s Application, website, and Company events and promotions or its licensees or selected commercial partners. We send you this only if you have double-opted-in to receive e-mails from KIVLIK. If you no longer wish to receive any e-mails from us, or if you want to modify your personal data or want your personal data to be deleted from our database, see Section 12.
		3. To analyze your use of the Application and visits to the website and to learn about the interests of our visitors in aggregate and also on a personal level to better understand your interests and needs, so we can improve our products and services and deliver to you the type of content, features, and promotions that most interest you.
		`,
  },
  {
    label: "PROTECTION OF YOUR PERSONAL AND GENERAL INFORMATION",
    content: `8.1. Security Measures
		In order to protect both the personal information and the general information that we receive from you through our Application and website, we have implemented various security measures, payment information processed and stored by the 3rd party operator, fraud detection, software monitoring, and other security measures.
		8.2. Risk of Interception
		Please keep in mind, however, that whenever you give out personal information online or through any smart-device application there is a risk that third parties may intercept and use that information. While KIVLIK, in conjunction with its smart-device platform and internet service providers and to protect your personal information and privacy, we cannot guarantee the security of any information you disclose online or through the Application. By using the Application and website, you expressly acknowledge and agree that we do not guarantee the security of any data provided to or received by us through the Application or the web site and that any personal information, general information, or other data or information received from you through the Application or site is provided to us at your own risk, which you expressly assume.
		`,
  },
  {
    label: "NO USE OF THIS SITE BY PERSONS UNDER 18 PERMITTED",
    content: `9.1 Registration Declined
		KIVLIK’s Application and website are only intended for the use by persons who are 18 years or older. Personal registration information submitted by a person under the age of 18 will not be accepted. Any general information provided by a person under the age of 18 and gathered (for example, through the use of cookies) during his, her, or ou’s visit may be used as indicated in this policy (see Section 6). KIVLIK is committed to protecting the privacy of children and has no intention of collecting personal data from children under the age of 18. We encourage parents and guardians of children under 18 to regularly check and monitor their children’s use of e-mail and other activities online.
		9.2. Notice Concerning Children
		PLEASE NOTE: We are a general audience Application and site and do not direct any of our content specifically at children under 13 years of age or any user that does not have a car. We understand and are committed to respecting the sensitive nature of children’s privacy online. If we learn or have reason to suspect that a site user is under age 13, we will promptly delete any personal information in that user’s account.
		`,
  },
  {
    label: "THIRD PARTIES",
    content: `10.1. Sharing Information With Persons or Entities Who Manage This Website
		KIVLIK’s Application and website can be accessed and viewed by global and local websites and personal information and general information collected are managed and controlled by KIVLIK’s internet manager and ISP.  For more information, please email carwash@KIVLIK.ca.  The servers that make KIVLIK’s Application and global and local websites available are stored securely. Please email carwash@KIVLIK.ca for more information.
		10.2. Disclosing Information to Third Parties
		WHO MAY HAVE ACCESS TO YOUR INFORMATION. Other than to the companies working with KIVLIK to provide an online marketplace for the posting and acquisition of auto detailing services and except as described in this policy, we do not sell, trade, or otherwise transfer your personal information or general information to third parties without informing you in advance. We provide some of our services through contractual arrangements with affiliates, services providers, partners and other third parties (collectively, “service partners”). Our service partners may use your personal information to operate the functions and services on our Application and website and to deliver that opportunity for services. For example, your data may be transferred to a website hosting and service partner and other parties who assist us in designing and operating the website, executing services (such as for prize fulfillment), or helping us analyze collected data. These parties will only use your data for the purpose of carrying out the functions of the Application and website as agreed with KIVLIK and will be required to keep the information confidential. We will encourage our service partners to adopt and post privacy policies. However, the use of your personal information by our service partners is governed by the privacy policies of those service partners and is not subject to our control. Please also see Paragraph 6.4 on how we will respond to “do not track” signals.
		10.2.1. Categories of Data Collected
		Non-public personally identifiable information (“Information”) is information that identifies you and is not available to the general public. The following sections tell you more about how and when we collect your information.
		During the Application set-up, use of the Application or registration through the website, or appointment and payment handling processes you may give us Information such as your:
		● Name \n
		● Personal address
		● Address of location at which services will be provided
		● Phone number
		● Email address
		● Car brand, model and plate number
		● Any special instructions provided in the order
		● Credit Card information, including provider (Visa, Master Card, Discover, American Express), card number, security code and billing address
		If you gave us your email address or phone number, KIVLIK may use it from time to time to notify you of such things as new services, special offers, or to confirm transactions. You can log in to your account within the KIVLIK application under your profile in the menu tab to update your email preferences.
		Information About Your Transactions
		We may collect information about your transactions and experiences with us and others, such as your payment history, location for services, vehicles and changes to your service selections.
		10.2.2. Categories of Data Shared or Disclosed With Third Parties:
		We may receive information about you from consumer reporting agencies, which provide us with motor vehicle reports, claim reports, and/or credit information where permitted by law. When you set-up an account in the Application or on the web-site to deliver or receive services from KIVLIK’s independent service providers, we may obtain credit information if permitted by applicable state law.
		The service providers who perform auto-detailing services and our Application and website managing personnel and service providers do not have access to the details of your credit information, unless it is necessary to perform a particular task, like respond to a fraud claim. Other companies who view your credit report will not see a KIVLIK inquiry. It will be visible only to you. Our inquiry will not affect your credit score or credit rating.
		10.2.3. Categories of Third Parties With Whom Data Is Shared or Disclosed and Purpose of the Sharing or Disclosure
		Information about our Application users or former customers will only be disclosed as permitted or required by law. Information about you that has been collected is maintained in your account and transaction records.
		We use this information to process and service your requests to post a demand for auto-detailing services, to pay for those services; to settle disputes with your consent; or as otherwise directed by you. We may also disclose it to persons or organizations as necessary to perform transactions you request or authorize. Information about our former customers and about individuals who have posted and received fulfillment of those requests from auto-detailing service providers is safeguarded to the same extent as information about our current users.
		10.2.4. Categories of Data Sold
		KIVLIK, Technologies Inc. does not sell personal user data.
		10.2.5. Categories of Third Parties to Whom Data Is Sold and the Purpose of Such Sale
		KIVLIK, Technologies Inc. does not sell personal user data.
		10.3. Security and Confidentiality
		CONFIDENTIALITY We restrict access to your Information to employees who we have determined need it in order to provide the Application and its auto detailing requests and payment services to you. We train our employees to safeguard customer information, and we require them to sign confidentiality and non-disclosure agreements.
		RELEASE OF INFORMATION. KIVLIK will release personal or general information without your consent for security purposes, when required by law, or to prevent imminent harm to any person or entity. We will disclose personal or general information upon receipt of a court order or subpoena, or to cooperate with a law enforcement investigation, which may include responding to requests and court orders from jurisdictions outside the United States. We fully cooperate with law enforcement agencies in identifying, lawfully, those who use our services for illegal activities. We reserve the right to report to law enforcement agencies any activities that we in good faith believe to be unlawful, as determined in our sole discretion. Release of your personal data for security purposes, as described in this agreement to any person or entity under any circumstances shall be based on a determination made solely by us, exercising our own discretion, permission for which is expressly granted by you to us in accordance with this policy.
		10.4. Links to Third Parties
		PURPOSE OF LINKS. For your convenience and to improve usage of the Application and website we may insert links to third parties on the Application or on our website. This privacy policy does not apply to such third party websites. These links take you outside our Application or service and out of the Application or off of KIVLIK’s site and are beyond our control. This includes links from partners that may use KIVLIK’s service marks, logos or promotional material as part of a co-branding agreement. The sites those links can take you to have their own separate privacy policy and although we seek to protect the integrity of our site, KIVLIK and its non-linked services provider(s) are not liable for the content and activities of those independent sites. Your use of the Application and visits and access to linked websites is at your own risk. Please note that those other sites may send their own cookies to users, collect data, or solicit personal information.
		`,
  },
  {
    label: "AUTOMATIC COLLECTION",
    content: `COOKIES. A cookie is a small string of information that the website you visit transfers to your smart device or computer for identification purposes. Cookies can be used to follow your activity in the Application or on the website and that information helps us to understand your preferences and improve your Application and website experience. Cookies are also used for such activities as remembering your user name and password, if you use such a feature on the Application or website. You can turn off all cookies, in case you prefer not to receive them. You can also have your smart device or computer warn you whenever cookies are being used. For both options you have to adjust your smart-device or browser settings (such as Apple, Android, Chrome, Safari, Firefox, Internet Explorer, or other platform or browser). There are also software products available that can manage cookies for you. Please be aware, however, that when you choose to reject cookies, this choice may limit the functionality of the Application or website you visit and you may lose access to some of its features.`,
  },
  {
    label: "DELETING OR MODIFYING PERSONAL INFORMATION",
    content: `12.1. Right to Opt-Out of the Sale of Personal Information
		You have the right to opt-out of the sale of your personal information. You may opt-out by visiting this link “Do Not Sell My Personal Information” and following the directions.
		12.2. Right to Request Deletion of Personal Information
		Subject to statutory exclusions, you have the right to request that we delete the personal information that we have collected about you. You may submit a request for deletion via the following method: [DESCRIBE PROCEDURES TO SUBMIT REQUEST TO DELETE INFORMATION].
		12.3. Update Your Contact Information
		We encourage you to update promptly your contact and personal information when it changes.`,
  },
  {
    label: "ADDITIONAL LEGAL RIGHTS",
    content: `HOW YOU CAN REVIEW RECORDED INFORMATION ABOUT YOU
			You have the right to review recorded information about you contained in our files. If you have any questions about what information we may have on file, please write us at the address at the end of this notice. We will need your complete name, address and all account numbers under which you post and receive auto-detailing services. Inform us what information you would like to receive. We will also need your home and office telephone numbers so that we can get in touch with you should it be necessary. Unless otherwise provided or allowed by law, within 30 business days of receipt of your request or no later than 45 days, we will inform you of the nature of that recorded information and identify the persons or organizations to whom we have disclosed this information in the preceding two years. We will also give you the name and address of any consumer reporting agency who prepared a report about you so that you can contact them to get a copy of that report. You may either see and copy your information in person or we will mail you a copy of your information.
			We are not required to give you access to information collected in evaluating a claim or dispute between Application or website users, service providers and recipients associated with an account or when the possibility of a lawsuit exists. Any information you request that is in coded form will be translated into plain language and provided in written form. We may charge a reasonable fee to cover our costs incurred in providing a copy of our recorded personal information to you.
			IF YOU DISAGREE WITH OUR RECORDS
			If, after reading the information in your file, you believe it is incorrect, please notify us in writing. Tell us what is inaccurate and why. You have the right to request that we correct, amend or delete information that you believe is incorrect.
			Upon receiving your follow-up explanation or request, we will, within thirty business days, reinvestigate the information you think is incorrect. If we agree with you, we will notify you and make the necessary corrections, amendments or deletions and also notify anyone you specify who may have received such information within the past two years. We will also notify any organization that supplied the information to us. Service providers to whom we systematically reveal information will also be informed of the change.
			If we do not agree to make the correction, amendment or deletion, we will notify you and tell you our reason. You may then file with us a brief statement setting forth what you believe to be the correct, relevant or fair information and why you disagree with our decision not to correct, amend or delete the original information. Your statement will become a permanent part of our file and will be made part of any future disclosure of the original information. In addition, copies of your statement will be sent to any person or service provider to whom the original information was received.`,
  },
];
function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Kivlik - Privacy Policy</title>
      </Helmet>
      <div className="privacy_page_layout">
        <div className="privacy_page_map">
          <div
            style={{
              background: "#f1f2f3",
              padding: "1rem",
              borderRadius: "15px",
            }}
          >
            {PRIVACY_POLICY_ITEMS.map((item, i) => (
              <a href={`#${item.label}`}>
                <div className="privacy_map_header">
                  <p>
                    {" "}
                    ●{" "}
                    {item.label[0].toUpperCase() +
                      item?.label.toLowerCase().substring(1)}{" "}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </div>
        <div className="privacy_page_content">
          <h1> Privacy and Policy </h1>
          <div
            className="privacy_page_content_section_warper"
            id="scroll-container"
          >
            {PRIVACY_POLICY_ITEMS.map((item, i) => (
              <section id={item.label} className="privacy_page_content_section">
                <h2>
                  {" "}
                  {i + 1} {item.label}{" "}
                </h2>
                <p> {item?.content} </p>
              </section>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
