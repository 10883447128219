import React from 'react'

import termsAndConditionImage from "../../assets/termsAndConditionImage.png"

function Header() {
    return (
        <>
            <div className='termsHeader' style={{ justifyContent: "space-evenly" }}>
                <span
                    className='termsHeaderText'
                >
                    <h1>Terms and Conditions </h1>
                </span>

                <span style={{ display: "flex", justifyContent: "center" }}>
                    <img className='floating' style={{ width: "90%", borderRadius: "40px" }} src={termsAndConditionImage} alt="" />
                </span>
            </div>
        </>
    )
}

export default Header