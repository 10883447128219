import React, { useState } from "react";
import Button from "../../Container/Button/Button";
import style from "./SignUpPhone.module.css";
import { useHistory } from "react-router-dom";
import { registerUser } from "../../utils/api";

function SignUpPhone() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const registerData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    };
    registerUser(registerData)
      .then((response) => {
        if (response.data.status) {
          history.push("myaccount");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={style["signup-container"]}>
      <div className={style["signup-placer"]}>
        <div className={style["signup-header"]}>
          <h3>Sign Up</h3>
        </div>
        <div className={style["signup-card"]}>
          <form className={style["signup-form"]} onSubmit={handleSubmit}>
            <div style={{ textAlign: "left", paddingLeft: "1em" }}>
              <div className="sign-card-header" htmlFor="">
                Sign up with Kivlik
              </div>
              <label htmlFor="">
                {" "}
                We'll need some information from you to get started.
              </label>
            </div>
            {error && <p>{error}</p>}
            <div className={style["signup-divider"]}>
              <div className={style["signup-divider-section"]}>
                <input
                  className={style["signup-input"]}
                  type="text"
                  name="fname"
                  placeholder="First name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  className={style["signup-input"]}
                  type="text"
                  name="lname"
                  placeholder="Last name"
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
                <input
                  className={style["signup-input"]}
                  type="text"
                  name="email"
                  placeholder="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
        <div className={style["signup-submit"]}>
          <div>
            <Button onClickEvent={handleSubmit}>Create Account</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPhone;
