import React from "react";

function GallertPageFooter() {
  return (
    <div className="galleryPageFooterPadding">
      <span>
        <h1 className="text-2xl font-normal font-sans">
          We are seeking individuals
          <span className="text-indigo-800">
            {" "}
            who are professional, ambitious,{" "}
          </span>{" "}
          and uphold strong ethical standards, with a{" "}
          <span className="text-indigo-800"> shared goal of driving </span>
          our organization forward and creating a lasting impact.{" "}
        </h1>
      </span>
    </div>
  );
}

export default GallertPageFooter;
