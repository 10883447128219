import React from "react";
import AddYourLocation from "./AddYourLocation";
import GoogleMapSection from "./GoogleMapSection";
import "./AddYourLocation.css";
function LocationMain() {
  return (
    <div className="addYourLocation-container">
      <div className="addYourLocation-placer">
        <div className="addYourLocation-header">
          <h2>ADD YOUR LOCATION</h2>
        </div>
        <div className="googlemap-section">
          <GoogleMapSection />
        </div>
        <AddYourLocation />
      </div>
    </div>
  );
}

export default LocationMain;
