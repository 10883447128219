import React from "react";
import "./DropDownUser.css";
import { ReactComponent as Arrow } from "../../assets/arrow2.svg";
import { Link } from "react-router-dom";
function DropDownUser(props) {
  return (
    <div className="menuUser-dropdown">
      <ul className="menuUser-list">
        <li className="menuUser-content">
          <Arrow className="arrow-button" fill="#17B3F0" />
          <Link
            to="/myaccount"
            style={{
              paddingLeft: "0.5em",
              color: "black",
              textDecoration: "none",
            }}
          >
            My Profile
          </Link>
        </li>
        <li className="menuUser-content">
          <Arrow className="arrow-button" fill="#17B3F0" />
          <Link
            to="/bookingHistory"
            style={{
              paddingLeft: "0.5em",
              color: "black",
              textDecoration: "none",
            }}
          >
            My Bookings
          </Link>
        </li>
        <li className="menuUser-content">
          <Arrow className="arrow-button" fill="#17B3F0" />
          <Link
            to="/contact"
            style={{
              paddingLeft: "0.5em",
              color: "black",
              textDecoration: "none",
            }}
          >
            Notification
          </Link>
        </li>
        <li className="menuUser-content" onClick={props.logout}>
          <Arrow className="arrow-button" fill="#17B3F0" />
          <span style={{ paddingLeft: "0.5em" }}>Logout</span>
        </li>
      </ul>
    </div>
  );
}

export default DropDownUser;
