import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  location: [],
  finalLocation: {},
};

export const userLocationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    getAddressdata: (state, payload) => {
      state.location = payload.payload;
    },
    setAddressData: (state, payload) => {
      state.finalLocation.nickname = payload.payload.nickname
        ? payload.payload.nickname
        : "";
      state.finalLocation.streetAddress = payload.payload.streetAddress;
      state.finalLocation.apartment = payload.payload.apartment
        ? payload.payload.apartment
        : "";
      state.finalLocation.city = payload.payload.city;
      state.finalLocation.province = payload.payload.province;
      state.finalLocation.country = payload.payload.country;
      state.finalLocation.pincode = payload.payload.pincode;
      state.finalLocation.latitude = payload.payload.latitude;
      state.finalLocation.longitude = payload.payload.longitude;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getAddressdata, setAddressData } = userLocationSlice.actions;

export default userLocationSlice.reducer;
