import { GENERIC_ERROR, NUMBER_MAX_DIGITS } from "./constant";

const formatZonalDate = (date) => {
  return date.toLocaleString("en-US", { timeZone: "Canada/Eastern" });
};

function validatePhoneNumber(num) {
  var regx = /^\+[1-9]{1}[0-9]{3,14}$/;
  if (regx.test(num)) {
    return true;
  } else {
    return false;
  }
}

const isValidEmail = (email) => {
  var pattern = new RegExp(
    "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9]+$",
    "i"
  );
  return pattern.test(email || "");
};

const isValidText = (text) => {
  var namePattern = /^[a-zA-Z\s'-]+$/;
  return namePattern.test(text);
};

const isNameValid = (name) => {
  var pattern = /^[a-zA-Z''-'\s]{1,40}$/;
  return pattern.test(name || "");
};

const isAlphaNumeric = (input) => {
  // Define a regular expression to match alphanumeric characters and space
  const alphaNumericPattern = /^[a-zA-Z0-9 ]+$/;

  // Test the input against the pattern
  return alphaNumericPattern.test(input);
};

const formatPhoneNumber = (num) => {
  if (!num) {
    return "";
  } else {
    return num.slice(0, 3) + "-" + num.slice(3, 6) + "-" + num.slice(6);
  }
};

const formatCurrency = (amt) => {
  if (!amt) amt = 0;
  return new Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amt);
};
const isValidField = (value) => {
  return value.length > 0;
};

const formatNumber = (
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
};

const isValidDecimalNumber = (event) => {
  let valid = event.target.validity.valid;
  if (valid) {
    const dotIndex = event.target.value.indexOf(".");
    valid =
      dotIndex >= 0
        ? dotIndex <= NUMBER_MAX_DIGITS
        : event.target.value.length <= NUMBER_MAX_DIGITS;
  }
  return valid;
};

const isValidPremiumValue = (value) => {
  const MAX_VALUE = 999999999999;
  if (value >= 0 && value <= MAX_VALUE) {
    return true;
  } else {
    return false;
  }
};

const isValidIntegerValue = (value) => {
  const MAX_INT_VALUE = 999999;
  if (Math.floor(value) === value && value >= 0 && value <= MAX_INT_VALUE) {
    return true;
  } else {
    return false;
  }
};

const setFocus = (id) => {
  if (id) {
    let element = document.querySelector(id);
    if (element) {
      element.focus();
    }
  }
};

const groupObjectArrayByKey = (objArray, key) => {
  return objArray.reduce((array, x) => {
    (array[x[key]] = array[x[key] || []]).push(x);
    return array;
  }, {});
};

const countDecimal = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().includes(".")
    ? value.toString().split(".")[1].length
    : 0;
};

const roundDecimal = (num, persition) => {
  return Number.parseFloat(num).toFixed(persition);
};

const compareString = (aString, bString, isAscendingOrder) => {
  if (!isAscendingOrder) {
    [aString, bString] = [bString, aString];
  }
  if (!aString) {
    return -1;
  }
  if (!bString) {
    return 1;
  }
  if (aString < bString) {
    return -1;
  } else if (aString > bString) {
    return 1;
  }
  return 0;
};

const handleError = (response) => {
  if (response) {
    const { status } = response;
    return { error: { status } };
  }
  return { error: { ...GENERIC_ERROR } };
};

const lookup = {
  section: {
    profile: "My Profile",
    vehicle: "My Vehicles",
    location: "Location",
    payment: "Payment Profile",
  },
};

const getDisplayValue = (type, value) => {
  return lookup[type][value];
};

const helper = {
  formatZonalDate,
  validatePhoneNumber,
  isValidField,
  isValidEmail,
  isNameValid,
  isValidText,
  isValidDecimalNumber,
  isValidPremiumValue,
  isValidIntegerValue,
  setFocus,
  compareString,
  handleError,
  groupObjectArrayByKey,
  countDecimal,
  roundDecimal,
  formatPhoneNumber,
  formatCurrency,
  formatNumber,
  isAlphaNumeric,
  getDisplayValue,
};

export default helper;
