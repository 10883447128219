import React from "react";
import time from "../../../assets/timetool.svg";
import "./SaveYourTime.css";
function SaveYourTime() {
  return (
    <div className="SaveYourTime-container">
      <div className="SaveYourTime-placer">
        <div className="SaveYourTime-image">
          <img src={time} alt="" />
        </div>
        <div className="SaveYourTime-content-section">
          <div className="SaveYourTime-content-heading">
            SAVE YOUR TIME, WATER AND MONEY
          </div>
          <div className="SaveYourTime-content">
            KIVLIK’s competitive edge will be Mobile App, Steam cleaning power
            and other technological innovation we provide. We have a very
            well-designed Mobile app and website where customers can book a car
            wash with just a few Taps on their phone. KIVLIK’s trained
            executives come to the customer’s location with everything needed to
            perform a full-service car wash which includes power and water. We
            only need the car keys from the customer.
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveYourTime;
