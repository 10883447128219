import React from "react";
import "./Button.css";
function Button(props) {
  return (
    <>
      <button
        type="submit"
        className="common-button"
        onClick={props.onClickEvent}
        style={props.style}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    </>
  );
}

Button.defaultProps = {
  disabled: false,
};

export default Button;
