import React from "react";
import GetAppScreen from "../../UI/GetAppScreen/GetAppScreen";
import AboutUsContent from "./AboutUsContent/AboutUsContent";
import AboutUsMain from "./AboutUsMain/AboutUsMain";
import SaveYourTime from "./SaveYourTime/SaveYourTime";
import SteamBenefit from "./SteamBenefit/SteamBenefit";
import { Helmet } from "react-helmet";
function About() {
  return (
    <>
      <Helmet>
        <title>Kivlik - About Us</title>
      </Helmet>
      <AboutUsMain />
      <AboutUsContent />
      <GetAppScreen />
      <SteamBenefit />
      <SaveYourTime />
    </>
  );
}

export default About;
