import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  packageData: {},
};

export const userPackageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    setpackageData: (state, payload) => {
      state.calenderData = payload.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setpackageData } = userPackageSlice.actions;

export default userPackageSlice.reducer;
