import React from "react";
import mobile from "../../assets/mobile.png";
import "./GetAppScreen.css";
// import googleplay from "../../assets/googleplay.png";
import appstore from "../../assets/appstore.svg";
import googleplay from "../../assets/googleplay.svg";

function GetAppScreen() {
  return (
    <div className="geta-app-container">
      <div className="get-app-image">
        <img style={{ height: "44em" }} src={mobile} alt="" />
      </div>
      <div className="get-app-wrapper">
        <div className="get-app-title">
          <div className="get-app-heading">GET THE KIVLIK APP</div>
          <div className="get-app-para">
            We will send you a link, open it on your email to download the app
          </div>
        </div>
        <div className="get-app-email">
          <div>
            <input className="get-app-input" type="text" placeholder="Email" />
          </div>
          <div className="get-app-padding">
            <button className="get-email-button">Share App Link</button>
          </div>
        </div>
        <div className="get-app-button">
          <div className="get-head-padding">Download app from</div>
          <div className="get-app-buttoncontainer">
            <div className="button-wrapper-pad">
              <img className="button-wrapper" src={googleplay} alt="" />
            </div>
            <div className="button-wrapper-pad">
              <img className="button-wrapper" src={appstore} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetAppScreen;
