import { useState } from "react";

import topbg from "../assets/gradient.png";
import chair from "../assets/chair.png";
import "./careers.min.css";
import { postCareers } from "../utils/api";
import { notify } from "react-notify-toast";
import { CgSpinner } from "react-icons/cg";
import { Helmet } from "react-helmet";

function App() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [resume, setResume] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const submitCareerPage = async () => {
    // fetch post request
    if (!email || !firstName || !lastName || !telephone || !resume) {
      notify.show("Please enter valid inputs", "error", 5000);
      return;
    }
    setSubmitLoading(true);
    const response = await postCareers(
      firstName,
      lastName,
      email,
      telephone,
      resume
    );
    console.log(response);
    if (response.status === 200) {
      setSubmitLoading(false);
      notify.show("Successfully submitted your resume.", "success", 5000);
    } else {
      setSubmitLoading(false);
      notify.show("Unfortunately something went wrong", "error", 5000);
    }
  };

  return (
    <>
      <Helmet>
        <title>Kivlik - Careers</title>
      </Helmet>
      <div className="relative careers-page pt-16 md:pt-0 bg-[#7cc0b52d]">
        <img src={topbg} alt="top colour" className="min-w-full " />
        <div className="min-w-full flex justify-center items-center">
          <div className="absolute  top-10 w-5/6 pt-16 md:pt-32 flex items-center  justify-between">
            <h2 className="text-white text-center text-lg sm:text-2xl">
              Careers
            </h2>
            <img src={chair} alt="chair" className="w-1/3" />
          </div>
        </div>
        <div className="min-w-full p-4 py-12 md:p-16 md:px-28 text-[#20404a]">
          <h2 className="font-bold  text-xl md:text-2xl">Submit Your Resume</h2>
          <h2 className="font-normal text-base md:text-lg">
            Become discoverable for all of our roles
          </h2>
          <div className="mt-16 ">
            <h2 className="text-base md:text-xl font-bold ">
              Personal Details
            </h2>
            <p>Please complete the below form and attach a CV.</p>
          </div>

          <div className="form-control w-full max-w-xs mt-10">
            <label className="label">
              <span className="label-text">Email Address</span>
            </label>
            <input
              type="text"
              placeholder="Type here"
              className="input input-bordered w-full max-w-xs"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-wrap gap-3 sm:gap-3">
            <div className="form-control w-full max-w-xs sm:mt-3">
              <label className="label">
                <span className="label-text">First Name</span>
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="input input-bordered w-full max-w-xs"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="form-control w-full max-w-xs sm:mt-3">
              <label className="label">
                <span className="label-text">Last Name</span>
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="input input-bordered w-full max-w-xs"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="form-control w-full max-w-xs sm:mt-3">
              <label className="label">
                <span className="label-text">Telephone</span>
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="input input-bordered w-full max-w-xs"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
              <label className="label">
                <span className="label-text-alt">
                  Please include your country and area code
                </span>
              </label>
            </div>
          </div>
          <div className="form-control w-full max-w-xs sm:mt-3">
            <label className="label">
              <span className="label-text">Upload CV</span>
            </label>
            <input
              type="file"
              className="file-input w-full max-w-xs"
              accept=".pdf,.docx,.txt"
              onChange={(e) => setResume(e.target.files[0])}
            />
            <label className="label">
              <span className="label-text-alt">
                Select resume file (TXT, PDF or Word DOC)
              </span>
            </label>
          </div>
          <button
            className="btn btn-info my-4 w-36 text-white flex justify-center items-center gap-3"
            onClick={submitCareerPage}
            disabled={submitLoading}
          >
            {submitLoading ? <CgSpinner className="animate-spin" /> : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
}

export default App;
