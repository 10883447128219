import styles from "./style.module.css";
import { columns } from "./tableData";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import Button from "../../Container/Button/Button";
import { packageName } from "../PricingPage/Content/icons";
import monthlysubscription from "../../assets/monthlysubscription.svg";

const Pricing = ({ tableData, priceTimevalue, selectionHandler }) => {
  const minToHrConverter = (durationInMin) => {
    const hours = Math.floor(durationInMin / 60);
    const minutes = durationInMin % 60;

    const durationInHrMin = hours + " hr " + minutes + " min";
    return durationInHrMin;
  };

  const selectPackage = (id) => {
    const packageId = Object.keys(packageName).find(
      (key) => packageName[key] === id
    );
    selectionHandler(packageId);
  };
  return (
    <>
      <div className={styles.pricingContainer} style={{ paddingBottom: "3em" }}>
        <div className={styles.pricingPlacer}>
          <div className={styles.pricingCard}>
            <div className={styles.tableSection}>
              <table>
                <tbody>
                  {tableData.map((tabl, tabIndex) => (
                    <>
                      <tr>
                        <th className={styles.mainTheader}></th>
                        {columns.map((col) => (
                          <>
                            {tabIndex === 0 ? (
                              <th className={styles.th} key={col.id}>
                                {col.id === "diamond" && (
                                  <div className={styles.mostpopular}>
                                    MOST POPULAR
                                  </div>
                                )}
                                {col.id === "gold" && (
                                  <div className={styles.monthly}>
                                    <img src={monthlysubscription} alt="" />
                                  </div>
                                )}
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    paddingBottom: "0.3em",
                                    fontSize: "1.1em",
                                  }}
                                >
                                  {col.label}
                                </div>
                                <div
                                  style={{
                                    color: "#02a4ef",
                                    fontSize: "1.6em",
                                    paddingBottom: "0.3em",
                                  }}
                                >
                                  {`$${priceTimevalue[col.id].price}`}
                                </div>
                                <div
                                  style={{
                                    color: "#5A6063",
                                    paddingBottom: "0.2em",
                                    fontSize: ".8em",
                                  }}
                                >
                                  {minToHrConverter(
                                    priceTimevalue[col.id].time
                                  )}
                                </div>
                                <Button
                                  style={{
                                    backgroundColor: "#02a4ef",
                                    borderRadius: "5px",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                  onClickEvent={(e) => selectPackage(col.id)}
                                >
                                  select
                                </Button>
                              </th>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </tr>
                      <tr className={styles.tableGapSmall}></tr>

                      <tr className={styles.headingRow}>
                        <td className={styles.mainTheader}>
                          {tabl.rows.name.toUpperCase()}
                        </td>
                        {columns.map((cl) => {
                          return <td></td>;
                        })}
                      </tr>

                      <tr className={styles.tableGapSmall}></tr>
                      {tabl.rows.items.map((it, index) => (
                        <tr key={index}>
                          <td className={styles.tdServiceName}>
                            {it.serviceName}
                          </td>
                          {columns.map((cl) => (
                            <td className={styles.tdCenter}>
                              {it.availableIn.find((avl) => avl === cl.id) ? (
                                <AiOutlineCheckCircle color="#14C8B9" />
                              ) : (
                                <AiOutlineCloseCircle color="#FF8484" />
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
