export const initialAccountData = {
  phoneNumber: "",
  roles: [],
  createdAt: "",
  updatedAt: "",
  firstName: "",
  lastName: "",
  email: "",
  address: [
    {
      nickname: "",
      addressLineOne: "",
      addressLineTwo: "",
      addressLineThree: "",
      addressLineFour: "",
      latitude: "",
      longitude: "",
      _id: "",
    },
    {
      nickname: "",
      addressLineOne: "",
      addressLineTwo: "",
      addressLineThree: "",
      addressLineFour: "",
      latitude: "",
      longitude: "",
      _id: "",
    },
  ],
};

export const vehicledata = [
  {
    _id: "",
    nickname: "",
    uid: "",
    bodytype: {
      _id: "",
      bodytypeName: "",
      image: "",
    },
    make: "",
    model: "",
    color: "",
    numberPlate: "",
    createdAt: "",
    updatedAt: "",
  },
];

export const packageData = [
  {
    additionalServices: [],
    createdAt: "",
    description: "",
    durations: [],
    includedServices: [],
    packageName: "",
    packagePrices: [],
    updatedAt: "",
    _id: "",
  },
];
