import React, { useState } from 'react'

export default function WeekSelector({ selectedWeek, setSelectedWeek }) {
  const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  return (
    <div>
      <h3 className='text-[#0686b8]'>Select Week & Day</h3>
      <div className='bg-white p-4 rounded-2xl'>
        <div className='text-[#0686b8] flex gap-3 items-center justify-between'>
          <p>Select Week</p>
          <select className='rounded-lg p-1'>
            <option value='' hidden>
              Select an Option
            </option>
            <option value='first'>First</option>
            <option value='second'>Second</option>
            <option value='third'>Third</option>
            <option value='forth'>Forth</option>
          </select>
        </div>
        <div className='grid grid-cols-3 grid-rows-2 gap-3 mt-3'>
          {weekDays.map((day) => (
            <div
              key={day}
              className={`p-6 shadow-md rounded-xl cursor-pointer font-bold text-center ${
                selectedWeek?.day === day
                  ? 'bg-[#17B3F0] text-white'
                  : 'bg-white text-[#17B3F0]'
              }`}
              onClick={() =>
                setSelectedWeek((prevState) => ({ ...prevState, day: day }))
              }
            >
              {day.toUpperCase()}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
