import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  validationStatus: false,
  modalStatus: false,
  formData: {},
  page: 0,
  selectedBodyTypeId: "",
  selectedData: {},
};

export const userBookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    //update the booking id data to formdata object
    updateData: (state, payload) => {
      if (payload.payload.index === "timeslot") {
        state.formData[payload.payload.index] = payload.payload.item.timeslotId;
      } else {
        state.formData[payload.payload.index] = payload.payload.item._id;
      }
    },
    forwardHandler: (state) => {
      state.page = state.page + 1;
    },
    backwardHandler: (state) => {
      state.page = state.page - 1;
    },
    selectedBodytype: (state, payload) => {
      state.selectedBodyTypeId = payload.payload;
    },
    //update the whole data from each section to selected data
    bookingData: (state, payload) => {
      state.selectedData[payload.payload.index] = payload.payload.item;
    },
    setValidation: (state, payload) => {
      state.validationStatus = payload.payload;
    },
    setModalStatus: (state, payload) => {
      state.modalStatus = payload.payload;
    },
    checkValidation: (state, payload) => {
      if (state.selectedData[payload.payload]) {
        state.validationStatus = false;
      } else {
        state.validationStatus = true;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateData,
  forwardHandler,
  backwardHandler,
  selectedBodytype,
  bookingData,
  setValidation,
  setModalStatus,
  checkValidation,
} = userBookingSlice.actions;

export default userBookingSlice.reducer;
