import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import CarWash from "../../UI/CarWash/CarWash";
import CompanyPage from "../../UI/CompanyPage/CompanyPage";
import DetailScreen from "../../UI/DetailScreen/DetailScreen";
import GetAppScreen from "../../UI/GetAppScreen/GetAppScreen";
import KivilikServiceScreen from "../../UI/KivlikServiceScreen/KivilikServiceScreen";
import Landingscreen1 from "../../UI/LandingScreen/Landingscreen1";
import SectionScreen from "../../UI/SectionScreen/SectionScreen";
import WhyKivlik from "../../UI/WhyKivlik/WhyKivlik";
import { Route, Switch } from "react-router-dom";
import MyAccount from "../../Component/MyAccount/MyAccount-new";
import AddYourVehicle from "../../Component/AddYourVehicle/AddYourVehicle";
import AddPaymentProfile from "../../Component/AddPaymentProfile/AddPaymentProfile";
import FlowCard from "../../Container/Tab/FlowCard";
import GradientBase from "../../Container/Gradient-base/GradientBase";
import LoginOtp from "../../Component/LoginOtp/LoginOtp";
import UserAuthContextProvider from "../../context/UserAuthContext";
import ProtectedRoute from "../ProtectedRoute";
import NotFoundPage from "../../Component/NotFoundPage/NotFoundPage";
import VehicleCollection from "../../Component/VehicleCollection/VehicleCollection";
import LocationCollection from "../../Component/LocationCollection/LocationCollection";
import Contact from "../../Component/ContactUS/Contact";
import About from "../../Component/AboutUS/About";
import CustomerReview from "../../Component/CustomerReview/CustomerReview";
import BookingHistory from "../../Component/BookingHistory";
import LocationMain from "../../Component/AddYourLocation/LocationMain";
import PricingPage from "../../Component/PricingPage/PricingPage";
import SignUpPhone from "../../Component/SignUpPhone/SignUpPhone";
import SignUpScreen from "../../Component/SignUpScreen/SignUpScreen";
import Gallery from "../../Component/Gallery/Gallery";
import PaymentStripe from "../../Component/PaymentStripe";
import TermsAndCondition from "../../Component/TermsAndConditions";
import Blog from "../../Component/Blog";
import PrivacyPolicy from "../../Component/PrivacyPlolicy";
import Confirmation from "../../Component/Confirmation/Confirmation";
import Careers from "../../Careers/Careers";
import MyAccountDesktop from "../../Component/MyAccount/MyAccount-new";
import MyAccountMobile from "../../Component/MyAccount/MyAccount-old";
import ProfileEdit from "../../Component/MyAccount/ProfileEdit";
import AccountDelete from "../../Component/AccountDelete/AccountDelete";

function LandingPage() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (
      Math.min(window.screen.width, window.screen.height) < 768 ||
      navigator.userAgent.indexOf("Mobi") > -1
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  return (
    <div className="container" style={{ position: "relative" }}>
      <UserAuthContextProvider>
        <Header />
        <Switch>
          <Route exact path="/">
            <Landingscreen1 />
            <CarWash />
            <SectionScreen />
            <DetailScreen />
            <KivilikServiceScreen />
            <WhyKivlik />
            <GetAppScreen />
          </Route>
          <Route path="/booking">
            <ProtectedRoute>
              <FlowCard />
            </ProtectedRoute>
          </Route>
          <Route path="/payment">
            <ProtectedRoute>
              <PaymentStripe />
            </ProtectedRoute>
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/pricing">
            <PricingPage />
          </Route>
          <Route exact path="/careers">
            <Careers />
          </Route>
          <Route exact path="/confirmation/:id">
            <ProtectedRoute>
              <Confirmation />
            </ProtectedRoute>
          </Route>
          <Route exact path="/blog">
            <Blog />
          </Route>
          <GradientBase>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/account-deletion-request">
              <AccountDelete />
            </Route>
            <Route exact path="/bookingHistory">
              <ProtectedRoute>
                <BookingHistory />
              </ProtectedRoute>
            </Route>
            <Route exact path="/review">
              <CustomerReview />
            </Route>
            <Route exact path="/login">
              <LoginOtp />
            </Route>
            <Route exact path="/adduser">
              <ProtectedRoute>
                <SignUpPhone />
              </ProtectedRoute>
            </Route>
            <Route exact path="/edit-profile">
              <ProtectedRoute>
                <ProfileEdit />
              </ProtectedRoute>
            </Route>
            <Route exact path="/signup">
              <SignUpScreen />
            </Route>
            {/* <Route exact path="/selectvehicle">
              <Scheduler />
            </Route> */}
            <Route exact path="/myaccount">
              <ProtectedRoute>
                {isMobile ? <MyAccountMobile /> : <MyAccountDesktop />}
                {/* <MyAccountDesktop /> */}
              </ProtectedRoute>
            </Route>
            <Route exact path="/addvehicle">
              <ProtectedRoute>
                <AddYourVehicle />
              </ProtectedRoute>
            </Route>
            <Route exact path="/editvehicle/:id">
              <AddYourVehicle />
            </Route>
            <Route exact path="/editlocation/:id">
              <ProtectedRoute>
                <LocationMain />
              </ProtectedRoute>
            </Route>
            <Route exact path="/addlocation">
              <ProtectedRoute>
                <LocationMain />
              </ProtectedRoute>
            </Route>
            <Route exact path="/addpayment">
              <AddPaymentProfile />
            </Route>
            <Route exact path="/vehicle">
              <ProtectedRoute>
                <VehicleCollection />
              </ProtectedRoute>
            </Route>
            <Route exact path="/location">
              <ProtectedRoute>
                <LocationCollection />
              </ProtectedRoute>
            </Route>
            {/* <Route exact path="/gallery">
              <Gallery />
            </Route> */}
            <Route exact path="/car-wash-and-detailing">
              <Gallery />
            </Route>
            {/* <Route exact path="/blog">
              <Blog />
            </Route> */}
            <Route exact path="/privacyPolicy">
              <PrivacyPolicy />
            </Route>

            <Route exact path="/termsAndCondition">
              <TermsAndCondition />
            </Route>
          </GradientBase>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
        <CompanyPage />
        <Footer />
      </UserAuthContextProvider>
    </div>
  );
}

export default LandingPage;
