import { React, useContext, useEffect, useRef, useState } from "react";
import "./Header.css";
import kiviklogo from "../assets/KivlikLogoBeta.svg";
import { Link, useHistory } from "react-router-dom";
import { userAuthContext } from "../context/UserAuthContext";
import profile from "../assets/profileicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { authLogin, authLogout } from "../Store/userAuth/userAuthSlice";
import DropDownUser from "../Container/DropDownUser/DropDownUser";
import Button from "../Container/Button/Button";

import { BsFillTelephoneFill } from "react-icons/bs";
function Header() {
  const [open, setOpen] = useState(false);
  const { logOut, state } = useContext(userAuthContext);
  const dispatch = useDispatch();
  const status = useSelector((state) => state.auth.authStatus);
  const username = useSelector((state) => state.auth.userName);
  const [dropdown, setDropdown] = useState(false);
  const userdropdownref = useRef();
  const menubarRef = useRef();
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (state.userDataPresent) {
      if (state.user != null) {
        dispatch(authLogin());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userDataPresent, state.user]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // adjust 768 to your desired breakpoint
    };

    handleResize(); // set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        dropdown &&
        userdropdownref.current &&
        !userdropdownref.current.contains(e.target)
      ) {
        // showDropdown(false);
        setDropdown(false);
      }
    };
    const checkIfClickedOutsideMenu = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        open &&
        menubarRef.current &&
        !menubarRef.current.contains(e.target)
      ) {
        // showDropdown(false);
        setTimeout(() => {
          setOpen(false);
        }, 1000);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    document.addEventListener("mousedown", checkIfClickedOutsideMenu);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
      document.removeEventListener("mousedown", checkIfClickedOutsideMenu);
    };
  }, [dropdown, open]);

  const handleLogout = async () => {
    try {
      await logOut();
      dispatch(authLogout());
      setDropdown(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const dropdownHandler = () => {
    setDropdown(!dropdown);
  };

  const booknowHandler = () => {
    history.push("/booking");
  };

  return (
    <div className={`header ${open && "opacity-class"}`}>
      <nav className="navbar">
        <div className="main-title">
          <div className="title-logo">
            <Link to="/">
              <img src={kiviklogo} alt=""></img>
            </Link>
          </div>
        </div>

        <div
          ref={menubarRef}
          className={`menu-items middle ${open && "opacity-middle"}`}
          style={{ alignItems: "", gap: "2.5rem" }}
        >
          <div className={`${open && "menu-item-display"}`}>
            <Link to="/about" onClick={() => setOpen(!open)}>
              About Us
            </Link>
          </div>
          <div className={`${open && "menu-item-display"}`}>
            <Link to="/review" onClick={() => setOpen(!open)}>
              Review
            </Link>
          </div>
          <div className={`${open && "menu-item-display"}`}>
            <Link to="/pricing" onClick={() => setOpen(!open)}>
              Pricing
            </Link>
          </div>
          <div className={`${open && "menu-item-display"}`}>
            <Link to="/contact" onClick={() => setOpen(!open)}>
              Contact
            </Link>
          </div>
          <div className={`${open && "menu-item-display"}`}>
            <a href="tel:+1-877-754-8545">
              <div
                className="copyText-tooltip "
                title="Copy Phone No."
                style={{
                  padding: "0rem .5rem 0 0",
                  // margin: "0 0 0 2rem",
                  borderRadius: "9999px",
                  backgroundColor: "white",
                  fontSize: ".9rem",
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText("1-877-754-8545");
                  var tooltip = document.getElementById("myTooltip");
                  tooltip.innerHTML = "Copied";
                }}
                onMouseOut={() => {
                  var tooltip = document.getElementById("myTooltip");
                  tooltip.innerHTML = "Click to Copy";
                }}
              >
                <span className="tooltiptext" id="myTooltip">
                  Click to Copy
                </span>

                <div
                  style={{
                    backgroundColor: "#3b82f6",
                    color: "white",
                    borderRadius: "99999px",
                    width: "4.5rem",
                    padding: ".1rem .1rem .1rem .2rem",
                    display: "flex",
                    // justifyContent: "center",
                    gap: ".5rem",
                    alignItems: "center",
                  }}
                >
                  <BsFillTelephoneFill
                    style={{
                      padding: ".4rem",
                      backgroundColor: "white",
                      borderRadius: "9999px",
                      color: "#02a4ef",
                    }}
                  />
                  Call
                </div>
                <span style={{ color: "#3b82f6" }}>1-877-754-8545</span>
              </div>
            </a>
          </div>
        </div>

        {state.user && status ? (
          <>
            <div
              className="dropdown-container"
              ref={userdropdownref}
              style={{ display: "flex", alignItems: "center", gap: "1rem" }}
            >
              <div
                className="logout-button menu-items end"
                onClick={() => dropdownHandler()}
                style={{ position: "relative" }}
              >
                <div>
                  <img src={profile} alt="logout" />
                  <span>
                    <a className="nav-arrow downside" role="button"></a>
                  </span>
                </div>
                <div className="username">{username}</div>
              </div>
              {dropdown ? <DropDownUser logout={handleLogout} /> : ""}
              {!isMobile && (
                <div className="signupStyle">
                  <Button
                    style={{
                      padding: "0.7em 0.7em",
                      fontSize: "0.8em",
                      borderRadius: "9999px",
                      width: "7em",
                      color: "#02a4ef",
                    }}
                    onClickEvent={booknowHandler}
                  >
                    Book Now
                  </Button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="menu-items end1">
            {process.env.REACT_APP_LOGIN_ENABLE === "true" && (
              <>
                <div className="loginStyle">
                  <Link to="/login">Login</Link>
                </div>
                <div className="signupStyle">
                  <div className="borderStyle">
                    <Link to="/signup">SignUp</Link>
                  </div>
                </div>
                {/* {!isMobile && (
                  <div className="signupStyle">
                    <Button
                      style={{
                        padding: "1em 0.4em",
                        fontSize: "0.7em",
                        borderRadius: "8px",
                        width: "7em",
                      }}
                      onClickEvent={booknowHandler}
                    >
                      Book Now
                    </Button>
                  </div>
                )} */}
              </>
            )}
          </div>
        )}
        <a
          href="#"
          className="toggle-button"
          onClick={() => setOpen(!open)}
          ref={menubarRef}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </a>
      </nav>
    </div>
  );
}

export default Header;
