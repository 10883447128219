import React, { useEffect, useState } from "react";
import { getAllBodyType, getAllPackages } from "../../../utils/api";
import "./Content.css";
import additional from "../../../assets/carservice1.svg";
import common from "../../../assets/sedan-blue-icon.svg";
import commonSlected from "../../../assets/sedan-blue-bg.svg";
import packageTimeIcon from "../../../assets/packageTimeIcon.svg";
import Pricing from "../../Pricing";
import Loading from "../../../Container/Loading/Loading";
import { svgIcons, packageName } from "./icons";
import Carousel from "react-multi-carousel";

import plasticdressing from "../../../assets/plasticdressing.svg";
import windowrain from "../../../assets/windowrain.svg";
import aivent from "../../../assets/aivent.svg";
import seatbelt from "../../../assets/seatbelt.svg";
import childcar from "../../../assets/childcar.svg";
import pethair from "../../../assets/pethair.svg";
import engineroom from "../../../assets/engineroom.svg";
import headlight from "../../../assets/headlight.svg";
import leather from "../../../assets/leather.svg";
import carseat from "../../../assets/carseat.svg";
import claybar from "../../../assets/claybar.svg";
import carpolish from "../../../assets/carpolish.svg";
import mobilesteam from "../../../assets/mobilesteam.svg";
import waterspot from "../../../assets/waterspot.svg";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1500 },
    items: 8,
  },
  LargeDesktop: {
    breakpoint: { max: 1500, min: 1400 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 1400, min: 1076 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1076, min: 650 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
};

function Content() {
  const [bodyTypeId, setbodyTypeId] = useState("");
  const [packageItems, setPackageItems] = useState([]);
  const [packageId, setPackageId] = useState([]);
  const [pricingTable, setPricingTable] = useState([]);
  const [filteredPackage, setFilteredPackage] = useState({});
  const [bodyTypeDetails, setBodyTypeDetail] = useState([]);
  const [priceTimevalue, setPriceTimevalue] = useState({});
  const [loading, setloading] = useState(false);
  useEffect(() => {
    getAllBodyType()
      .then((response) => {
        const packageArray = response.data.data;
        setBodyTypeDetail(packageArray);
        setbodyTypeId(response.data.data[0]._id);
        let bodyType = response.data.data[0]._id;
        setloading(true);
        getAllPackages()
          .then((response) => {
            setPackageItems(response.data.data);
            if (bodyType) {
              // const availablePackages = response.data.data.filter((element) =>
              //   element.packagePrices.some(
              //     (packagePrices) => packagePrices.bodytypeId === bodyType
              //   )
              // );
              const availablePackages = response.data.data.filter((element) =>
                element.packagePrices.some(
                  (packagePrices) => packagePrices.bodytypeId === bodyType
                )
              );
              const tableData = [
                {
                  rows: {
                    name: "exterior",
                    items: response.data.data.reduce((acc, curr) => {
                      curr.includedServices.forEach((item) => {
                        if (item.type === "exterior") {
                          const existingItem = acc.find(
                            (accItem) => accItem.serviceName === item.title
                          );
                          if (existingItem) {
                            existingItem.availableIn.push(
                              packageName[curr._id]
                            );
                          } else {
                            acc.push({
                              serviceName: item.title,
                              availableIn: [packageName[curr._id]],
                            });
                          }
                        }
                      });
                      return acc;
                    }, []),
                  },
                },
                {
                  rows: {
                    name: "interior",
                    items: response.data.data.reduce((acc, curr) => {
                      curr.includedServices.forEach((item) => {
                        if (item.type === "interior") {
                          const existingItem = acc.find(
                            (accItem) => accItem.serviceName === item.title
                          );
                          if (existingItem) {
                            existingItem.availableIn.push(
                              packageName[curr._id]
                            );
                          } else {
                            acc.push({
                              serviceName: item.title,
                              availableIn: [packageName[curr._id]],
                            });
                          }
                        }
                      });
                      return acc;
                    }, []),
                  },
                },
              ];
              const priceTimeOfPackage = [];

              response.data.data.forEach((pkg) => {
                const name = packageName[pkg._id];

                // initialize the package data if it doesn't exist yet
                if (!priceTimeOfPackage[name]) {
                  priceTimeOfPackage[name] = { price: 0, time: 0 };
                }

                // loop through each duration and add up the times
                pkg.durations.forEach((duration) => {
                  const matchingTime =
                    pkg.durations &&
                    pkg.durations.find((time) => time.bodytypeId === bodyType);

                  if (matchingTime) {
                    priceTimeOfPackage[name].time = matchingTime.durationInMin;
                  }
                });

                // loop through each package price and add up the prices
                pkg.packagePrices.forEach((price) => {
                  const matchingPrice =
                    pkg.packagePrices &&
                    pkg.packagePrices.find(
                      (price) => price.bodytypeId === bodyType
                    );
                  if (matchingPrice) {
                    priceTimeOfPackage[name].price =
                      matchingPrice.salePriceInCents;
                  }
                });

                // convert the price to dollars
                priceTimeOfPackage[name].price /= 100;
              });
              // console.log(availablePackages, "check");
              const platinumObject = availablePackages.find(
                (obj) => obj._id === "637ba3167f0458b56e2bfc0a"
              );
              const allAdditionalServices = availablePackages.map(
                (pack) => pack?.additionalServices
              );
              const result = flattenAndRemoveDuplicates(allAdditionalServices);
              console.log(result, "flatten servies");
              const availablePackage = platinumObject
                ? platinumObject
                : availablePackages[0];
              const filteredServices =
                availablePackage &&
                availablePackage.additionalServices.filter((service) => {
                  return service.bodySpecific.some((body) => {
                    return body.bodytypeId === bodyType;
                  });
                });
              setFilteredPackage(filteredServices);
              setPriceTimevalue(priceTimeOfPackage);
              setPricingTable(tableData);
              // setSelectedPackage(response.data.data[0]);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const setBodytypeId = (id) => {
    setbodyTypeId(id);
    setFilteredPackage({});
    const priceTimeOfPackage = {};
    // const availablePackages = packageItems.filter((element) =>
    //   element.packagePrices.some(
    //     (packagePrices) => packagePrices.bodytypeId === id
    //   )
    // );
    packageItems.forEach((pkg) => {
      const name = packageName[pkg._id];

      // initialize the package data if it doesn't exist yet
      if (!priceTimeOfPackage[name]) {
        priceTimeOfPackage[name] = { price: 0, time: 0 };
      }

      // loop through each duration and add up the times
      pkg.durations.forEach((duration) => {
        const matchingTime =
          pkg.durations && pkg.durations.find((time) => time.bodytypeId === id);

        if (matchingTime) {
          priceTimeOfPackage[name].time = matchingTime.durationInMin;
        }
      });

      // loop through each package price and add up the prices
      pkg.packagePrices.forEach((price) => {
        const matchingPrice =
          pkg.packagePrices &&
          pkg.packagePrices.find((price) => price.bodytypeId === id);
        if (matchingPrice) {
          priceTimeOfPackage[name].price = matchingPrice.salePriceInCents;
        }
      });

      // convert the price to dollars
      priceTimeOfPackage[name].price /= 100;
    });
    const availablePackages = packageItems.filter((element) =>
      element.packagePrices.some(
        (packagePrices) => packagePrices.bodytypeId === id
      )
    );
    const platinumObject = availablePackages.find(
      (obj) => obj._id === "637ba3167f0458b56e2bfc0a"
    );
    const availablePackage = platinumObject
      ? platinumObject
      : availablePackages[0];
    const filteredServices =
      availablePackage &&
      availablePackage.additionalServices.filter((service) => {
        return service.bodySpecific.some((body) => {
          return body.bodytypeId === id;
        });
      });
    setFilteredPackage(filteredServices);
    setPriceTimevalue(priceTimeOfPackage);
    // setPackageItem(availablePackages);
    // setSelectedPackage(availablePackages[0]);
  };

  const selectedPackageHandler = (id) => {
    //setting id of the package from pricing page
    // getAllService()
    //   .then((response) => {
    //     if (response.data.status) {
    //       const allService = response.data.data;
    //       console.log(allService);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    const selected =
      packageItems && packageItems.filter((element) => element._id === id);
    const filteredServices = selected[0].additionalServices.filter(
      (service) => {
        return service.bodySpecific.some((body) => {
          return body.bodytypeId === bodyTypeId;
        });
      }
    );
    setFilteredPackage(filteredServices);
    setPackageId(id);
  };

  console.log(filteredPackage, "additinal services");
  return (
    <>
      <div>
        <div className="plan-heading">Select your Car Type</div>
        {/* <div
          className="pricing-packages-bodytype"
          style={{ paddingBottom: "3em" }}
        >
          <div className="pricing-packages-image">
            {bodyTypeDetails &&
              bodyTypeDetails.map((item) => {
                const matchingObject = svgIcons.find(
                  (obj) => obj.id === item._id
                );
                const selectionStatus = item._id === bodyTypeId;
                const selectionUrl = selectionStatus
                  ? matchingObject
                    ? matchingObject.selected
                    : commonSlected
                  : matchingObject
                  ? matchingObject.src
                  : common;

                return (
                  <div key={item._id} onClick={() => setBodytypeId(item._id)}>
                    <div id={item._id} className="pricing-packages-carbody">
                      <img src={selectionUrl} alt="" style={{ width: "95%" }} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div> */}
        <div className="pricing-packages-bodytype">
          <Carousel
            responsive={responsive}
            className="react-multi-carousel-list-pricing"
          >
            {bodyTypeDetails &&
              bodyTypeDetails.map((item, index) => {
                const matchingObject = svgIcons.find(
                  (obj) => obj.id === item._id
                );
                const selectionStatus = item._id === bodyTypeId;
                const selectionUrl = selectionStatus
                  ? matchingObject
                    ? matchingObject.selected
                    : commonSlected
                  : matchingObject
                  ? matchingObject.src
                  : common;
                return (
                  <div
                    key={item._id}
                    onClick={() => setBodytypeId(item._id)}
                    className=""
                  >
                    <div id={item._id} className="pricing-packages-carbody">
                      <img src={selectionUrl} alt="" style={{ width: "95%" }} />
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
      </div>
      {!loading ? (
        <Loading />
      ) : (
        <Pricing
          tableData={pricingTable}
          priceTimevalue={priceTimevalue}
          selectionHandler={selectedPackageHandler}
        />
      )}
      {Object.keys(filteredPackage).length !== 0 ? (
        <div>
          <div className="plan-heading">Additional Services</div>
          <div className="addditional-cards">
            {filteredPackage &&
              filteredPackage.map((item, index) => {
                const price =
                  item.bodySpecific &&
                  item.bodySpecific.find(
                    (price) => price.bodytypeId === bodyTypeId
                  ).salePriceInCents / 100;
                const time =
                  item.bodySpecific &&
                  item.bodySpecific.find(
                    (price) => price.bodytypeId === bodyTypeId
                  ).durationInMins;

                const logoImage =
                  additionalServicesImages.find(
                    (service) => service.id === item?._id
                  )?.image || additional;
                return (
                  <div key={item._id} className="additional-card">
                    <div className="additional-card-upper">
                      <div className="additional-card-image">
                        <img src={logoImage} alt="" />
                      </div>
                      <div className="additional-card-header">{item.title}</div>
                    </div>
                    <div className="additional-card-price">
                      <div className="additional-card-value">${price}</div>
                      <div className="additional-card-value">
                        <div>
                          <img
                            src={packageTimeIcon}
                            alt=""
                            style={{ width: "1.5em" }}
                          />
                        </div>
                        <div>{time} min</div>
                      </div>
                    </div>
                    <div className="additional-card-content">
                      {item.description}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Content;

const additionalServicesImages = [
  {
    service: "Mobile Steam Car wash & Detailing",
    image: mobilesteam,
    id: "64077d79a88d8ec60ffa3b6e",
  },
  {
    service: "Car Polishing & Waxing",
    image: carpolish,
    id: "64076996a88d8ec60ffa2b6a",
  },
  {
    service: "Clay Bar Treatment",
    image: claybar,
    id: "640762b8a88d8ec60ffa2781",
  },
  {
    service: "Car seat shampooing",
    image: carseat,
    id: "64077e70a88d8ec60ffa438d",
  },
  {
    service: "Leather conditioning",
    image: leather,
    id: "64077d4ea88d8ec60ffa3a3d",
  },
  {
    service: "Headlight Restoration",
    image: headlight,
    id: "64219c7785cf356f0c633214",
  },
  {
    service: "Engine Room cleaning",
    image: engineroom,
    id: "64219cfb85cf356f0c6333bd",
  },
  {
    service: "Water Spot Removal",
    image: waterspot,
    id: "64076281a88d8ec60ffa2710",
  },
  {
    service: "Pet Hair Removal",
    image: pethair,
    id: "64219c3085cf356f0c633077",
  },
  {
    service: "Odor Eliminator (Ozone treatment)",
    image: seatbelt,
    id: "64206796feb5dfa02c0ff93d",
  },
  {
    service: "Plastic Dressing",
    image: plasticdressing,
    id: "64076281a88d8ec60ffa2710",
  },
  {
    service: "Air Vent Steam Treatment",
    image: aivent,
    id: "64076281a88d8ec60ffa2710",
  },
  {
    service: "Child Car Seat Treatmen",
    image: childcar,
    id: "64077d4ea88d8ec60ffa3a3d",
  },
  {
    service: "Window Rain Repellent",
    image: windowrain,
    id: "6407630da88d8ec60ffa2887",
  },
  {
    service: "Seat Belt steam cleaning",
    image: seatbelt,
    id: "64077da3a88d8ec60ffa3ca8",
  },
];

function flattenAndRemoveDuplicates(arrayOfArrays) {
  // Flatten the array of arrays
  const flattened = arrayOfArrays.reduce((acc, curr) => acc.concat(curr), []);

  // Use a Map to store unique objects based on their _id
  const uniqueObjectsMap = new Map();
  flattened.forEach((obj) => {
    uniqueObjectsMap.set(obj._id, obj);
  });

  // Convert Map values back to an array
  const uniqueObjectsArray = Array.from(uniqueObjectsMap.values());

  return uniqueObjectsArray;
}
