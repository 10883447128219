import React from "react";
import Header from "./Header";
import "./TermsAndCondition.css";
import { TERMS_AND_CONDITION_DATA } from "./TermsAndConditionData";
import TermsComponent from "./TermsComponent";
import { Helmet } from "react-helmet";

function TermsAndCondition() {
  return (
    <>
      <Helmet>
        <title>Kivlik - Terms & Condition</title>
      </Helmet>
      <div className="termsPadding" style={{ paddingBottom: "5rem" }}>
        <Header />
        <p className="termsPara">
          <b>
            {" "}
            PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING OUR
            SERVICES BECAUSE THEY GOVERN YOUR ACCESS TO AND USE OF THE KIVLIK
            APPLICATIONS, PLATFORMS AND OTHER AUTHORIZED SERVICES. IT IS
            IMPORTANT THAT YOU READ AND UNDERSTAND THE FOLLOWING TERMS. BY
            ACCESSING THE KIVLIK.CA WEBSITE AND/OR AFFILIATED MOBILE APPS, YOU
            ARE AGREEING THAT THESE TERMS WILL APPLY IF YOU CHOOSE TO ACCESS
            AND/OR USE THE SERVICES.
          </b>
          <br />
          KIVLIK provides applications for the on-demand delivery of on-site,
          Eco-Friendly auto-detailing and automobile washing related Services.
          The Services permit you to utilize certain Internet and mobile-device
          applications, including storing personal content regarding your
          vehicle(s), email, phone number, and payment information and accessing
          it on your devices and computers, only under the terms and conditions
          set forth in this Agreement. As soon as you enable KIVLIK, your
          content will be automatically sent to and stored by KIVLIK, so you can
          receive premium, on-demand car wash and auto detailing services that
          are insured, convenient and efficient.
        </p>
        {TERMS_AND_CONDITION_DATA.map((item) => (
          <TermsComponent data={item} />
        ))}
      </div>
    </>
  );
}

export default TermsAndCondition;
