import React from "react";
import { Link } from "react-router-dom";
import PageNotFound from "../../assets/404-error-page.jpg";
import { Helmet } from "react-helmet";
function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>Kivlik - Not Found</title>
      </Helmet>
      <div>
        <img src={PageNotFound} alt="" />
        <p style={{ textAlign: "center" }}>
          <Link to="/">Go to Home </Link>
        </p>
      </div>
    </>
  );
}

export default NotFoundPage;
