import React from "react";
import "./Gallery.css";
import { GALLERY_CONTENT_DATA } from "./GalleryContent/GalleryData";
import BothSideContents from "./GalleryContent/BothSideContents";
import DoubleContent from "./GalleryContent/DoubleContent";
import GalleryContent from "./GalleryContent/Index";
import GallertPageFooter from "./GalleryContent/GallertPageFooter";
import { Helmet } from "react-helmet";

function Gallery() {
  return (
    <>
      <Helmet>
        <title>Kivlik - Gallery</title>
      </Helmet>
      <article>
        <span className="gallery-header-section">
          <h1 className="gallery-header font-normal font-sans">
            We’re on a mission to bring premium car detailing to your doorstep
            which enables you to spend quality time with your family.
          </h1>
          <p className="gallery-header-para font-extralight">
            KIVLIK is a Canadian startup which provides On-Demand, STEAM car
            wash and detailing services anywhere in your city. Offering a better
            alternative to traditional car detailing - including exterior and
            interior detail, polishing and waxing, salt removal, pet hair
            removal, odour removal, engine bay and many more.
          </p>
        </span>
      </article>

      <article style={{ backgroundColor: "#ffff" }}>
        <GalleryContent key={"1"} data={GALLERY_CONTENT_DATA[0]} />
        <GalleryContent key={"4"} data={GALLERY_CONTENT_DATA[3]} />
        <BothSideContents />
        <DoubleContent data={GALLERY_CONTENT_DATA[4]} />
        <DoubleContent data={GALLERY_CONTENT_DATA[5]} />
        <DoubleContent data={GALLERY_CONTENT_DATA[6]} />
        <DoubleContent data={GALLERY_CONTENT_DATA[7]} />
        <GallertPageFooter />
      </article>
    </>
  );
}

export default Gallery;
