import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import Button from "../../Container/Button/Button";
import Loading from "../../Container/Loading/Loading";
import { ReactComponent as Arrow } from "../../assets/arrow2.svg";
import common from "../../assets/sedan-blue-icon.svg";
import commonSlected from "../../assets/sedan-blue-bg.svg";
import {
  addVehicle,
  getAllBodyType,
  getVehicle,
  updateVehicle,
} from "../../utils/api";
import styles from "./AddYourVehicle.module.css";
import Carousel from "react-multi-carousel";
import { svgIcons } from "../../../src/Component/PricingPage/Content/icons";
import helper from "../../utils/helper";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1500 },
    items: 6,
  },
  LargeDesktop: {
    breakpoint: { max: 1500, min: 1400 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1400, min: 1076 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1076, min: 650 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
};

function AddYourVehicle(props) {
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [bodyTypeId, setbodyTypeId] = useState("");
  const [vehicleNameVaild, setVehicleNameVaild] = useState(false);
  const [vehicleMakeVaild, setVehicleMakeVaild] = useState(false);
  const [vehicleModelVaild, setVehicleModelVaild] = useState(false);
  const [vehicleColorVaild, setVehicleColorVaild] = useState(false);
  const [vehicleNumberVaild, setVehicleNumberVaild] = useState(false);
  const [bodyTypeDetails, setBodyTypeDetail] = useState([]);
  const [editId, setEditId] = useState("");
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const vehicleNullCheck = location && location.state && location.state.data;
  // const routingText = location && location.state && location.state.text;
  const backCheck =
    location && location.state && location.state.backflag ? false : true;
  const [backStatus, SetBackStatus] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    SetBackStatus(backCheck);
  }, [backCheck]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // adjust 768 to your desired breakpoint
    };

    handleResize(); // set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (params.id) {
      setEditId(params.id);
      getVehicle(params.id)
        .then((response) => {
          if (response.data.status) {
            const singleVehicle = response.data.data;
            setVehicleName(singleVehicle.nickname);
            setVehicleMake(singleVehicle.make);
            setVehicleModel(singleVehicle.model);
            setVehicleColor(singleVehicle.color);
            setVehicleNumber(singleVehicle.numberPlate);
            setBodytypeId(singleVehicle.bodytype);
            setVehicleNameVaild(helper.isAlphaNumeric(singleVehicle.nickname));
            setVehicleMakeVaild(helper.isAlphaNumeric(singleVehicle.make));
            setVehicleModelVaild(helper.isAlphaNumeric(singleVehicle.model));
            setVehicleColorVaild(helper.isAlphaNumeric(singleVehicle.color));
            setVehicleNumberVaild(
              helper.isAlphaNumeric(singleVehicle.numberPlate)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    getAllBodyType()
      .then((response) => {
        setBodyTypeDetail(response.data.data);
        setloading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [bodyTypeId]);

  const setBodytypeId = (id) => {
    setbodyTypeId(id);
  };

  const backwardHandler = () => {
    if (vehicleNullCheck === 0) {
      // history.push("/myaccount");
      history.push({
        pathname: `/myaccount`,
        state: { text: "vehicle" },
      });
    } else if (vehicleNullCheck === 1) {
      history.push("/booking");
    } else {
      history.goBack();
    }
  };

  const onSumbitVehicleHandler = async (e) => {
    e.preventDefault();
    if (editId || params.id) {
      const body = {
        nickname: vehicleName,
        bodytypeId: bodyTypeId,
        vehicleId: editId,
        make: vehicleMake,
        model: vehicleModel,
        color: vehicleColor,
        numberPlate: vehicleNumber,
      };
      updateVehicle(body)
        .then((response) => {
          if (response.data.status) {
            if (vehicleNullCheck === 2) {
              history.push({
                pathname: "/myaccount",
                state: { text: "vehicle" },
              });
            } else if (vehicleNullCheck === 1) {
              history.push("/booking");
            } else {
              // history.push("/myaccount");
              history.push({
                pathname: "/myaccount",
                state: { text: "vehicle" },
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const body = {
        nickname: vehicleName,
        bodytypeId: bodyTypeId,
        make: vehicleMake,
        model: vehicleModel,
        color: vehicleColor,
        numberPlate: vehicleNumber,
      };
      addVehicle(body)
        .then((response) => {
          if (response.data.status) {
            if (vehicleNullCheck === 2) {
              history.push({
                pathname: "/myaccount",
                state: { text: "vehicle" },
              });
            } else if (vehicleNullCheck === 1) {
              history.push("/booking");
            } else {
              // history.push("/myaccount");
              history.push({
                pathname: "/myaccount",
                state: { text: "vehicle" },
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const saveValidation = () => {
    const isValid =
      vehicleNameVaild &&
      vehicleMakeVaild &&
      vehicleModelVaild &&
      vehicleColorVaild &&
      vehicleNumberVaild;
    return isValid;
  };

  return (
    <>
      {!loading ? (
        <Loading />
      ) : (
        <div className={styles["addYourVehicle-container"]}>
          <div className={styles["addYourVehicle-placer"]}>
            <div className={styles["addYourVehicle-header"]}>
              {editId ? <h2>EDIT YOUR VEHICLE</h2> : <h2>ADD YOUR VEHICLE</h2>}
            </div>
            <div className={styles["addYourVehicle-card"]}>
              <form className={styles["addYourVehicle-form"]}>
                <div className={styles["addYourVehicle-cardform"]}>
                  <div className={styles["input-style"]}>
                    <input
                      className={styles["addYourVehicle-input"]}
                      type="text"
                      name="nickname"
                      placeholder="Vehicle Nick Name"
                      required=""
                      value={vehicleName}
                      onChange={(e) => {
                        setVehicleName(e.target.value);
                        const isValid = helper.isAlphaNumeric(e.target.value);
                        setVehicleNameVaild(isValid);
                      }}
                    />
                    {!vehicleNameVaild && (
                      <span className={styles["error-message"]}>
                        This field cannot be empty
                      </span>
                    )}
                  </div>
                  <div className={styles["input-style"]}>
                    <input
                      className={styles["addYourVehicle-input"]}
                      type="text"
                      name="make"
                      placeholder="Make"
                      required=""
                      value={vehicleMake}
                      onChange={(e) => {
                        setVehicleMake(e.target.value);
                        const isValid = helper.isAlphaNumeric(e.target.value);
                        setVehicleMakeVaild(isValid);
                      }}
                    />
                    {!vehicleMakeVaild && (
                      <span className={styles["error-message"]}>
                        This field cannot be empty
                      </span>
                    )}
                  </div>
                  <div className={styles["input-style"]}>
                    <input
                      className={styles["addYourVehicle-input"]}
                      type="text"
                      name="model"
                      placeholder="Model"
                      required=""
                      value={vehicleModel}
                      onChange={(e) => {
                        setVehicleModel(e.target.value);
                        const isValid = helper.isAlphaNumeric(e.target.value);
                        setVehicleModelVaild(isValid);
                      }}
                    />
                    {!vehicleModelVaild && (
                      <span className={styles["error-message"]}>
                        This field cannot be empty
                      </span>
                    )}
                  </div>
                  <div className={styles["input-style"]}>
                    <input
                      className={styles["addYourVehicle-input"]}
                      type="text"
                      name="color"
                      placeholder="Color"
                      required=""
                      value={vehicleColor}
                      onChange={(e) => {
                        setVehicleColor(e.target.value);
                        const isValid = helper.isAlphaNumeric(e.target.value);
                        setVehicleColorVaild(isValid);
                      }}
                    />
                    {!vehicleColorVaild && (
                      <span className={styles["error-message"]}>
                        This field cannot be empty
                      </span>
                    )}
                  </div>
                  <div className={styles["input-style"]}>
                    <input
                      className={styles["addYourVehicle-input"]}
                      type="text"
                      name="numberPlate"
                      placeholder="Plate Number"
                      required=""
                      value={vehicleNumber}
                      onChange={(e) => {
                        setVehicleNumber(e.target.value);
                        const isValid = helper.isAlphaNumeric(e.target.value);
                        setVehicleNumberVaild(isValid);
                      }}
                    />
                    {!vehicleNumberVaild && (
                      <span className={styles["error-message"]}>
                        This field cannot be empty
                      </span>
                    )}
                  </div>
                </div>
              </form>

              <div className={styles["addYourVehicle-bodytype"]}>
                <div className={styles["addYourVehicle-bodytype-header"]}>
                  BODY TYPE
                </div>
                <div className={styles["addYourVehicle-image"]}>
                  <Carousel
                    responsive={responsive}
                    className={styles["react-multi-carousel-list"]}
                  >
                    {bodyTypeDetails &&
                      bodyTypeDetails.map((item, index) => {
                        const matchingObject = svgIcons.find(
                          (obj) => obj.id === item._id
                        );

                        const selectionStatus = item._id === bodyTypeId;
                        const selectionUrl = selectionStatus
                          ? matchingObject
                            ? matchingObject.selected
                            : commonSlected
                          : matchingObject
                          ? matchingObject.src
                          : common;
                        return (
                          <div
                            key={item._id}
                            onClick={() => setBodytypeId(item._id)}
                            className=""
                          >
                            <div
                              id={item._id}
                              className={styles["addYourVehicle-carbody"]}
                            >
                              <img
                                src={selectionUrl}
                                alt=""
                                style={{ width: "95%" }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </Carousel>
                </div>
              </div>
            </div>
            <div
              className={styles["addYourVehicle-submit"]}
              style={!backStatus ? { justifyContent: "flex-end" } : {}}
            >
              {backStatus ? (
                <div>
                  <Button
                    onClickEvent={backwardHandler}
                    style={{
                      gridGap: "1em",
                      display: "flex",
                      borderRadius: "5px",
                      backgroundColor: "#17B3F0",
                    }}
                  >
                    {!isMobile && (
                      <Arrow
                        className="arrow-button"
                        style={{
                          transform: "rotate(180deg)",
                        }}
                        fill="#03577B"
                        stroke="#03577B"
                      />
                    )}
                    <div>Back</div>
                  </Button>
                </div>
              ) : (
                ""
              )}
              <div>
                <Button
                  onClickEvent={onSumbitVehicleHandler}
                  style={{
                    gridGap: "1em",
                    display: "flex",
                    borderRadius: "5px",
                  }}
                  disabled={!saveValidation()}
                >
                  <div>SAVE</div>
                  {!isMobile && (
                    <Arrow
                      className={styles["arrow-button"]}
                      // style={{
                      //   transform: "rotate(180deg)",
                      // }}
                      fill="#03577B"
                      stroke="#03577B"
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AddYourVehicle;
