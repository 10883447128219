import React, { useEffect, useState } from "react";
import carlogo from "../../assets/carlogo3.svg";
import locationcar from "../../assets/loactioncar.svg";
import "./MyAccount.css";
import { useHistory } from "react-router-dom";
import { getAccountDetails, getAllVehicle } from "../../utils/api";
import { useDispatch } from "react-redux";
import { getAddressdata } from "../../Store/userLocation/userLocationSlice";
import Button from "../../Container/Button/Button";
import { setMenuName } from "../../Store/userAuth/userAuthSlice";
import Loading from "../../Container/Loading/Loading";
function MyAccountMobile() {
  const [accountDetail, setAccountDetail] = useState({});
  const [loading, setloading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const onRoutefrommyaccount = (value) => {
    let path = value;
    history.push(path);
  };

  useEffect(() => {
    getAccountDetails()
      .then((response) => {
        setAccountDetail(response.data.data);
        dispatch(setMenuName(response.data.data.firstName));
        dispatch(getAddressdata(response.data.data.address));
        setloading(true);
      })
      .catch((err) => {
        if (err.response.data.errCode === "USER_NOT_REGISTERED") {
          history.push("/adduser");
        } else {
          console.log(err);
          setError(err);
        }
      });
    getAllVehicle()
      .then((response) => {
        setVehicle(response.data.data.length !== 0 ? response.data.data : []);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }, [dispatch]);
  const redirectToBooking = () => {
    history.push("booking");
  };
  return (
    <>
      {!error && (
        <>
          {!loading ? (
            <Loading />
          ) : (
            <div className="myAccount-container">
              <div className="myAccount-placer">
                <div className="myAccount-header">
                  <h2>My Account</h2>
                </div>
                <div className="myAccount-divider-card">
                  <div
                    onClick={onRoutefrommyaccount.bind(null, "myaccount")}
                    className="myAccount-card myAccount-card2"
                  >
                    <div className="myAccount-detail">
                      <h1 style={{ fontWeight: 100, opacity: 0.5 }}>{`${
                        accountDetail &&
                        Object.keys(accountDetail).length === 0 &&
                        Object.getPrototypeOf(accountDetail) ===
                          Object.prototype
                          ? ""
                          : accountDetail.firstName
                      }  ${
                        accountDetail &&
                        Object.keys(accountDetail).length === 0 &&
                        Object.getPrototypeOf(accountDetail) ===
                          Object.prototype
                          ? ""
                          : accountDetail.lastName
                      }`}</h1>
                      <h2 style={{ fontWeight: 100, opacity: 0.5 }}>
                        {accountDetail &&
                        Object.keys(accountDetail).length === 0 &&
                        Object.getPrototypeOf(accountDetail) ===
                          Object.prototype
                          ? ""
                          : accountDetail.email}
                      </h2>
                      <h2 style={{ fontWeight: 100, opacity: 0.5 }}>
                        {accountDetail &&
                        Object.keys(accountDetail).length === 0 &&
                        Object.getPrototypeOf(accountDetail) ===
                          Object.prototype
                          ? ""
                          : accountDetail?.phoneNumber}
                      </h2>
                    </div>
                  </div>
                  <div
                    onClick={onRoutefrommyaccount.bind(null, "vehicle")}
                    className="myAccount-card myAccount-card1"
                  >
                    <div className="myAccount-detail">
                      <div className="myAccount-detail-header">MY VEHICLES</div>
                      <div className="myAccount-vehicle">
                        <div className="myAccount-carlogo">
                          <img src={carlogo} alt="" />
                        </div>
                        <div className="myAccount-card-section">
                          <div className="myAccount-card-details">
                            <span
                              className="span-shadow"
                              style={{
                                fontWeight: "bold",
                                opacity: 0.7,
                                paddingRight: "1em",
                              }}
                            >
                              Name :
                            </span>
                            <span style={{ fontWeight: "900", opacity: 0.5 }}>
                              {vehicle.length !== 0 ? vehicle[0].nickname : ""}
                            </span>
                          </div>
                          <div className="myAccount-card-details">
                            <span
                              className="span-shadow"
                              style={{
                                fontWeight: "bold",
                                opacity: 0.7,
                                paddingRight: "1em",
                              }}
                            >
                              Type :
                            </span>
                            <span style={{ fontWeight: "900", opacity: 0.5 }}>
                              {vehicle.length !== 0
                                ? vehicle[0].bodytype.bodytypeName
                                : ""}
                            </span>
                          </div>
                          <div className="myAccount-card-details">
                            <span
                              className="span-shadow"
                              style={{
                                fontWeight: "bold",
                                opacity: 0.7,
                                paddingRight: "1em",
                              }}
                            >
                              Number Plate :
                            </span>
                            <span style={{ fontWeight: "900", opacity: 0.5 }}>
                              {vehicle.length !== 0
                                ? vehicle[0].numberPlate
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="myAccount-divider-card">
                  <div
                    onClick={onRoutefrommyaccount.bind(null, "location")}
                    className="myAccount-card myAccount-card2"
                  >
                    <div className="myAccount-detail">
                      <div className="myAccount-detail-header">MY LOCATION</div>
                      <div className="myAccount-vehicle">
                        <div className="myAccount-carlogo">
                          <img src={locationcar} alt="" />
                        </div>
                        <div className="myAccount-card-section">
                          <div className="myAccount-card-details">
                            <span
                              className="span-shadow"
                              style={{
                                fontWeight: "bold",
                                opacity: 0.7,
                                paddingRight: "1em",
                              }}
                            >
                              Address :{" "}
                            </span>

                            <span style={{ fontWeight: "900", opacity: 0.5 }}>
                              {accountDetail &&
                              Object.keys(accountDetail).length === 0 &&
                              Object.getPrototypeOf(accountDetail) ===
                                Object.prototype
                                ? ""
                                : accountDetail.address.length !== 0
                                ? accountDetail?.address[0].streetAddress
                                : ""}
                            </span>
                          </div>
                          <div className="myAccount-card-details">
                            <span
                              className="span-shadow"
                              style={{
                                fontWeight: "bold",
                                opacity: 0.7,
                                paddingRight: "1em",
                              }}
                            >
                              City :
                            </span>

                            <span style={{ fontWeight: "900", opacity: 0.5 }}>
                              {accountDetail &&
                              Object.keys(accountDetail).length === 0 &&
                              Object.getPrototypeOf(accountDetail) ===
                                Object.prototype
                                ? ""
                                : accountDetail.address.length !== 0
                                ? accountDetail?.address[0].city
                                : ""}
                            </span>
                          </div>

                          <div className="myAccount-card-details">
                            <span
                              className="span-shadow"
                              style={{
                                fontWeight: "bold",
                                opacity: 0.7,
                                paddingRight: "1em",
                              }}
                            >
                              Nick Name :
                            </span>
                            <span style={{ fontWeight: "900", opacity: 0.5 }}>
                              {accountDetail &&
                              Object.keys(accountDetail).length === 0 &&
                              Object.getPrototypeOf(accountDetail) ===
                                Object.prototype
                                ? ""
                                : accountDetail.address.length !== 0
                                ? accountDetail?.address[0].nickname
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    onClick={onRoutefrommyaccount.bind(null, "addpayment")}
                    className="myAccount-card myAccount-card1"
                  >
                    <div className="myAccount-detail">
                      <div className="myAccount-detail-header">
                        PAYMENT PROFILE
                      </div>
                      <div className="myAccount-vehicle">
                        <div className="myAccount-carlogo">
                          <img src={paymentcard} alt="" />
                        </div>
                        <div className="myAccount-card-section">
                          <div className="myAccount-card-details">
                            <span
                              className="span-shadow"
                              style={{
                                fontWeight: "bold",
                                opacity: 0.7,
                                paddingRight: "1em",
                              }}
                            >
                              Type :
                            </span>
                          </div>
                          <div className="myAccount-card-details">
                            <span
                              className="span-shadow"
                              style={{
                                fontWeight: "bold",
                                opacity: 0.7,
                                paddingRight: "1em",
                              }}
                            >
                              Number :
                            </span>
                          </div>
                          <div className="myAccount-card-details">
                            <span
                              className="span-shadow"
                              style={{
                                fontWeight: "bold",
                                opacity: 0.7,
                                paddingRight: "1em",
                              }}
                            >
                              Exp. Date :
                            </span>
                          </div>
                          <div className="myAccount-card-details">
                            <span
                              className="span-shadow"
                              style={{
                                fontWeight: "bold",
                                opacity: 0.7,
                                paddingRight: "1em",
                              }}
                            >
                              {" "}
                              Card holder Name:
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="myAccount-submit">
                  <Button
                    onClickEvent={redirectToBooking}
                    style={{
                      width: "15em",
                      maxWidth: "15em",
                      borderRadius: "5px",
                    }}
                  >
                    Book a Wash
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default MyAccountMobile;
