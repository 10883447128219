import carWaxImage2 from "../../assets/BlogImage2.jpeg";
import carWaxImg4 from "../../assets/carWaxImg4.jpeg";
import carWaxImg7 from "../../assets/carWaxImg7.jpeg";

export const BLOG_CONTENT_DATA = [
  {
    header: "Don’t skip the wash day",
    firstPara:
      "The longer you let the remains of salt sit on your car, the more damage it causes. To avoid this, wash your car regularly with a high-pressure hose to clean hard-to-reach areas, and make sure to put that lawn sprinkler to good use to cover the undercarriage. ",
    image: null,
    lastPara:
      "If you’re opting for paid car wash, check whether they’re using repurposed water as it might contain traces of salt.\n It’s advised to avoid driving before and after a snowstorm since you’ll be unlucky enough to run into fresh road salt at those times. But if you must, make sure you wash your car later.",
  },
  {
    header: "Wax the car exterior",
    firstPara:
      "Waxing acts as an additional layer of defence that will preserve the clear coat over your vehicle’s paint. This clear coat protects the paint from corrosion but is not enough to fight against road salt on its own.",
    image: null,
    lastPara:
      "Wax usually lasts for only about 2-3 months so don’t shy away from redoing a good strong coat regularly at least during the winter time. Also, don’t forget to seal the undercarriage as well with the help of a professional.        ",
  },
  {
    header: "Double check the wheel",
    firstPara:
      "Check and keep the tires clean throughout the winter if you don’t want them to kick up snow and road salt onto the car’s body. Road salt and the sand on the road can cause intense wear and tear on the tires too.",
    image: carWaxImage2,
    lastPara:
      "Washing them often will help to retain their grip and performance and risks like low tread depth, splits, and bumps are easier to spot when your tires are clean. Investing in winter tires that are made of rubber with high silica content is a good option if you wanna go the extra mile.",
  },
  {
    header: "Steer clear of puddles and deep snow",
    firstPara:
      "Road salt tends to collect in large amounts in puddles of melted snow and the salty water will splash onto the undercarriage when you drive through them, so it’s best to avoid them. Getting an undercoating spray will give your car an additional layer of protection from water and salt.",
    image: null,
    lastPara:
      "Just like puddles, deep snow is a trap. For starters it is literally a trap, you can get stuck in it. It’ll also stuff salt into the undercarriage, creating buildup in places where it’ll be hard to remove thus leading to corrosion and affecting engine drivability.",
  },
  {
    header: "Get your car checked",
    firstPara:
      "Getting your car checked by a professional before the winter arrives will let you take necessary preventive measures not only against damage caused by road salt but also any other malfunctions. Identifying wear and tear, paint chip, and rust buildup and treating them beforehand will limit any further corrosion.",
    image: null,
    lastPara:
      "It is advised to use an outdoor car cover designed for winter to combat the harm caused by moisture and prevent rust before it occurs. \n Road salt is a genius way to combat snow on the road but you don’t have to let it eat away your car. A poorly maintained car is always prone to damage caused by pollutants. Proper maintenance and limiting exposure to road salt are all you need to do to keep the clear coat pristine and protect the paint underneath during the winter.",
  },
];
