import React from "react";
import "./App.css";
import LandingPage from "./Route/LandingPage/LandingPage";
import Notifications from "react-notify-toast";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./hooks/ScrollToTop";

function App() {
  return (
    <div className="app">
      <Router>
        <Notifications />
        <ScrollToTop />
        <LandingPage />
      </Router>
    </div>
  );
}

export default App;
