import React, { createContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../Firebase/firebase";
export const userAuthContext = createContext();

export default function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState("");
  let [state, setState] = useState({
    userDataPresent: false,
    user: null,
    listener: null,
  });
  const signUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const logIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
  const logOut = () => {
    return signOut(auth);
  };
  function setUpRecaptcha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (state.listener == null) {
      setState({
        ...state,
        listener: auth.onAuthStateChanged((user) => {
          if (user)
            setState((oldState) => ({
              ...oldState,
              userDataPresent: true,
              user: user,
            }));
          else
            setState((oldState) => ({
              ...oldState,
              userDataPresent: true,
              user: null,
            }));
        }),
      });
    }
    return () => {
      if (state.listener) state.listener();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, state, signUp, logIn, logOut, setUpRecaptcha }}
    >
      {children}
    </userAuthContext.Provider>
  );
}
