import React from "react";
import plasticdressing from "../../assets/plasticdressing.svg";
import windowrain from "../../assets/windowrain.svg";
import aivent from "../../assets/aivent.svg";
import seatbelt from "../../assets/seatbelt.svg";
import childcar from "../../assets/childcar.svg";
import pethair from "../../assets/pethair.svg";
import engineroom from "../../assets/engineroom.svg";
import headlight from "../../assets/headlight.svg";
import leather from "../../assets/leather.svg";
import carseat from "../../assets/carseat.svg";
import claybar from "../../assets/claybar.svg";
import carpolish from "../../assets/carpolish.svg";
import mobilesteam from "../../assets/mobilesteam.svg";
import waterspot from "../../assets/waterspot.svg";
import "./KivilikServiceScreen.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1200 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1200, min: 1076 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1076, min: 650 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
};
function KivilikServiceScreen() {
  const data = [
    {
      service: "Mobile Steam Car wash & Detailing",
      image: mobilesteam,
    },
    {
      service: "Car Polishing & Waxing",
      image: carpolish,
    },
    {
      service: "Clay Bar Treatment",
      image: claybar,
    },
    {
      service: "Car seat shampooing",
      image: carseat,
    },
    {
      service: "Leather conditioning",
      image: leather,
    },
    {
      service: "Headlight Restoration",
      image: headlight,
    },
    {
      service: "Engine Room cleaning",
      image: engineroom,
    },
    {
      service: "Water Spot Removal",
      image: waterspot,
    },
    {
      service: "Pet Hair Removal",
      image: pethair,
    },
    {
      service: "Odor Eliminator (Ozone treatment)",
      image: seatbelt,
    },
    {
      service: "Plastic Dressing",
      image: plasticdressing,
    },
    {
      service: "Air Vent Steam Treatment",
      image: aivent,
    },
    {
      service: "Child Car Seat Treatmen",
      image: childcar,
    },
    {
      service: "Window Rain Repellent",
      image: windowrain,
    },
    {
      service: "Seat Belt steam cleaning",
      image: seatbelt,
    },
  ];

  return (
    <div className="section2-container">
      <div className="section2-header">KIVLIK SERVICES</div>
      <div className="section2-contain">
        <Carousel responsive={responsive}>
          {data.map((item, index) => {
            return (
              <div key={index} className="section1-iconholder">
                <div className="image1-layout">
                  <img
                    src={item.image}
                    alt=""
                    style={{ height: "8em", width: "19em" }}
                  />
                </div>
                <div className="subsection2-layout">{item.service}</div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}

export default KivilikServiceScreen;
