import React, { useEffect, useRef, useState } from "react";
import package1 from "../../assets/packageicon.svg";
import arrow from "../../assets/arrow.svg";
import time from "../../assets/time.svg";
import "./Package.css";
import PackageModal from "../PackageModal/PackageModal";
import { getAllPackages } from "../../utils/api";
import { packageData } from "../MyAccount/data";
import { useDispatch, useSelector } from "react-redux";
import bluetick from "../../assets/bluetick.svg";
import selecttick from "../../assets/selecttick.svg";
import { checkValidation } from "../../Store/userBooking/userBookingSlice";
import Loading from "../../Container/Loading/Loading";

function Package() {
  const [packageDetails, setPackageDetails] = useState(packageData);
  const [packageItem, setPackageItem] = useState({});
  const [loading, setloading] = useState(false);
  const bodytypeId = useSelector((state) => state.booking.selectedBodyTypeId);
  const [packageId, setPackageId] = useState("");
  const modalref = useRef();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const convertMinutes = (minutes) => {
    const hour = Math.floor(minutes / 60);
    const min = minutes % 60;
    if (min > 0) return ` ${hour}H ${min}Min `;
    else return ` ${hour}H `;
  };

  useEffect(() => {
    getAllPackages()
      .then((response) => {
        let availablePackages = response.data.data.filter((element) =>
          element.packagePrices.some(
            (packagePrices) => packagePrices.bodytypeId === bodytypeId
          )
        );
        setloading(true);
        setPackageDetails(availablePackages);
        dispatch(checkValidation("package"));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const modalexiterHandler = () => {
    setShowModal(!showModal);
  };
  const selectHandler = (item) => {
    setShowModal(!showModal);
    setPackageItem(item);
  };
  // useEffect(() => {
  //   fetch("http://localhost:5000/api/v1/payment/postSubscribePackage", {
  //     method: "POST",
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //     })
  //     .then(({ session }) => {
  //       window.location = session.url;
  //     })
  //     .catch((error) => console.log(error, "error"));
  // }, []);
  return (
    <>
      {!loading ? (
        <Loading />
      ) : (
        <div className="package-container">
          <div className="package-placer">
            <div className="package-header">
              <h2>SELECT PACKAGES</h2>
            </div>
            {showModal && (
              <div className="package-holder" ref={modalref}>
                <PackageModal
                  item={packageItem}
                  bodytypeId={bodytypeId}
                  modalexiter={modalexiterHandler}
                  setPackageHandler={setPackageId}
                />
              </div>
            )}

            <div className="package-divider-card">
              {packageDetails &&
                packageDetails.map((item, index) => {
                  const priceArray = item.packagePrices.find((price) => {
                    return price.bodytypeId === bodytypeId;
                  });
                  return (
                    <div
                      key={index}
                      className="package-card "
                      onClick={() => selectHandler(item)}
                    >
                      <div className="package-card-content">
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            gap: "0.5rem",
                          }}
                        >
                          <div className="package-tick">
                            {packageId === item._id ? (
                              <img src={bluetick} alt="" />
                            ) : (
                              <img src={selecttick} alt="" />
                            )}
                          </div>
                          <div className="package-card-header">
                            {item.packageName}
                          </div>
                          <div className="package-vehicle-main">
                            <div className="package-card-data">
                              {`${item.description
                                ?.split(" ")
                                .slice(0, 12)
                                .join(" ")}...`}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="package-card-image">
                            <img src={arrow} alt="" />
                          </div>
                        </div>
                        <div style={{ margin: "auto" }}>
                          <div className="package-card-section-main">
                            <img src={package1} alt="" />
                            <div className="package-price-main">
                              ${priceArray && priceArray.salePriceInCents / 100}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="package-card-time">
                        <div>
                          <img src={time} alt="" width={25} />
                        </div>
                        <div className="package-card-value">
                          From{" "}
                          {bodytypeId &&
                            convertMinutes(
                              +item.durations.find((e) => {
                                return e.bodytypeId === bodytypeId;
                              })?.durationInMin
                            )}
                          to{" "}
                          {bodytypeId &&
                            convertMinutes(
                              +item.durations.find((e) => {
                                return e.bodytypeId === bodytypeId;
                              })?.durationInMin + 15
                            )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Package;
