import React, { useEffect, useState } from "react";
import locationcar from "../../assets/loactioncar.svg";
import AddContainer from "../../Container/AddContainer/AddContainer";
import selecttick from "../../assets/selecttick.svg";
import bluetick from "../../assets/bluetick.svg";
import dot from "../../assets/dot.svg";
import "./LocationCollection.css";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddressDetails, getAccountDetails } from "../../utils/api";
import { getAddressdata } from "../../Store/userLocation/userLocationSlice";
import Dropdown from "../../Container/Dropdown/Dropdown";
import Modal from "../../Container/Modal/Modal";
import ModalAction from "../../Container/Modal/ModalAction/ModalAction";
import Button from "../../Container/Button/Button";
import { ReactComponent as Arrow } from "../../assets/arrow2.svg";
import Loading from "../../Container/Loading/Loading";
function LocationCollection() {
  const [selectIndex, setSelectIndex] = useState(0);
  const [size, setSize] = useState(0);
  const [deleteId, setDeleteId] = useState("");
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState("");
  const location = useSelector((state) => state.location.location);
  const history = useHistory();
  const dispatch = useDispatch();
  const addLocationHandler = () => {
    history.push({
      pathname: "/addlocation",
      state: { data: 2 },
    });
  };
  useEffect(() => {
    getAccountDetails()
      .then((response) => {
        if (response.data.status) {
          dispatch(getAddressdata(response.data.data.address));
          setSize(response.data.data.address.length);
          setloading(true);
          if (response.data.data.address.length === 0) {
            history.push({
              pathname: "/addlocation",
              state: { data: 0 },
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const locationCollectionHandler = (index) => {
    setSelectIndex(index);
  };

  const backwardHandler = () => {
    history.push("myaccount");
  };

  const dropdownHandler = (id) => {
    dispatch(
      getAddressdata(
        location.map((el) =>
          el._id === id ? Object.assign({}, el, { showDropdown: true }) : el
        )
      )
    );
  };

  const onblurhandler = (id) => {
    dispatch(
      getAddressdata(
        location.map((el) =>
          el._id === id ? Object.assign({}, el, { showDropdown: false }) : el
        )
      )
    );
  };
  const actionModalHandler = (e) => {
    deleteAddressDetails(deleteId)
      .then((response) => {
        if (response.data.status === true) {
          setDeleteStatus(true);
          setModalStatus(false);
          e.preventDefault();
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  };
  const closeModalHandler = () => {
    setModalStatus(false);
  };

  const deleteSuccessHandler = (e) => {
    setDeleteStatus(false);
    window.location.reload(false);
    e.preventDefault();
  };

  const editHandler = (id) => {
    history.push({
      pathname: `/editlocation/${id}`,
      state: { data: 2 },
    });
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    setModalStatus(true);
  };

  return (
    <>
      {!error && (
        <>
          {modalStatus ? (
            <Modal>
              <div>
                <div className="actionHeader">Do you want to Delete?</div>
              </div>
              <ModalAction
                action={actionModalHandler}
                close={closeModalHandler}
              />
            </Modal>
          ) : (
            ""
          )}
          {deleteStatus ? (
            <Modal>
              <div>
                <div className="actionHeader">Successfully deleted</div>
              </div>
              <ModalAction close={deleteSuccessHandler} action={false} />
            </Modal>
          ) : (
            ""
          )}
          {!loading ? (
            <Loading />
          ) : (
            <div className="locationCollection-container">
              <div className="locationCollection-placer">
                <div className="locationCollection-header">
                  <h2>SELECT YOUR LOCATION</h2>
                </div>
                <div className="locationCollection-divider-card">
                  {location &&
                    location.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => locationCollectionHandler(index)}
                          className="locationCollection-card "
                        >
                          <div className="locationCollection-tick">
                            {selectIndex === index ? (
                              <img src={bluetick} alt="" />
                            ) : (
                              <img src={selecttick} alt="" />
                            )}
                          </div>
                          <div
                            className="dropdown-container"
                            tabIndex={0}
                            onBlur={() => onblurhandler(item._id)}
                          >
                            <div
                              className="locationCollection-dot"
                              onClick={() => dropdownHandler(item._id)}
                            >
                              <img src={dot} alt="" />
                            </div>
                            <Dropdown
                              show={item.showDropdown}
                              edit={() => editHandler(item._id)}
                              delete={() => deleteHandler(item._id)}
                            />
                          </div>

                          <div className="locationCollection-vehicle">
                            <div className="locationCollection-carlogo">
                              <img src={locationcar} alt="" />
                            </div>

                            <div className="locationCollection-card-section">
                              <div className="locationCollection-card-details">
                                <span
                                  className="span-shadow"
                                  style={{
                                    fontWeight: "bold",
                                    opacity: 0.7,
                                    paddingRight: "1em",
                                  }}
                                >
                                  Address :
                                </span>
                                <span
                                  style={{ fontWeight: "900", opacity: 0.5 }}
                                >
                                  {item && item.streetAddress}
                                </span>
                              </div>
                              <div className="locationCollection-card-details">
                                <span
                                  className="span-shadow"
                                  style={{
                                    fontWeight: "bold",
                                    opacity: 0.7,
                                    paddingRight: "1em",
                                  }}
                                >
                                  City :
                                </span>
                                <span
                                  style={{ fontWeight: "900", opacity: 0.5 }}
                                >
                                  {item && item.city}
                                </span>
                              </div>
                              <div className="locationCollection-card-details">
                                <span
                                  className="span-shadow"
                                  style={{
                                    fontWeight: "bold",
                                    opacity: 0.7,
                                    paddingRight: "1em",
                                  }}
                                >
                                  Nick Name :
                                </span>
                                <span
                                  style={{ fontWeight: "900", opacity: 0.5 }}
                                >
                                  {item && item.nickname}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {size <= 4 ? (
                  <AddContainer onClickHandler={addLocationHandler}>
                    Add New Location
                  </AddContainer>
                ) : (
                  ""
                )}
                <div className="back-submit">
                  <div>
                    <Button
                      onClickEvent={backwardHandler}
                      style={{
                        gridGap: "1em",
                        display: "flex",
                        borderRadius: "5px",
                        backgroundColor: "#17B3F0",
                      }}
                    >
                      <Arrow
                        className="arrow-button"
                        style={{
                          transform: "rotate(180deg)",
                        }}
                        fill="#03577B"
                        stroke="#03577B"
                      />
                      <div>Back</div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default LocationCollection;
