import axios from "axios";
import { auth } from "../Firebase/firebase";

export const getIdTokenValue = async () => {
  let idToken = "";
  idToken = await auth.currentUser?.getIdToken(true);
  return idToken;
};

const axiosInstance = async () => {
  const token = await getIdTokenValue();
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
const testAxiosInstance = async () => {
  const token = await getIdTokenValue();
  return axios.create({
    baseURL: "http://localhost:5000/api/v1",
    headers: { Authorization: `Bearer ${token}` },
  });
};
export { axiosInstance, testAxiosInstance };
