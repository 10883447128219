import React from "react";

function GalleryContent({ data }) {
  return (
    <div
      className="gallery-content-padding"
      style={{ backgroundPosition: data?.backGroundPosition }}
    >
      <div
        className={data?.left ? "content-warper row-reverse" : "content-warper"}
      >
        <div className="content-text-side">
          <span>
            {data?.singleImageHeading}
            <h1 className="gallery-content-header ">{data?.heading}</h1>
            <p className="gallery-content-para">
              {" "}
              {data?.content?.split("\n").map((str) => (
                <p>{str}</p>
              ))}{" "}
            </p>
          </span>
        </div>
        <div className="content-image-side">
          {data?.singleImage && (
            <span className="single-image">
              {" "}
              <img
                src={data?.singleImage}
                style={{ width: "70%", borderRadius: "20px", zIndex: 2 }}
                alt="car related images"
              />
            </span>
          )}

          <div className="row">
            <div className="column">
              {data?.img1 && (
                <img
                  src={data?.img1}
                  style={{ width: "100%" }}
                  alt="car related images"
                />
              )}
            </div>
            <div className="column">
              {data?.img2 && (
                <img
                  src={data?.img2}
                  style={{ width: "100%", height: "200px" }}
                  alt="car related images"
                />
              )}
              {data?.img3 && (
                <img
                  src={data?.img3}
                  style={{ width: "100%" }}
                  alt="car related images"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GalleryContent;
