import styles from "./styles.module.css";
const BookingTab = ({ selectedTab, items, setSelected }) => {
  const onTabItemClick = (id) => {
    setSelected(id);
  };
  return (
    <div className={styles.tabs}>
      {items.map((item) => (
        <div
          key={item.id}
          onClick={() => onTabItemClick(item.id)}
          className={[
            styles.tabItem,
            selectedTab === item.id ? styles.active : styles.inActive,
          ].join(" ")}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default BookingTab;
