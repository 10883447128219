import React from "react";

function BlogContentWithImage({ data }) {
  return (
    <div className="blogPagePadding blogPageContent">
      <span className="blogPageParaPadding">
        <h2 className="blogPageContentHeading"> {data?.header} </h2>
        {data?.firstPara?.split("\n").map((str) => (
          <p>{str}</p>
        ))}
        {data?.image && (
          <span
            className="blogPagePrimaryPage flex justify-center "
            style={{ display: "flex" }}
          >
            {data?.image && (
              <img style={{ width: "70%" }} src={data?.image} alt="" />
            )}
          </span>
        )}

        <span className="blogPageParaPadding">
          {data?.lastPara?.split("\n").map((str) => (
            <p>{str}</p>
          ))}
        </span>
      </span>
    </div>
  );
}

export default BlogContentWithImage;
