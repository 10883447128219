import React, { useEffect, useState } from "react";
import selecttick from "../../assets/selecttick.svg";
import dot from "../../assets/dot.svg";
import bluetick from "../../assets/bluetick.svg";
import AddContainer from "../../Container/AddContainer/AddContainer";
import "./SelectVechile.css";
import { deleteVehicle, getAllVehicle } from "../../utils/api";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { svgIcons } from "../../../src/Component/PricingPage/Content/icons";
import {
  bookingData,
  checkValidation,
  selectedBodytype,
  updateData,
} from "../../Store/userBooking/userBookingSlice";
import Dropdown from "../../Container/Dropdown/Dropdown.js";
import ModalAction from "../../Container/Modal/ModalAction/ModalAction";
import Modal from "../../Container/Modal/Modal";
import Loading from "../../Container/Loading/Loading";
function SelectVechile() {
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectIndex, setSelectIndex] = useState(0);
  const [size, setSize] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [error, setError] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const existingData = useSelector((state) => state.booking.selectedData);

  useEffect(() => {
    getAllVehicle()
      .then((response) => {
        setVehicleDetails(response.data.data);
        setSize(response.data.data.length);
        if (response.data.data.length === 0) {
          history.push({
            pathname: "/addvehicle",
            state: { data: 0, backflag: false },
          });
        } else {
          if (existingData.hasOwnProperty("vehicle")) {
            setSelectIndex(existingData.vehicle._id);
            dispatch(checkValidation("vehicle"));
            setloading(true);
          } else {
            const actionType = {
              index: "vehicle",
              item: response.data.data[0],
            };
            setSelectIndex(response.data.data[0]._id);
            dispatch(updateData(actionType));
            dispatch(selectedBodytype(response.data.data[0].bodytype._id));
            dispatch(bookingData(actionType));
            dispatch(checkValidation("vehicle"));
            setloading(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }, []);

  const addVehicleHandler = () => {
    // history.push("/addvehicle");
    history.push({
      pathname: "/addvehicle",
      state: { data: 1 },
    });
  };

  const selectVechileHandler = (id, item) => {
    const actionType = {
      index: "vehicle",
      item: item,
    };
    dispatch(updateData(actionType));
    dispatch(selectedBodytype(item.bodytype._id));
    dispatch(bookingData(actionType));
    setSelectIndex(id);
  };
  const dropdownHandler = (id) => {
    setVehicleDetails(
      vehicleDetails.map((el) =>
        el._id === id ? Object.assign({}, el, { showDropdown: true }) : el
      )
    );
  };

  const onblurhandler = (id) => {
    setVehicleDetails(
      vehicleDetails.map((el) =>
        el._id === id ? Object.assign({}, el, { showDropdown: false }) : el
      )
    );
  };

  const editHandler = (id) => {
    history.push({
      pathname: `/editvehicle/${id}`,
      state: { data: 1 },
    });
  };
  const deleteHandler = (id) => {
    setDeleteId(id);
    setModalStatus(true);
  };
  const actionModalHandler = (e) => {
    deleteVehicle(deleteId)
      .then((response) => {
        if (response.data.status === true) {
          setDeleteStatus(true);
          setModalStatus(false);
          e.preventDefault();
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  };

  const closeModalHandler = () => {
    setModalStatus(false);
  };
  const deleteSuccessHandler = (e) => {
    setDeleteStatus(false);
    window.location.reload(false);
    e.preventDefault();
  };

  return (
    <>
      {!error && (
        <>
          {modalStatus ? (
            <Modal>
              <div>
                <div className="actionHeader">Do you want to Delete?</div>
              </div>
              <ModalAction
                action={actionModalHandler}
                close={closeModalHandler}
              />
            </Modal>
          ) : (
            ""
          )}
          {deleteStatus ? (
            <Modal>
              <div>
                <div className="actionHeader">Successfully deleted</div>
              </div>
              <ModalAction close={deleteSuccessHandler} action={false} />
            </Modal>
          ) : (
            ""
          )}
          {!loading ? (
            <Loading />
          ) : (
            <div className="selectVechile-placer">
              <div className="selectVechile-header">
                <h2>SELECT YOUR VEHICLE</h2>
              </div>
              <div className="selectVechile-divider-card">
                {vehicleDetails &&
                  vehicleDetails.map((item) => {
                    const matchingObject = svgIcons.find(
                      (obj) => obj.id === item.bodytype._id
                    );
                    return (
                      <div
                        key={item._id}
                        onClick={() => selectVechileHandler(item._id, item)}
                        className="selectVechile-card "
                      >
                        <div className="selectvehicle-tick">
                          {selectIndex === item._id ? (
                            <img src={bluetick} alt="" />
                          ) : (
                            <img src={selecttick} alt="" />
                          )}
                        </div>
                        <div
                          className="dropdown-container"
                          tabIndex={0}
                          onBlur={() => onblurhandler(item._id)}
                        >
                          <div
                            className="selectvehicle-dot"
                            onClick={() => dropdownHandler(item._id)}
                          >
                            <img src={dot} alt="" />
                          </div>
                          <Dropdown
                            show={item.showDropdown}
                            edit={() => editHandler(item._id)}
                            delete={() => deleteHandler(item._id)}
                          />
                        </div>

                        <div className="selectVechile-vehicle">
                          <div className="selectVechile-carlogo">
                            <img
                              src={matchingObject && matchingObject.src}
                              alt=""
                            />
                          </div>
                          <div className="selectVechile-card-section">
                            <div className="selectVechile-card-details">
                              <span
                                className="span-shadow"
                                style={{
                                  fontWeight: "bold",
                                  opacity: 0.7,
                                  paddingRight: "1em",
                                }}
                              >
                                Name :
                              </span>
                              <span style={{ fontWeight: "900", opacity: 0.5 }}>
                                {item && item.nickname}
                              </span>
                            </div>
                            <div className="selectVechile-card-details">
                              <span
                                className="span-shadow"
                                style={{
                                  fontWeight: "bold",
                                  opacity: 0.7,
                                  paddingRight: "1em",
                                }}
                              >
                                Make :
                              </span>
                              <span style={{ fontWeight: "900", opacity: 0.5 }}>
                                {item && item.make}
                              </span>
                            </div>
                            <div className="vehicleCollection-card-details">
                              <span
                                className="span-shadow"
                                style={{
                                  fontWeight: "bold",
                                  opacity: 0.7,
                                  paddingRight: "1em",
                                }}
                              >
                                Type :
                              </span>
                              <span style={{ fontWeight: "900", opacity: 0.5 }}>
                                {item && item.bodytype.bodytypeName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {size <= 4 ? (
                <AddContainer onClickHandler={addVehicleHandler}>
                  Add New Vehicle
                </AddContainer>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default SelectVechile;
