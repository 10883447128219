import React from "react";
import "./AboutUsMain.css";
import contactus from "../../../assets/aboutpic.svg";
import gradient from "../../../assets/gradient.png";
function AboutUsMain() {
  return (
    <div className="about-main-content">
      <div className="about-image-wrapper">
        <div className="about-bg-image">
          <img
            style={{ width: "100%", height: "100%" }}
            src={gradient}
            alt=""
          />
        </div>
        <div className="about-landingpage-wrapper">
          <div className="about-landingpage-content">
            <div className="about-landingpage-heading">ABOUT US</div>
          </div>
          <div className="about-landingpage-image">
            <img style={{ width: "86%" }} src={contactus} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsMain;
