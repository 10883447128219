import React from "react";
import gradient from "../../assets/gradient3.png";
import carlogo from "../../assets/carwash.svg";
import water from "../../assets/water.svg";
import trained from "../../assets/trained.svg";
import sanitization from "../../assets/sanitization.svg";
import save from "../../assets/save.svg";
import eco from "../../assets/eco.svg";
import "./WhyKivlik.css";

function WhyKivlik() {
  return (
    <div className="kivlik-container">
      <div className="kivlik-main-wrapper">
        <div className="kivliksvg-gradient">
          <img style={{ width: "100%" }} src={gradient} alt="" />
        </div>

        <div className="kivlik-wrapper">
          <div className="kivlik-content">
            <div className="kivlik-heading"> WHY KIVLIK ?</div>
            <div className="kivlik-sub">
              Saving you time, money ,water and most importantly the
              environment.
            </div>
            <div className="kivlik-image">
              <img
                style={{ width: "20em", height: "22em" }}
                src={carlogo}
                alt=""
              />
            </div>
          </div>

          <div className="kivlik-tickcontainer">
            <div className="kivlik-tick">
              <div>
                <img style={{ height: "3em" }} src={water} alt="" />
              </div>
              <div className="kivlik-subcontent">
                <h3>Water conservation</h3>
                <div className="kivlik-subcontent-para">
                  - KIVLIK mobile steam car wash requires only one gallon of
                  water for each car, without producing any waste water.
                </div>
              </div>
            </div>
            <div className="kivlik-tick">
              <div>
                <img style={{ height: "3em" }} src={sanitization} alt="" />
              </div>
              <div className="kivlik-subcontent">
                <h3>Sterilization / Sanitization</h3>{" "}
                <div className="kivlik-subcontent-para">
                  - Dry steam helps to kill bacteria, viruses and germs.
                </div>
              </div>
            </div>
            <div className="kivlik-tick">
              <div>
                <img style={{ height: "3em" }} src={save} alt="" />
              </div>
              <div className="kivlik-subcontent">
                <h3>Save your Precious time</h3>
                <div className="kivlik-subcontent-para">
                  {" "}
                  - Avoid the long waiting at the traditional wash stations.
                  Provides our services at your convenient time and place
                </div>
              </div>
            </div>
            <div className="kivlik-tick">
              <div>
                <img style={{ height: "3em" }} src={eco} alt="" />
              </div>
              <div className="kivlik-subcontent">
                <h3> Eco Friendly </h3>
                <div className="kivlik-subcontent-para">
                  - Steam car wash method significantly reduces the usage of
                  harmful chemicals on your car exterior and interior.
                </div>
              </div>
            </div>
            <div className="kivlik-tick">
              <div>
                <img style={{ height: "3em" }} src={trained} alt="" />
              </div>
              <div className="kivlik-subcontent">
                <h3>Trained Executives </h3>
                <div className="kivlik-subcontent-para">
                  - All kivlik executives are trained, bonded and vetted before
                  working on your car
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyKivlik;
