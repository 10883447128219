import { useState } from "react";
import BookingTab from "./BookingTab";
import styles from "./styles.module.css";
import BookingCard from "./BookingCard";
import { useEffect } from "react";
import { getAllBooking } from "../../utils/api";
function BookingHistory() {
  const [selectedTab, setSelectedTab] = useState("all");
  const [bookingData, setBookingData] = useState();
  useEffect(() => {
    getAllBooking()
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data);
          setBookingData(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const tabs = [
    {
      id: "all",
      label: "All",
    },
    {
      id: "payment-success",
      label: "Payment Success",
    },
    {
      id: "timeout",
      label: "Timeout",
    },
    {
      id: "initiated",
      label: "Initiated",
    },
    {
      id: "refund_created",
      label: "Cancelled",
    },
  ];

  return (
    <div className={styles.historyContainer}>
      <div className={styles.historyPlacer}>
        <div className={styles.historyHeader}>
          <div className={styles.mainHeading}>BOOKING HISTORY</div>
          <div className={styles.tabSection}>
            <BookingTab
              setSelected={setSelectedTab}
              selectedTab={selectedTab}
              items={tabs}
            />
          </div>
        </div>
        <div className={styles.historyCard}>
          <div className={styles.bookingHistoryGrid}>
            {selectedTab === "all"
              ? bookingData &&
                bookingData.map((data) => (
                  <>
                    <BookingCard data={data} />
                  </>
                ))
              : bookingData &&
                bookingData
                  .filter((data) => data.status === selectedTab)
                  .map((dt) => (
                    <>
                      <BookingCard data={dt} />
                    </>
                  ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingHistory;
