import React from "react";
import { BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { GrLinkedin } from "react-icons/gr";
import { ImMail4 } from "react-icons/im";
import carWaxImage1 from "../../assets/BlogImage1.jpg";

import "./Blog.css";
import { BLOG_CONTENT_DATA } from "./BlogData";
import BlogContentWithImage from "./BlogContentWithImage";
import { Helmet } from "react-helmet";
function Blog() {
  return (
    <>
      <Helmet>
        <title>Kivlik - Blog</title>
      </Helmet>
      <div className="blog-page">
        <header className="pt-24">
          <span className="">
            <div className="w-fit mx-auto">
              <h1 className=" pt-8 font-extralight ">
                5 Ways to protect your car from road salt rust
              </h1>

              <span className="blogSubHeader">
                <h4 className="text-black/80">1 MAR ,2023 </h4>
                <span className="blogPageHeaderIconRow flex justify-start items-center gap-3">
                  <a
                    href="https://www.facebook.com/Kivlik/"
                    className="text-black pt-2 text-2xl"
                  >
                    <BsFacebook />
                  </a>
                  <a
                    href="https://www.facebook.com/Kivlik/"
                    className="text-black pt-2 text-2xl"
                  >
                    <AiFillTwitterCircle />
                  </a>
                  <a
                    href="https://www.facebook.com/Kivlik/"
                    className="text-black pt-2 text-2xl"
                  >
                    <GrLinkedin />
                  </a>
                  <a
                    href="mailto:helpdesk@kivlik.ca"
                    className="text-black pt-2 text-2xl"
                  >
                    <ImMail4 />
                  </a>
                </span>
              </span>
            </div>
          </span>
        </header>
        <article className="blogPagePadding blogPageContent">
          <span className="blogPageParaPadding p-5 flex justify-center">
            <img style={{ width: "70%" }} src={carWaxImage1} alt="" />
          </span>

          <span className="blogPageParaPadding ">
            <p className="m-0">
              Winter is a time when every car owner must brace themselves to
              battle the villainous road salt along with the tons of snow on the
              road. Yes, road salt does help to clear roads faster by melting
              the snow and helping pedestrians and vehicles with the slippery
              roads, but the salt reacts with the metal parts of your car where
              moisture is retained. This leads to corrosion or rust, degrading
              the vehicle's finish and performance, especially when fuel and
              brake lines are affected.
            </p>
          </span>
          <span className="blogPageParaPadding">
            <p className="m-0">
              Even though the automotive industry is cautious when it comes to
              developing solutions for corrosion, as the driver there are a
              couple of things you can do to prevent rust caused by road salt.
            </p>
          </span>
        </article>
        <article style={{ paddingBottom: "3rem" }}>
          {BLOG_CONTENT_DATA?.map((item) => (
            <BlogContentWithImage data={item} />
          ))}
        </article>
      </div>
    </>
  );
}

export default Blog;
