import React from "react";
import {
  useLoadScript, GoogleMap, Marker
} from "@react-google-maps/api";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyAtT5xA2mu-WVx3URThZOBh5QDFDDcIEqs");
function Location(props) {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAtT5xA2mu-WVx3URThZOBh5QDFDDcIEqs"
  });

  // const MapWithAMarker = withScriptjs(
  //   withGoogleMap(() => (
  //     <GoogleMap
  //       defaultZoom={15}
  //       defaultCenter={{
  //         lat: Number(props.lat),
  //         lng: Number(props.lng),
  //       }}
  //     >
  //       <Marker
  //         draggable={true}
  //         position={{
  //           lat: Number(props.lat),
  //           lng: Number(props.lng),
  //         }}
  //       >
  //         <InfoWindow>
  //           <div style={{ color: "black" }}>{props.address}</div>
  //         </InfoWindow>
  //       </Marker>
  //     </GoogleMap>
  //   ))
  // );
  return (
    <div>
      {isLoaded && <GoogleMap
        center={{  lat: Number(props.lat),
          lng: Number(props.lng), }}
        zoom={15}
        mapContainerStyle={{
          margin: "20px 0 0",
          height: "30vh",
          width: "100%"
        }}
      >
        <Marker
          position={{  lat: Number(props.lat),
            lng: Number(props.lng), }}
        />
      </GoogleMap>}
      {/* <MapWithAMarker
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAtT5xA2mu-WVx3URThZOBh5QDFDDcIEqs&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      /> */}
    </div>
  );
}
export default Location;
