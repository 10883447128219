import React, { useState } from "react";
import "./PackageModal.css";
import time from "../../assets/time.svg";
import tick from "../../assets/greentick.svg";
import add from "../../assets/addtime.svg";
import buttontick from "../../assets/buttontick.svg";
import { useDispatch } from "react-redux";
import untick from "../../assets/selecttick.svg";
import {
  bookingData,
  checkValidation,
  updateData,
} from "../../Store/userBooking/userBookingSlice";
import { IoCloseOutline } from "react-icons/io5";
import package1 from "../../assets/packageicon.svg";

function PackageModal(props) {
  const [services, setServices] = useState(false);
  const [additional, setAdditional] = useState([]);
  const [packageItem, setPackageItem] = useState(props.item);
  const [extraPrice, setExtraPrice] = useState(0);
  const [additionalService, setAdditionalService] = useState(
    props.item.additionalServices.filter((element) =>
      element.bodySpecific.some(
        (bodySpecific) => bodySpecific.bodytypeId === props.bodytypeId
      )
    )
  );
  // const [selectedAddService, setSelectedAddService] = useState([]);
  const [packagePrice, setPackagePrice] = useState(
    props.item.packagePrices.find((e) => {
      return e.bodytypeId === props.bodytypeId;
    }).salePriceInCents
  );
  const [packagePriceinCent, setPackagePriceinCent] = useState(
    props.item.packagePrices.find((e) => {
      return e.bodytypeId === props.bodytypeId;
    }).salePriceInCents
  );
  const [totalAmount, setTotalAmount] = useState(packagePrice / 100);
  const [duration, setDuration] = useState(
    props.item.durations.find((e) => {
      return e.bodytypeId === props.bodytypeId;
    })?.durationInMin
  );
  const dispatch = useDispatch();

  const convertMinutes = (minutes) => {
    const hour = Math.floor(minutes / 60);
    const min = minutes % 60;
    if (min > 0) return ` ${hour}H ${min}Min `;
    else return ` ${hour}H `;
  };
  const addServiceHandler = () => {
    setServices(!services);
  };
  const selectPackageHandler = () => {
    const packagedata = {
      ...packageItem,
      additionalServiceAdded: additional,
      totalDuration: duration,
      packagePrice: packagePrice,
      packagePriceinCent: packagePriceinCent,
      totalAmount: totalAmount,
      extraAmount: extraPrice ? extraPrice : 0,
    };
    const actionType = {
      index: "package",
      item: packagedata,
    };
    dispatch(updateData(actionType));
    dispatch(bookingData(actionType));
    props.modalexiter();
    props.setPackageHandler(packageItem._id);
    dispatch(checkValidation("package"));
  };
  const serviceSelectionHandler = (id) => {
    if (
      additional.some((e) => {
        return e.id === id;
      })
    ) {
      const data = additional.filter((e) => {
        return e.id !== id;
      });
      setAdditional(data);
      let extraAmount =
        additionalService
          .find((e) => {
            return e._id === id;
          })
          .bodySpecific.find((item) => {
            return item.bodytypeId === props.bodytypeId;
          }).salePriceInCents / 100;
      const totalAmount1 = totalAmount - extraAmount;
      extraAmount = extraPrice - extraAmount;
      setExtraPrice(extraAmount);

      const totalDuration =
        +duration -
        additionalService
          .find((e) => {
            return e._id === id;
          })
          .bodySpecific.find((item) => {
            return item.bodytypeId === props.bodytypeId;
          }).durationInMins;
      setDuration(totalDuration);
      setTotalAmount(totalAmount1);
      setPackageItem((prevState) => {
        return {
          ...prevState,
          totalDuration: totalDuration,
          totalAmount: totalAmount1,
          packagePrice: packagePrice,
          packagePriceinCent: packagePriceinCent,
          extraAmount: 0,
        };
      });
    } else {
      setAdditional((prevState) => {
        return [...prevState, { id: id }];
      });
      let extraAmount =
        additionalService
          .find((e) => {
            return e._id === id;
          })
          .bodySpecific.find((item) => {
            return item.bodytypeId === props.bodytypeId;
          }).salePriceInCents / 100;
      const totalAmount1 = totalAmount + extraAmount;
      extraAmount = extraPrice + extraAmount;
      setExtraPrice(extraAmount);
      const totalDuration =
        additionalService
          .find((e) => {
            return e._id === id;
          })
          .bodySpecific.find((item) => {
            return item.bodytypeId === props.bodytypeId;
          }).durationInMins + +duration;

      setDuration(totalDuration);
      setTotalAmount(totalAmount1);
      setPackageItem((prevState) => {
        return {
          ...prevState,
          totalDuration: totalDuration,
          totalAmount: totalAmount1,
          packagePrice: packagePrice,
          packagePriceinCent: packagePriceinCent,
          extraAmount: extraAmount,
        };
      });
    }
  };
  return (
    <div className="package-modal">
      <div className="close-icon" onClick={() => props.modalexiter()}>
        <IoCloseOutline size={"1.2rem"} />
      </div>
      <div className="package-placer">
        <div className="package-modal-header">{props.item.packageName}</div>
        <div className="package-modal-content">{props.item.description}</div>
        <div className="package-modal-time">
          <div className="package-modal-image">
            <img src={time} alt="" />
          </div>
          <div>
            Duration :
            {/* {props.bodytypeId && convertMinutes(+duration)} to{" "} */}
            {props.bodytypeId && convertMinutes(+duration + 15)}
          </div>
        </div>
        <h2 className="package-modal-outer-package-heading">Exterior</h2>
        <div className="package-modal-outer-package">
          {props.item.includedServices
            ?.filter((data) => data?.type === "exterior")
            ?.map((item) => {
              return (
                <>
                  <div
                    key={item._id}
                    className="package-modal-packages-details"
                  >
                    <div className=".package-modal-sectioncontent ">
                      {item.title}
                    </div>
                    <div className="package-modal-sectionimage">
                      <img src={tick} alt="" width={"35px"} />
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <h2 className="package-modal-outer-package-heading">Interior</h2>
        <div className="package-modal-outer-package">
          {props.item.includedServices
            ?.filter((data) => data?.type === "interior")
            ?.map((item) => {
              return (
                <>
                  <div
                    key={item._id}
                    className="package-modal-packages-details"
                  >
                    <div className=".package-modal-sectioncontent ">
                      {item.title}
                    </div>
                    <div className="package-modal-sectionimage">
                      <img src={tick} alt="" width={"35px"} />
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <div>
          <div className="package-modal-additional" onClick={addServiceHandler}>
            <div>Additional Services</div>
            <div className="package-modal-timeimage">
              <img src={add} alt="" />
            </div>
          </div>
          {services && (
            <div className="package-modal-packages">
              {console.log(additionalService)}
              {additionalService.map((item) => {
                return (
                  <>
                    <div
                      key={item._id}
                      className="package-modal-packages-details"
                    >
                      <div className=".package-modal-sectioncontent ">
                        {item.title}
                      </div>

                      <div
                        className="package-modal-sectionimage additional"
                        onClick={() => serviceSelectionHandler(item._id)}
                      >
                        {additional.find((e) => {
                          return e.id === item._id;
                        }) ? (
                          <div>
                            {console.log(
                              item?.bodySpecific,
                              "item body specific"
                            )}
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                              {item.bodySpecific?.find(
                                (bodyType) =>
                                  bodyType?.bodytypeId == props.bodytypeId
                              )?.salePriceInCents / 100}
                              $
                            </p>
                            <img src={tick} alt="" />
                          </div>
                        ) : (
                          <div>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                              {item.bodySpecific?.find(
                                (bodyType) =>
                                  bodyType?.bodytypeId == props.bodytypeId
                              )?.salePriceInCents / 100}
                              $
                            </p>
                            <img src={untick} alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </div>
        <div className="package-modal-total">
          <div>PACKAGE COST : </div>
          {/* <div>{totalAmount}$ </div> */}
          <div className="package-card-section">
            <img src={package1} alt="" width={100} />
            <div className="package-price">${totalAmount}</div>
          </div>
        </div>
        <div>
          <button
            onClick={() => selectPackageHandler()}
            className="package-modal-button"
          >
            <img src={buttontick} alt="" />
            Select Package
          </button>
        </div>
      </div>
    </div>
  );
}

export default PackageModal;
