import { useEffect, useState } from "react";
import DatePicker from "./customCalender/index";
import styles from "./Scheduler.module.css";
import "./calender.css";
import useSetweek from "../../hooks/useSetweek";
import bookingtime from "../../assets/bookingtime.svg";
import bookingdate from "../../assets/bookingdate.svg";
import { getAllSlot } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  bookingData,
  updateData,
  forwardHandler,
  setModalStatus,
  checkValidation,
} from "../../Store/userBooking/userBookingSlice";
import Modal from "../../Container/Modal/Modal";
import ModalAction from "../../Container/Modal/ModalAction/ModalAction";
import { setCalenderData } from "../../Store/userBooking/userTime";
import moment from "moment-timezone";

import helper from "../../utils/helper";
import Loading from "../../Container/Loading/Loading";
import WeekSelector from "./WeekSelector";
const Scheduler = () => {
  const bookingSelectedData = useSelector(
    (state) => state.booking.selectedData
  );
  console.log(bookingSelectedData, "package Info");
  const [selectedWeek, setSelectedWeek] = useState({});

  const calenderData = useSelector((state) => state.calender.calenderData);
  const [date, setDate] = useState(new Date());
  const setDays = useSetweek();
  const modalStatus = useSelector((state) => state.booking.modalStatus);

  // const page = useSelector((state) => state.booking.page);
  const [selectedTimeZone, setSelectedTimeZone] = useState("all");
  const [loading, setloading] = useState(false);
  const [selectSlotTime, setSelectSlotTime] = useState({});
  const [selectedTimeIndex, setSelectedTimeIndex] = useState();
  const [timeSlot, setTimeSlot] = useState([]);
  const [currentMonthYear, setCurrentMonthYear] = useState([
    new Date().getMonth(),
    new Date().getFullYear(),
  ]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [formattedTimeSlot, setFormattedTimeSlot] = useState();
  const [inActiveDays, setInActiveDays] = useState([]);
  const [dailySlot, SetDailySlot] = useState();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const isSubscriptionPackage =
    bookingSelectedData?.package?.packageName === "STEAM GOLD";
  const dispatch = useDispatch();

  useEffect(() => {
    getAllSlot()
      .then((response) => {
        setTimeSlot(response.data);
        const formattedObj = formatTimeSlot(response.data);
        setFormattedTimeSlot(formattedObj);
        getInactiveDays(formattedObj, currentMonthYear);
        dispatch(checkValidation("timeslot"));
        setloading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (formattedTimeSlot) {
      getInactiveDays(formattedTimeSlot, currentMonthYear);
      const year = date.getFullYear();
      const month = date.getMonth();
      const date1 = date.getDate();
      if (
        (formattedTimeSlot[year]
          ? formattedTimeSlot[year][month]
            ? formattedTimeSlot[year][month][date1]
            : undefined
          : undefined) === undefined
      ) {
        SetDailySlot([]);
        setTimeSlots([]);
        setSelectSlotTime([]);
      } else {
        SetDailySlot(
          formattedTimeSlot[year] &&
            formattedTimeSlot[year][month] &&
            formattedTimeSlot[year][month][date1]
            ? formattedTimeSlot[year][month][date1]
            : []
        );

        if (selectedTimeZone === "all") {
          const slots = [];
          const checkDate =
            formattedTimeSlot[year] &&
            formattedTimeSlot[year][month] &&
            formattedTimeSlot[year][month][date1] &&
            formattedTimeSlot[year][month][date1]
              ? formattedTimeSlot[year][month][date1]
              : false;
          if (checkDate) {
            if (checkDate?.morning) {
              slots.push(...checkDate.morning);
            }
            if (checkDate?.afternoon) {
              slots.push(...checkDate.afternoon);
            }
            if (checkDate?.evening) {
              slots.push(...checkDate.evening);
            }
          }
          setTimeSlots([...slots]);
        } else {
          setTimeSlots(
            formattedTimeSlot[year] &&
              formattedTimeSlot[year][month] &&
              formattedTimeSlot[year][month][date1] &&
              formattedTimeSlot[year][month][date1][selectedTimeZone]
              ? formattedTimeSlot[year][month][date1][selectedTimeZone]
              : []
          );
        }
        if (
          formattedTimeSlot[year][month][date1][selectedTimeZone] !== undefined
        ) {
          const initialSlot =
            formattedTimeSlot[year] &&
            formattedTimeSlot[year][month] &&
            formattedTimeSlot[year][month][date1] &&
            formattedTimeSlot[year][month][date1][selectedTimeZone]
              ? formattedTimeSlot[year][month][date1][selectedTimeZone][0]
              : [];
          setSelectSlotTime(initialSlot ? initialSlot : []);
        } else {
          setSelectSlotTime([]);
        }
      }
      if (
        calenderData.date !== undefined
        // calenderData.zone === !undefined &&
        // calenderData.timeSlot === !undefined
      ) {
        setDate(calenderData.date);
        setSelectedTimeZone(calenderData.zone);
        setSelectSlotTime(calenderData.timeSlot);
        setSelectedTimeIndex(calenderData.selectedIndex);
        dispatch(dispatch(setCalenderData({})));
      }
    }
  }, [
    currentMonthYear[0],
    currentMonthYear[1],
    formattedTimeSlot,
    SetDailySlot,
    setTimeSlots,
    date,
  ]);

  const timeZone = [
    {
      zoneId: "all",
      zoneLabel: "All",
    },
    {
      zoneId: "morning",
      zoneLabel: "Morning",
    },
    {
      zoneId: "afternoon",
      zoneLabel: "Afternoon",
    },
    {
      zoneId: "evening",
      zoneLabel: "Evening",
    },
  ];
  const onChange = async (newDate) => {
    setDate(newDate);
  };
  const getInactiveDays = (formattedObj, monthYear) => {
    let inactives;
    const dates = Array(31 - 1 + 1)
      .fill()
      .map((_, idx) => 1 + idx);
    if (formattedObj[monthYear[1]]) {
      if (!formattedObj[monthYear[1]][monthYear[0]]) {
        setInActiveDays(dates);
        return dates;
      } else {
        inactives = dates.filter(
          (d) => !formattedObj[monthYear[1]][monthYear[0]][d]
        );
        setInActiveDays(inactives);
      }
    } else {
      setInActiveDays(dates);
      return dates;
    }
  };

  const dateTime = (ti) => {
    let greet;
    let localDate = new Date(ti);
    let zonalDate = helper.formatZonalDate(localDate);
    let ndate = new Date(zonalDate);
    let hr = ndate.getHours();
    if (hr < 12) {
      greet = "morning";
    } else if (hr >= 12 && hr <= 17) {
      greet = "afternoon";
    } else if (hr >= 17 && hr <= 24) greet = "evening";
    return greet;
  };
  const formatDay = (time) => {
    let format = "";
    let localDate = new Date(time);
    let zonalDate = helper.formatZonalDate(localDate);
    let ndate = new Date(zonalDate);
    let hr = ndate.getHours();
    if (hr < 12) {
      format = "AM";
    } else if (hr >= 12 && hr <= 24) {
      format = "PM";
    }
    return format;
  };
  const formatTimeSlot = (schedules) => {
    const available = schedules.data.filter((el) => el.isAvailable);
    const formattedObj = {};
    available.forEach((schedule, index) => {
      const localDate = new Date(schedule.startTime);
      let zonalDate = helper.formatZonalDate(localDate);
      let date = new Date(zonalDate);
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      if (!formattedObj[year]) {
        formattedObj[year] = {
          [month]: { [day]: { [dateTime(schedule.startTime)]: [schedule] } },
        };
      } else if (!formattedObj[year][month]) {
        formattedObj[year][month] = {
          [day]: { [dateTime(schedule.startTime)]: [schedule] },
        };
      } else if (!formattedObj[year][month][day]) {
        formattedObj[year][month][day] = {
          [dateTime(schedule.startTime)]: [schedule],
        };
      } else if (
        !formattedObj[year][month][day][dateTime(schedule.startTime)]
      ) {
        formattedObj[year][month][day][dateTime(schedule.startTime)] = [
          schedule,
        ];
      }
      if (formattedObj[year][month][day][dateTime(schedule.startTime)]) {
        if (
          !(
            formattedObj[year][month][day][dateTime(schedule.startTime)][0]
              .timeslotId === schedule.timeslotId
          )
        ) {
          formattedObj[year][month][day][dateTime(schedule.startTime)].push(
            schedule
          );
        }
      } else {
        formattedObj[year][month][day][dateTime(schedule.startTime)] = schedule;
      }
    });
    return formattedObj;
  };

  const selectSlotTimeHandler = (item, index, termsAccepted = false) => {
    setSelectSlotTime(item);
    setSelectedTimeIndex(index);
    console.log(acceptTerms, "appcte terms");
    if (bookingSelectedData?.package?.packageName === "STEAM GOLD") {
      if (termsAccepted) {
        const actionType = {
          index: "timeslot",
          item: selectSlotTime,
        };
        dispatch(bookingData(actionType));
        dispatch(checkValidation("timeslot"));
      } else {
        const actionType = {
          index: "timeslot",
          item: null,
        };
        dispatch(bookingData(actionType));
        dispatch(checkValidation("timeslot"));
      }
    } else {
      const actionType = {
        index: "timeslot",
        item: selectSlotTime,
      };
      dispatch(bookingData(actionType));
      dispatch(checkValidation("timeslot"));
    }
  };

  const acceptTermsHandler = (event) => {
    setAcceptTerms(event.target.checked);
    selectSlotTimeHandler(
      selectSlotTime,
      selectedTimeIndex,
      event.target.checked
    );
  };

  const convertDate = (d) => {
    return `
      ${moment(d.startTime).tz("Canada/Eastern").format("hh:mm a")} - 
      ${moment(d.endTime).tz("Canada/Eastern").format("hh:mm a")}
      `;
  };

  const getCurrentMonth = (month, year) => {
    setCurrentMonthYear([month, year]);
  };
  const getZoneDetail = (zoneId) => {
    let slots = [];
    if (zoneId === "all") {
      if (dailySlot?.morning) {
        slots.push(...dailySlot.morning);
      }
      if (dailySlot?.afternoon) {
        slots.push(...dailySlot.afternoon);
      }
      if (dailySlot?.evening) {
        slots.push(...dailySlot.evening);
      }
    } else {
      slots = dailySlot[zoneId];
    }
    setTimeSlots(slots ? slots : []);
    setSelectedTimeZone(zoneId);
  };
  const actionModalHandler = () => {
    // Object.assign({ ...selectSlotTime, date: date });
    // selectSlotTime.date = date;
    const calenderData = {
      date: date,
      timeSlot: selectSlotTime,
      zone: selectedTimeZone,
      selectedIndex: selectedTimeIndex,
    };
    const actionType = {
      index: "timeslot",
      item: selectSlotTime,
    };
    dispatch(setCalenderData(calenderData));
    dispatch(updateData(actionType));
    dispatch(bookingData(actionType));
    dispatch(forwardHandler());
    dispatch(setModalStatus(false));
  };
  const closeModalHandler = () => {
    dispatch(setModalStatus(false));
  };
  return (
    <>
      {modalStatus ? (
        <Modal>
          <div>
            <div className={styles.actionHeader}>
              Do you want to proceed with below date?
            </div>
            <div className={styles.selectedStatus}>
              <div className={styles.bookingTime}>
                <div className={styles.title}>
                  <div className={styles.iconLabel}>BOOKING TIME</div>
                </div>
                <div className={styles.value}>
                  {selectSlotTime.length !== 0
                    ? convertDate(selectSlotTime)
                    : "No slot"}
                </div>
              </div>
              <div className={styles.bookingDate}>
                <div className={styles.title}>
                  <div className={styles.iconLabel}>BOOKING DATE</div>
                </div>

                <div className={styles.value}>
                  {date.getFullYear() +
                    "/" +
                    (date.getMonth() + 1) +
                    "/" +
                    date.getDate()}
                </div>
              </div>
            </div>
          </div>
          <ModalAction action={actionModalHandler} close={closeModalHandler} />
        </Modal>
      ) : (
        ""
      )}
      {!loading ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            {bookingSelectedData?.package?.packageName === "STEAM GOLD" ? (
              <h2>SELECT WEEK & DAY</h2>
            ) : (
              <h2>SELECT DATE & TIME</h2>
            )}
          </div>
          <div className={styles.mainContent}>
            {bookingSelectedData?.package?.packageName === "STEAM GOLD" && (
              <div className={styles.description}>
                <h2>STEAM GOLD</h2>
                <p>
                  The STEAM GOLD package is exclusively offered through a
                  monthly subscription. Please select the week, day, and
                  preferred schedule for each month to receive the service at
                  your convenience. Once subscribed, the KIVLIK customer care
                  team will reach out to you for any additional assistance. They
                  will also stay in touch with you monthly to ensure you
                  continue to receive a personalized service. The credit card
                  associated with your account will be billed monthly on the
                  service date, excluding the months of January through March
                  when services are unavailable. KIVLIK's Gold Packages provide
                  unparalleled value for money, ensuring your car stays
                  impeccably clean throughout the entire season with a monthly
                  subscription.
                </p>
              </div>
            )}
            <div className={styles.actionArea}>
              <div className={styles.leftContent}>
                {bookingSelectedData?.package?.packageName === "STEAM GOLD" ? (
                  <>
                    <WeekSelector
                      selectedWeek={selectedWeek}
                      setSelectedWeek={setSelectedWeek}
                    />
                  </>
                ) : (
                  <>
                    <div className={styles.calenderHead}>Select Date</div>
                    <div className={styles.calender}>
                      <DatePicker
                        onClick={() => setTimeout(setDays, 100)}
                        onChange={(e) => onChange(e)}
                        selected={date}
                        minDate={yesterday}
                        currentMonth={getCurrentMonth}
                        inActiveDays={inActiveDays}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className={styles.rightContent}>
                <div className={styles.timeSelector}>
                  <div className={styles.timeHead}>Select Time</div>
                  <div className={styles.timePicker}>
                    <div className={styles.slotPicker}>
                      {timeZone.map((time, index) => (
                        <div
                          key={index}
                          onClick={() => getZoneDetail(time.zoneId)}
                          className={`${styles.timeTile} ${
                            selectedTimeZone === time.zoneId
                              ? styles.slotActive
                              : ""
                          }`}
                        >
                          {time.zoneLabel}
                        </div>
                      ))}
                    </div>
                    <div className={styles.timeGrid}>
                      {timeSlots.length !== 0 ? (
                        timeSlots.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => selectSlotTimeHandler(item, index)}
                            className={`${styles.timeStyle} ${
                              selectedTimeIndex === index
                                ? styles.timeStyleActive
                                : ""
                            }`}
                          >
                            {convertDate(item)}
                          </div>
                        ))
                      ) : (
                        <div className={styles.timeStyleNone}>
                          {" "}
                          No slot Available
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.selectedStatus}>
                    <div className={styles.bookingTime}>
                      <div className={styles.title}>
                        <div className={styles.icon}>
                          <img src={bookingtime} alt="" />
                        </div>
                        <div className={styles.iconLabel}>BOOKING TIME</div>
                      </div>
                      <div className={styles.value}>
                        {selectSlotTime && selectSlotTime.length !== 0
                          ? convertDate(selectSlotTime)
                          : "No slot"}
                      </div>
                    </div>
                    <div className={styles.bookingDate}>
                      <div className={styles.title}>
                        <div className={styles.icon}>
                          <img src={bookingdate} alt="" />
                        </div>
                        <div className={styles.iconLabel}>
                          {isSubscriptionPackage
                            ? "BOOKING WEEK"
                            : "BOOKING DATE"}
                        </div>
                      </div>

                      <div className={styles.value}>
                        {(date && date.getFullYear()) +
                          "/" +
                          (date && date.getMonth() + 1) +
                          "/" +
                          (date && date.getDate())}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {bookingSelectedData?.package?.packageName === "STEAM GOLD" && (
              <div className={styles.termsConditionContainer}>
                <input
                  type="checkbox"
                  name="terms"
                  id="termsCondition"
                  onChange={acceptTermsHandler}
                />
                <label htmlFor="termsCondition">
                  {" "}
                  I Accept to the Terms & Condition
                </label>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Scheduler;
