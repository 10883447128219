export const tableData = [
  {
    rows: {
      name: "EXTERIOR",
      items: [
        {
          serviceName: "Full exterior steam hand wash & dry",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Streak free exterior windows",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Clean rims & tires",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Dress and shine tires",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Wheel well",
          availableIn: ["diamond", "emarald"],
        },
        {
          serviceName: "Gas door",
          availableIn: ["diamond", "emarald"],
        },
        {
          serviceName: "Water spot removal",
          availableIn: ["diamond", "emarald"],
        },
        {
          serviceName: "Bug removal from grill & windows",
          availableIn: ["emarald"],
        },
        {
          serviceName: "Tree sap removal",
          availableIn: ["emarald"],
        },
        {
          serviceName: "Clay bar treatment for exterior paint",
          availableIn: ["diamond", "emarald"],
        },
        {
          serviceName: "Outside Plastic dressing & UV protection",
          availableIn: ["diamond", "emarald"],
        },
        {
          serviceName: "Exterior paint - Two stage polishing and buffing",
          availableIn: ["emarald"],
        },
        {
          serviceName: "Wheel Iron Decontamination & Tar removal",
          availableIn: ["emarald"],
        },
        {
          serviceName:
            "Comprehensive Wax, Shine and protect (carnauba wax) with polisher",
          availableIn: ["platinum", "diamond", "emarald"],
        },
      ],
    },
  },
  {
    rows: {
      name: "INTERIOR",
      items: [
        {
          serviceName:
            "Garbage removal & thorough interior vacuuming Including trunk",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName:
            "Steam clean dashboard, Instrument console - air blowout",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Brush & Steam clean air vents",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Steam clean door pockets & cup holders",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Steam clean interior windows",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Door panels, plastics / vinyl cleaning",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Steam clean seats (deep)",
          availableIn: ["gold", "platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Steam clean floor mats (deep)",
          availableIn: ["platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Door Jamb cleaning & wipedown (deep)",
          availableIn: ["platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Leather Cleaning and Conditioning",
          availableIn: ["platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Stain removal - Steam ( silver wax pro )",
          availableIn: ["platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Steam clean trunk",
          availableIn: ["platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Steam clean foot pedals",
          availableIn: ["platinum", "diamond", "emarald"],
        },
        {
          serviceName: "Carpets and mats - Shampoo, Steam and extraction",
          availableIn: ["diamond", "emarald"],
        },
        {
          serviceName: "Inside Plastic dressing & UV protection",
          availableIn: ["diamond", "emarald"],
        },
        {
          serviceName: "Salt Extraction from the Carpets and Mats using steam",
          availableIn: ["diamond", "emarald"],
        },
        {
          serviceName: "Seats - Deep clean, shampooed, steamed and extracted",
          availableIn: ["diamond", "emarald"],
        },
      ],
    },
  },
];

export const columns = [
  { id: "gold", label: "GOLD" },
  { id: "platinum", label: "PLATINUM" },
  { id: "diamond", label: "DIAMOND" },
  { id: "emrald", label: "EMERALD" },
];
